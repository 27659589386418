import axios from 'axios';

export interface Ads {
  AdPassedLevelPropability: number;
  AdFailedLevelProbability: number;
  AdFrequencySeconds: number;
  RateUsFrequency: number;
}

export let adsData: Ads;

/**
 * Fetch ads data or return cached object
 */
export const getAdsData = (): Promise<Ads> => {
  if (adsData) {
    return Promise.resolve(adsData);
  } else {
    const data = axios
      .get(`${String(process.env.REACT_APP_API)}/ads`)
      .then(response => {
        adsData = response.data;
        return adsData;
      });
    data.catch(error => {
      console.log(error);
    });
    return data;
  }
};

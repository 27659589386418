import i18n from 'shared/i18n';
import cookies from 'browser-cookies';

import get from 'lodash/get';

import langCookieName from 'shared/locales/langCookieName';

/*
  This function sets i18n language & updates cookie
*/
export const setLang = (lang) => {
  cookies.set(langCookieName, lang);
  i18n.changeLanguage(lang);
};

/*
  for items like:
  {
    Name: string,
    Locales: {
      en: string,
      ru: string,
      ...
    }
  }
*/
const getTitleByLocale = (item, locale) => {
  const title = get(item, `Locales.${locale}`);
  return title ? title : item.Name;
}

/* 
  This function returns world locale data: e.g. for locale='en'
  
  {
    "title": "World 1: Origins",
    "packs": {
      "1": "Cinque"
    }
  }

*/
const getWorldDataLocale = (worldData, locale) => {
  const worldDataLocale = {
    title: getTitleByLocale(worldData, locale),
    packs: {},
  };

  if (worldData.Packs) {
    const packs = {};
    worldData.Packs.forEach(pack => {
      packs[pack.Id] = getTitleByLocale(pack, locale);
    });
    worldDataLocale.packs = packs;
  }

  return worldDataLocale;
}

/*
  This function parses worlds data and adds locales, e.g. 
  
  "worlds": {
    world.Id: {
      "title": "World 1: Origins",
      "packs": {
        pack.Id: "Cinque"
      }
    }
  }

*/
export const updateWorldsLocales = (worldsData = []) => {
  const enResouceBundle = {};
  const ruResouceBundle = {};

  const enWorlds = {};
  const ruWorlds = {};

  worldsData.forEach(worldData => {
    enWorlds[worldData.Id] = getWorldDataLocale(worldData, 'en');
    ruWorlds[worldData.Id] = getWorldDataLocale(worldData, 'ru');
  });
  enResouceBundle.worlds = enWorlds;
  ruResouceBundle.worlds = ruWorlds;

  i18n.addResourceBundle('en', 'translation', enResouceBundle, true);
  i18n.addResourceBundle('ru', 'translation', ruResouceBundle, true);
};
import store from '../../redux/store';

import { Howl, Howler } from 'howler';
import forEach from 'lodash/forEach';
import size from 'lodash/size';
import shuffle from 'lodash/shuffle';
import get from 'lodash/get';

import { isTabVisible } from '../helpers/tabVisibility';

// redux
import { setSettings } from '../../redux/actions/settings';


interface SoundItem {
  id: string; // howl instance id in the _howlSounds list (readonly)
  options: any; // howl options
};

interface BgSoundItem extends SoundItem {
  active?: boolean; // indicate current playing or stopped track
};

/**
 * Manage sounds in applicaiton
 * Background sound on the PlaygroundPage, button sounds on click
 */
export default class AppSoundManager {

	/******************************************
	* CLASS PROPERTIES
	******************************************/
	/**
	 * id - id of the sound in the _howlSounds array
	 * options - Howl object options
	 */
	static _soundsList: Array<SoundItem> = [
		{
			'id': 'buttonDefault',
			'options': {
				src: ['/assets/sound/button.mp3'],
				volume: 0.5,
			}
		}, {
			'id': 'win',
			'options': {
				src: ['/assets/sound/win.webm', '/assets/sound/win.mp3'],
                volume: 0.9,
                html5: true,
			}
		}, {
			'id': 'fail',
			'options': {
				src: ['/assets/sound/lose.mp3'],
                volume: 0.9,
                html5: true,
			}
		}, {
			'id': 'action',
			'options': {
				src: ['/assets/sound/any-other-action.mp3'],
                volume: 0.5,
                html5: true,
			}
		}, {
			'id': 'nodeClick',
			'options': {
				src: ['/assets/sound/node-click.mp3'],
                volume: 0.5,
                html5: true,
			}
		}, {
			'id': 'addMovesLifes',
			'options': {
				src: ['/assets/sound/add-moves-lifes.mp3'],
                volume: 0.5,
                html5: true,
			}
		}, {
			'id': 'boosterHint',
			'options': {
				src: ['/assets/sound/booster-hint.mp3'],
                volume: 0.5,
                html5: true,
			}
		}, {
			'id': 'boosterMoveBack',
			'options': {
				src: ['/assets/sound/booster-move-back.mp3'],
                volume: 0.5,
                html5: true,
			}
		},
	];
	/**
	 * List of all bg sound, may be randomized by _randomizeBgSoundOrder method
	 * Sounds are being loaded while playing
	 * id - id of the sound in the _howlSounds array
	 * active - indicate active playing or paused background sound
	 * options - Howl object options
	 */
	static _bgSoundsList: Array<BgSoundItem> = [
		{
			'id': 'bg-1',
			'active': true,
			'options': {
				src: ['/assets/sound/DG-OST.mp3'],
				volume: 0.8,
				html5: true,
			}
		}, {
			'id': 'bg-2',
			'options': {
				src: ['/assets/sound/TDG-OST-2.mp3'],
				volume: 0.8,
				html5: true,
			}
		}, {
			'id': 'bg-3',
			'options': {
				src: ['/assets/sound/TDG-OST-3.mp3'],
				volume: 0.8,
				html5: true,
			}
		}, {
			'id': 'bg-4',
			'options': {
				src: ['/assets/sound/TDG-OST-4.mp3'],
				volume: 0.8,
				html5: true,
			}
		}
	];
	static _howlSounds: any = {}; // howl instances
	// when bg sound ended we run next one from the _bgSoundsList
	static _handlerBgSoundEndBind: any = null;

	/******************************************
	* CLASS GETTERS
	******************************************/

	static get isInitialized(): boolean {
		return size(this._howlSounds) > 0;
	}

	static get isSoundsEnabled(): boolean {
		return !!get(store.getState(), 'settings.sounds');
	}

	static get isMusicEnabled(): boolean {
		return !!get(store.getState(), 'settings.music');
	}

	/******************************************
	* CLASS METHODS
	******************************************/

	/**
		* Creates howl instances, loades sounds
		* Run this method before working with sounds
		* @param {boolean} randomizeBgSound - shuffle _bgSoundsList if argument is true
	*/
	static init(randomizeBgSound?: boolean) {
		if (this.isInitialized) return;
		window.Howler.autoUnlock = false;
		Howler.autoUnlock = false;
		if (randomizeBgSound) this._randomizeBgSoundOrder();
        // this._playBgMusicOnPageLoad();
		// load other app sounds
		forEach(this._soundsList, (soundItem) => {
			this._howlSounds[soundItem.id] = new Howl(soundItem.options);
		});
	}

	/*
		* Stops playing background music
	*/
	static stopBackgroundMusic(pause: boolean) {
		if (!this.isInitialized) return;
		forEach(this._bgSoundsList, (soundItem) => {
			const howlInstance = this._howlSounds[soundItem.id];
			if (howlInstance && howlInstance.playing()) {
				if (pause) {
					howlInstance.pause();
				} else {
					howlInstance.stop();
				}
				howlInstance.off('end', this._handlerBgSoundEndBind);
			}
		});
	}

	/*
		* Pause background music
	*/
	static pauseBackgroundMusic() {
		this.stopBackgroundMusic(true);
	}

	/*
		* Starts active background sound
	*/
	static startBackgroundMusic() {
		if (!this.isInitialized) this.init();
		if (!this.isMusicEnabled) return;
		if (!isTabVisible()) return;
		let activeBgSound = this._getActiveBgSound();
		if (!activeBgSound) {
			activeBgSound = this._bgSoundsList[0];
			activeBgSound.active = true;
		}
		let howlItem = this._howlSounds[activeBgSound.id];
		if (!howlItem) {
			howlItem = this._howlSounds[activeBgSound.id] = new Howl(activeBgSound.options);
		}
		if (howlItem.playing()) return;
		if (!this._handlerBgSoundEndBind) {
			this._handlerBgSoundEndBind = this._handlerBgSoundEnd.bind(this);
		}
		howlItem.off('end', this._handlerBgSoundEndBind);
		howlItem.once('end', this._handlerBgSoundEndBind);
		howlItem.play();
	}

	/*
		* Toggles sounds
	*/
	static toggleSounds() {
		store.dispatch(setSettings({sounds: !this.isSoundsEnabled}));
	}

	/*
		* Toggles bg music according on localStorage value this.isMusicEnabled
		* Then play or stops music
	*/
	static toggleMusic() {
		this.toggleMusicSetting();
		if (this.isMusicEnabled) {
			this.startBackgroundMusic();
		} else {
			this.pauseBackgroundMusic();
		}
	}

	/*
		* Toggles bg music
	*/
	static toggleMusicSetting() {
		store.dispatch(setSettings({ music: !this.isMusicEnabled }));
	}

	static playButtonSound() {
		this._playSoundById('buttonDefault');
	}

	static playFailSound() {
		this._playSoundById('fail');
	}

	static playWinSound() {
		this._playSoundById('win');
	}

	static playActionSound() {
		this._playSoundById('action');
	}

	static playNodeClickSound() {
		this._playSoundById('nodeClick');
	}

	static playAddMovesLifesSound() {
		this._playSoundById('addMovesLifes');
	}

	static playBoosterHintSound() {
		this._playSoundById('boosterHint');
	}

	static playBoosterMoveBackSound() {
		this._playSoundById('boosterMoveBack');
	}

	/******************************************
	* CLASS PRIVATE METHODS
	******************************************/

	static _playSoundById(soundId: string) {
		if (!this.isInitialized) return;
		if (!this.isSoundsEnabled) return;
		if (!this._howlSounds[soundId]) {
			console.warn(`${soundId} sound not found!`);
			return;
		}
		this._howlSounds[soundId].play();
	}

	/**
	 * After active bg sound end, play next one from the _bgSoundsList
	 */
	static _handlerBgSoundEnd() {
		const activeBgSound: any = this._getActiveBgSound();
		const nextActiveIndex = this._bgSoundsList.indexOf(activeBgSound) + 1;
		let nextActiveBgSound = this._bgSoundsList[nextActiveIndex];
		if (activeBgSound) activeBgSound.active = false;
		if (!nextActiveBgSound) {
			nextActiveBgSound = this._bgSoundsList[0];
		}
		nextActiveBgSound.active = true;
		if (!this._howlSounds[nextActiveBgSound.id]) {
			// create Howl instance for the new sound if it hasn't created yet
			this._howlSounds[nextActiveBgSound.id] = new Howl(nextActiveBgSound.options);
		}
		this.startBackgroundMusic();
	}

	static _getActiveBgSound(): BgSoundItem|void {
		return this._bgSoundsList.find((soundItem) => soundItem.active === true);
	}

	/**
	 * Shuffle _bgSoundsList array, reset active bg sound
	 */
	static _randomizeBgSoundOrder() {
		const activeBgSound = this._getActiveBgSound();
		if (activeBgSound) activeBgSound.active = false;
		this._bgSoundsList = shuffle(this._bgSoundsList);
		this._bgSoundsList[0].active = true;
    }
    
    static _playBgMusicOnPageLoad() {
        // load first active background sound
        let activeBgSound: any = this._getActiveBgSound();
		if (!activeBgSound) activeBgSound = this._bgSoundsList[0];
        this._howlSounds[activeBgSound.id] = new Howl(activeBgSound.options);
        const howlSound = this._howlSounds[activeBgSound.id];
        howlSound.once('unlock', () => {
			// console.log('unlocked');
			// can't play music until user performs interaction
			// Chrome autoplay policy changes https://goo.gl/7K7WLu

			// check that music is not playing
			// (playing() method doesn't work correctly in this case, therefore I had to use seek())
			if (howlSound.seek() === 0) {
				howlSound.stop();
				this.startBackgroundMusic();
			}
		});
    }
}

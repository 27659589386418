import { Component } from 'react';
import { connect } from 'react-redux';

// redux
import {
  reset, toggleSettings, toggleBackBtn, toggleUpdateBtn,
  toggleUserLives, toggleTitle,
} from 'redux/actions/header';


const mapStateToProps = state => ({
  headerBackBtnClick: state.header.backBtnClick,
  headerUpdateBtnClick: state.header.updateBtnClick,
});

const mapDispatchToProps = dispatch => ({
  reset: () => dispatch(reset()),
  showHeaderSettings: (toggle) => dispatch(toggleSettings(toggle)),
  showHeaderBackBtn: (toggle) => dispatch(toggleBackBtn(toggle)),
  showHeaderUpdateBtn: (toggle) => dispatch(toggleUpdateBtn(toggle)),
  showHeaderUserLives: (toggle) => dispatch(toggleUserLives(toggle)),
  showHeaderTitle: (toggle, title) => dispatch(toggleTitle(toggle, title)),
});

class PageHeaderManager extends Component {

	/******************************************
	* COMPONENT HOOKS
	******************************************/

  render() {
    return null;
  }

  componentDidMount() {
    this.updatePageHeader();
  }

  componentDidUpdate(prevProps) {
    // back btn visibility
    if (this.props.showBackBtn !== prevProps.showBackBtn) {
      this.updateVisibilityBackBtn();
    }
    
    // update btn visibility
    if (this.props.showUpdateBtn !== prevProps.showUpdateBtn) {
      this.updateVisibilityUpdateBtn();
    }
    
    // update user lives visibility
    if (this.props.showUserLives !== prevProps.showUserLives) {
      this.updateVisibilityUserLives();
    }
    
    // update settings visibility
    if (this.props.showSettings !== prevProps.showSettings) {
      this.updateVisibilitySettings();
    }
    
    // title changes
    if (this.props.title !== prevProps.title ||
      this.props.showTitle !== prevProps.showTitle) {
      this.updateTitle();
    }

    // back btn click
    if (this.props.headerBackBtnClick && this.props.headerBackBtnClick !== prevProps.headerBackBtnClick) {
      this.props.onBackClick();
    }
    
    // update btn click
    if (this.props.headerUpdateBtnClick && this.props.headerUpdateBtnClick !== prevProps.headerUpdateBtnClick) {
      this.props.onUpdateClick();
    }
  }

	/******************************************
	* COMPONENT METHODS
	******************************************/

  updatePageHeader() {
    // header management
    this.props.reset();
    this.updateVisibilityBackBtn();
    this.updateVisibilityUpdateBtn();
    this.updateVisibilityUserLives();
    this.updateVisibilitySettings();
    this.updateTitle();
  }

  updateVisibilityBackBtn() {
    this.props.showHeaderBackBtn(!!this.props.showBackBtn);
  }
  
  updateVisibilityUpdateBtn() {
    this.props.showHeaderUpdateBtn(!!this.props.showUpdateBtn);
  }

  updateVisibilityUserLives() {
    this.props.showHeaderUserLives(!!this.props.showUserLives);
  }
  
  updateVisibilitySettings() {
    this.props.showHeaderSettings(!!this.props.showSettings);
  }

  updateTitle() {
    this.props.showHeaderTitle(!!this.props.showTitle, this.props.title);
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PageHeaderManager);
import React from 'react';
import classNames from 'classnames';

import LifeIcon from 'shared/components/LifeIcon';

import { propTypes, defaultProps } from './props';
import styles from './LivesNumber.module.css';


class LivesNumber extends React.PureComponent {
	constructor(props) {
	  super(props);
	  this.state = {};
	}

	render() {
		const wrapperClasses = classNames({
		  [styles.LivesNumber]: true,
		  [this.props.className]: !!this.props.className,
		});
		return (
			<div className={wrapperClasses}>
				<LifeIcon className={styles.LivesNumberIcon} />
				<span className={styles.LivesNumberText}>{this.props.livesNumber}</span>
			</div>
		);
	}
}

LivesNumber.propTypes = propTypes;
LivesNumber.defaultProps = defaultProps;

export default LivesNumber;
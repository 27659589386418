export const SET_GAME = 'SET_GAME';
export const RESET_GAME = 'RESET_GAME';

export const CLEAR_STEPS_SPENT = 'CLEAR_STEPS_SPENT';
export const SET_STEPS_SPENT = 'SET_STEPS_SPENT';
export const SET_LEVEL_HASH = 'SET_LEVEL_HASH';
export const SET_MAX_STARS = 'SET_MAX_STARS';
export const SET_MAX_MOVES_COUNT = 'SET_MAX_MOVES_COUNT';
export const SET_BEST_MOVES_COUNT = 'SET_BEST_MOVES_COUNT';
export const SET_INFINITE_STEPS = 'SET_INFINITE_STEPS';

export const COMPLETE_LEVEL_REQUEST = 'COMPLETE_LEVEL_REQUEST';
export const COMPLETE_LEVEL_SUCCESS = 'COMPLETE_LEVEL_SUCCESS';
export const COMPLETE_LEVEL_FAILURE = 'COMPLETE_LEVEL_FAILURE';

export const SET_GAME_PASSED = 'SET_GAME_PASSED';
export const SET_GAME_LOST = 'SET_GAME_LOST';
export const SET_MOVES_RUN_OUT = 'SET_MOVES_RUN_OUT';
export const SET_CHEAT_STARS = 'SET_CHEAT_STARS';

export const SET_BEST_VERTEX_COORDS = 'SET_BEST_VERTEX_COORDS';
export const SET_GRAPH_COORDS = 'SET_GRAPH_COORDS';

export const SET_STEPS_LEFT = 'SET_STEPS_LEFT';
export const SET_NOT_BEST_VERTEX_DATA = 'SET_NOT_BEST_VERTEX_DATA';

// actions for game component
export const BEST_VERTEX_CLICK_REQUIRE = 'BEST_VERTEX_CLICK_REQUIRE';
export const NOT_BEST_VERTEX_CLICK_REQUIRE = 'NOT_BEST_VERTEX_CLICK_REQUIRE';
export const SHOW_HINT_REQUIRE = 'SHOW_HINT_REQUIRE';
export const UPDATE_BEST_VERTEX_COORD_REQUIRE = 'UPDATE_BEST_VERTEX_COORD_REQUIRE';
export const UPDATE_GRAPH_COORD_REQUIRE = 'UPDATE_GRAPH_COORD_REQUIRE';
export const UPDATE_STEPS_LEFT_REQUIRE = 'UPDATE_STEPS_LEFT_REQUIRE';
export const UPDATE_NOT_BEST_VERTEX_DATA_REQUIRE = 'UPDATE_NOT_BEST_VERTEX_DATA_REQUIRE'; // for tutorial "move back"
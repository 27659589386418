import { UPDATE_ACTIVE_LEVEL_ID, SET_TUTORIAL_PASSED, RESET_TUTORIAL } from '../../redux/actionTypes';
import { updateSettingsTutorial } from './settings';


export const updateActiveLevelId = (activeLevelId?: number) => ({
  type: UPDATE_ACTIVE_LEVEL_ID,
  activeLevelId
});

export const passTutorial = () => {
  return function (dispatch: any) {
    dispatch({ type: SET_TUTORIAL_PASSED });
    updateSettingsTutorial()(dispatch);
  };
};

export const resetTutorial = () => {
  return function (dispatch: any) {
    dispatch({ type: RESET_TUTORIAL });
    updateSettingsTutorial([])(dispatch);
  };
};

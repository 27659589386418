import {
  GET_LIVES_REQUEST,
  GET_LIVES_SUCCESS,
  GET_LIVES_FAILURE,
  RESET_LIVES_NUMBER,
  SET_NEW_LIFE_TIMER,
  CLEAR_NEW_LIFE_TIMER
} from 'redux/actionTypes';
import * as livesAPI from 'shared/api/livesAPI';
import { Dispatch } from 'redux';

export const getLivesRequest = () => ({
  type: GET_LIVES_REQUEST
});

export const getLivesSuccess = (lives: livesAPI.IPutLivesParams) => ({
  type: GET_LIVES_SUCCESS,
  lives
});

export const getLivesFailure = () => ({
  type: GET_LIVES_FAILURE
});

export const resetLivesNumber = () => ({
  type: RESET_LIVES_NUMBER
});

export const setNewLifeTimer = () => ({
  type: SET_NEW_LIFE_TIMER
});

export const clearNewLifeTimer = () => ({
  type: CLEAR_NEW_LIFE_TIMER
});

/* ASYNC ACTION CREATORS */

export function getLivesPromise(promise: Promise<livesAPI.IPutLivesParams>) {
  return function(dispatch: Dispatch, _?: () => any) {
    dispatch(getLivesRequest());
    return promise.then(
      lives => {
        dispatch(getLivesSuccess(lives));
      },
      () => {
        dispatch(getLivesFailure());
      }
    );
  };
}

export const addLives = () => getLivesPromise(livesAPI.increaseLivesNumber());
export const removeLife = () => getLivesPromise(livesAPI.deleteLivesNumber());
export const getLives = () => getLivesPromise(livesAPI.fetchLives());
// <-- METHODS ONLY FOR DEVELOPERS ROLES

export function addLivesDeveloper() {
  return (dispatch: Dispatch, getState: () => any) => {
    const lives = getState().lives as livesAPI.IPutLivesParams;
    let livesNumber = lives.livesNumber + 1;
    if (livesNumber > lives.maxLivesNumber) {
      livesNumber = lives.maxLivesNumber;
    }
    putLives({ livesNumber })(dispatch, getState);
  };
}

export function removeLivesDeveloper() {
  return (dispatch: Dispatch, getState: () => any) => {
    const lives = getState().lives as livesAPI.IPutLivesParams;
    let livesNumber = lives.livesNumber - 1;
    if (livesNumber < 0) {
      livesNumber = 0;
    }
    putLives({ livesNumber })(dispatch, getState);
  };
}

export function putLives(lives: Partial<livesAPI.IPutLivesParams>) {
  return function(dispatch: Dispatch, getState: () => any) {
    const request = getState().user.developer
      ? livesAPI.putLives(lives)
      : Promise.reject(new Error('This API available only for developers'));

    return getLivesPromise(request)(dispatch, getState);
  };
}

//  METHODS ONLY FOR DEVELOPERS ROLES -->

const separator = '-';

/**
 * Creates entity id of the pack in normalized data
 * @param  {number} worldId
 * @param  {number} packId
 * @return {string}
 */
export const createPackEntityId = (worldId: number, packId: number): string => {
  return `${worldId}${separator}${packId}`;
};

/**
 * Creates entity id of the level in normalized data
 * @param  {number} worldId
 * @param  {number} packId
 * @param  {number} levelId
 * @return {string}
 */
export const createLevelEntityId = (worldId: number, packId: number, levelId: number): string => {
  return `${worldId}${separator}${packId}${separator}${levelId}`;
};

export const parseLevelEntityId = (levelEntityId: string): any => {
  const data = levelEntityId.split(separator);
  return {
    worldId: data[0],
    packId: data[1],
    levelId: data[2]
  };
};
import isEqual from 'lodash/isEqual';
import pick from 'lodash/pick';

import * as settingsAPI from 'shared/api/settingsAPI';

import {
  putSettingsRequest, putSettingsSuccess, putSettingsFailure,
} from 'redux/actions/settings';


const fields = ['music', 'sounds', 'language'];

export const saveSettingsOnChangeMiddleware = () => store => next => action => {
  // Get the state before and after the action was performed
  const prevState = store.getState();
  const isGetSettingsInProcess = prevState.settings.isGetSettingsInProcess;
  
  const prevSettings = pick(prevState.settings, fields);
  next(action);
  const nextSettings = pick(store.getState().settings, fields);
  
  // Save data via API on changes
  // If it wasn't change after GET request
  if (!isEqual(prevSettings, nextSettings) && !isGetSettingsInProcess) {
    store.dispatch(putSettingsRequest());
    return settingsAPI.putSettings(nextSettings)
      .then(() => {
        store.dispatch(putSettingsSuccess());
      }, () => {
        store.dispatch(putSettingsFailure());
      });
  }
};

import PropTypes from 'prop-types';

export const propTypes = {
  listItem: PropTypes.shape({
  	height: PropTypes.number.isRequired,
  	marginBottom: PropTypes.number
  }),
};

export const defaultProps = {
  listItem: {},
};
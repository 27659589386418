/* eslint react/jsx-pascal-case: [0, { ignore: 1 }] */

import React from 'react';
import classNames from 'classnames';

import { propTypes, defaultProps } from './props';

// styles
import styles from './BackBtn.module.css';


class BackBtn extends React.PureComponent {

  /******************************************
  * COMPONENT HOOKS
  ******************************************/

  render() {
    const btnClasses = classNames({
      [styles.BackBtn]: true,
      [styles.Reversed]: this.props.reversed,
      [styles.Transparent]: this.props.transparent,
      [this.props.className]: !!this.props.className
    });

    return (
      <button className={btnClasses}
        onClick={this.props.onClick && this.props.onClick}>
      </button>
    );
  }
}

BackBtn.propTypes = propTypes;
BackBtn.defaultProps = defaultProps;

export default BackBtn;

export const GET_SETTINGS_REQUEST = 'GET_SETTINGS_REQUEST';
export const GET_SETTINGS_SUCCESS = 'GET_SETTINGS_SUCCESS';
export const GET_SETTINGS_FAILURE = 'GET_SETTINGS_FAILURE';
export const PUT_SETTINGS_REQUEST = 'PUT_SETTINGS_REQUEST';
export const PUT_SETTINGS_SUCCESS = 'PUT_SETTINGS_SUCCESS';
export const PUT_SETTINGS_FAILURE = 'PUT_SETTINGS_FAILURE';
export const SET_SETTINGS = 'SET_SETTINGS';

export const GET_SETTINGS_TUTORIAL_REQUEST = 'GET_SETTINGS_TUTORIAL_REQUEST';
export const GET_SETTINGS_TUTORIAL_SUCCESS = 'GET_SETTINGS_TUTORIAL_SUCCESS';
export const GET_SETTINGS_TUTORIAL_ERROR = 'GET_SETTINGS_TUTORIAL_ERROR';

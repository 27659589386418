/* eslint react/jsx-pascal-case: [0, { ignore: 1 }] */

import React from 'react';
import classNames from 'classnames';

import { propTypes, defaultProps } from './props';

// styles
import styles from './UpdateBtn.module.css';


class UpdateBtn extends React.PureComponent {

  /******************************************
  * COMPONENT HOOKS
  ******************************************/

  render() {
    const btnClasses = classNames({
      [styles.UpdateBtn]: true,
      [styles.Transparent]: this.props.transparent,
      [this.props.className]: !!this.props.className,
    });

    return (
      <button className={btnClasses}
        onClick={this.props.onClick.bind(this)}>
      </button>
    );
  }
}

UpdateBtn.propTypes = propTypes;
UpdateBtn.defaultProps = defaultProps;

export default UpdateBtn;

import { normalize } from 'normalizr';

import {
  CLEAR_PROGRESS_REQUEST, CLEAR_PROGRESS_SUCCESS, CLEAR_PROGRESS_FAILURE,
  GET_WORLDS_PROGRESS_SUCCESS, GET_WORLDS_PROGRESS_FAILURE, RESET_WORLDS_PROGRESS,
  UPDATE_LEVEL_PROGRESS_SUCCESS, UPDATE_LEVEL_PROGRESS_FAILURE,
  CALC_ALL_ACHIEVED_STARS,
} from '../../redux/actionTypes';
import { getLevelProgress } from '../../redux/selectors/progress';
import { clearLastVisitedLevel, clearAllNextPackMsgShowed } from '../../shared/helpers/progress';
import { resetBoosters } from '../../redux/actions/boosters';
import { resetLivesNumber } from '../../redux/actions/lives';
import { resetTutorial, updateActiveLevelId } from '../../redux/actions/tutorial';

import * as progressAPI from '../../shared/api/progressAPI';
// import { WorldProgress } from '../../shared/api/progressAPI';

import { ProgressListSchema, ProgressEntities } from '../../shared/schemas/progress';
import { WorldsEntities } from '../../shared/schemas/worlds';


export const clearProgressRequest = () => ({
  type: CLEAR_PROGRESS_REQUEST,
});

export const clearProgressSuccess = () => ({
  type: CLEAR_PROGRESS_SUCCESS,
});

export const clearProgressFailure = () => ({
  type: CLEAR_PROGRESS_FAILURE,
});

export const getWorldsProgressSuccess = (progress: ProgressEntities) => ({
  type: GET_WORLDS_PROGRESS_SUCCESS,
  progress,
});

export const getWorldsProgressFailure = () => ({
  type: GET_WORLDS_PROGRESS_FAILURE,
});

export const resetWorldsProgress = () => ({
  type: RESET_WORLDS_PROGRESS,
});

export const updateLevelProgressSuccess = (worldId: number, packId: number, levelId: number, hash: string, completed: boolean, stepsSpent: number, addMovesAttempts?: number, cheatStars?: number) => ({
  type: UPDATE_LEVEL_PROGRESS_SUCCESS,
  worldId, packId, levelId, hash, completed, stepsSpent,
  addMovesAttempts, cheatStars,
});

export const updateLevelProgressFailure = () => ({
  type: UPDATE_LEVEL_PROGRESS_FAILURE,
});

export const calcAchievedStars = (worldsData: WorldsEntities) => ({
  type: CALC_ALL_ACHIEVED_STARS,
  worldsData,
});

/**
 * Requires both worlds and progress data from the store!
 */
export function calcAchievedStarsAsync() {
  return function(dispatch: any, getState: any) {
    const normalizedWorldsData = getState().worlds.entities;
    dispatch(calcAchievedStars(normalizedWorldsData));
  };
};

export function clearProgress() {
  return function(dispatch: any, getState: any) {
    dispatch(clearProgressRequest());
    return progressAPI.deleteProgress().then(() => {
      clearLastVisitedLevel();
      clearAllNextPackMsgShowed();
      dispatch(resetTutorial());
      dispatch(updateActiveLevelId());
      dispatch(resetBoosters());
      dispatch(resetLivesNumber());
      dispatch(resetWorldsProgress());
      dispatch(clearProgressSuccess());
    }, () => {
      dispatch(clearProgressFailure());
    });
  };
};

export const getProgress = () => {
  return (dispatch: any, getState: any) => {
    return progressAPI.fetchProgress()
      .then((progress) => {
        // Normalize data, see ProgressEntities interface structure
        const normalizedProgress = normalize(progress, ProgressListSchema);
        dispatch(getWorldsProgressSuccess(normalizedProgress.entities));
        return normalizedProgress.entities;
      }, (error) => {
        dispatch(getWorldsProgressFailure());
      });
  };
};

export const updateLevelProgress = (worldId: number, packId: number, levelId: number, hash: string, completed: boolean, stepsSpent: number, addMovesAttempts: number, cheatStars?: number) => {
  return (dispatch: any, getState: any) => {
    const progress = getState().progress.progress;
    let apiCall;
    if (getLevelProgress(worldId, packId, levelId, progress)) {
      apiCall = progressAPI.putProgress(worldId, packId, levelId, hash, completed, stepsSpent, addMovesAttempts);
    } else {
      apiCall = progressAPI.postProgress(worldId, packId, levelId, hash, completed, stepsSpent, addMovesAttempts);
    }
    return apiCall.then(() => {
      return new Promise((resolve) => {
        // Update level's progress in the store
        dispatch(updateLevelProgressSuccess(worldId, packId, levelId, hash, completed, stepsSpent, addMovesAttempts, cheatStars));
        // Recalculate achieved stars
        const normalizedWorldsData = getState().worlds.entities;
        dispatch(calcAchievedStars(normalizedWorldsData));
        resolve(true);
      });
    }, (error) => {
      dispatch(updateLevelProgressFailure());
    });
  };
};

/*
  REDUCERS FOR MODALS MANAGEMENT
*/
import find from 'lodash/find';
import remove from 'lodash/remove';
import produce from 'immer';

import { SHOW_MODAL, HIDE_MODAL } from 'redux/actionTypes';
import { ModalAction, ModalType } from 'redux/actions/modals';
export interface IModal {
  type: ModalType;
  props: any;
  show: boolean;
  active?: boolean;
  metrica?: any
}

interface InitialStateProps {
  modals: IModal[]
}

const initialState: InitialStateProps = {
  modals: [],
};

export default produce((draftState, action: ModalAction) => {
  switch (action.type) {
    case SHOW_MODAL: {
      const activeModal = find(draftState.modals, { active: true });
      if (activeModal) activeModal.active = false;

      remove(draftState.modals, {type: action.modalType});

      draftState.modals.push({
        type: action.modalType,
        props: action.modalProps ? action.modalProps : {},
        metrica: action.metrica,
        show: true,
        active: true,
      });
      break;
    }
    case HIDE_MODAL: {
      const modal = find(draftState.modals, {type: action.modalType});

      if (modal) {
        modal.show = false;
      }
      break;
    }
  }
}, initialState)

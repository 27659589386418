import isNil from 'lodash/isNil';

/**
 * Returns restore minutes in milliseconds
 * @return {number} - minutes
 */
export const minToMs = (mins: number | string) => {
  if (typeof mins === 'number') {
    return mins * 1000 * 60;
  }
  if (typeof mins !== 'string') {
    return 0;
  }
  if (/^(\d+):(\d+)$/.exec(mins)) {
    const m = +RegExp.$1;
    const s = +RegExp.$2;
    return (m * 60 + s) * 1000;
  }
  const v = parseInt(mins, 10);
  if (Number.isNaN(v)) {
    return 0;
  }
  return v * 1000 * 60;
};

/**
 * Gets minutes amount since new life timer started
 * @return {number} - minutes
 */
export const getMinutesPassed = (newLifeTimer: number | null): number => {
  if (isNil(newLifeTimer)) return 0;
  const now = Date.now();
  const diff = Math.abs(now - newLifeTimer);
  return diff / 1000 / 60;
};

/**
 * Gets restored lives number
 * For example user with 1 life stops the game and gets back in 60min
 * So we should add to user 60min/20min = 3 lives
 * @return {number} - restored lives number
 */
export const getRestoredLivesNumber = (
  newLifeTimer: number | null,
  lifeRestoreMinutes: number
): number => {
  return Math.floor(getMinutesPassed(newLifeTimer) / lifeRestoreMinutes);
};

/* eslint react/jsx-pascal-case: [0, { ignore: 1 }] */

import React from 'react';
import classNames from 'classnames';
import isNil from 'lodash/isNil';

// styles
import styles from './LifeIcon.module.css';


class LifeIcon extends React.PureComponent {

  /******************************************
  * COMPONENT HOOKS
  ******************************************/

  render() {
    const iconClasses = classNames({
      [styles.LifeIcon]: true,
      [styles.SizeBig]: this.props.size === 'big',
      [styles[`Color${this.props.color}`]]: !isNil(this.props.color),
      [this.props.className]: !!this.props.className,
    });

    return (
      <i className={iconClasses}></i>
    );
  }
}

export default LifeIcon;

import { createStore, compose, applyMiddleware } from 'redux';
import thunkMiddleware from 'redux-thunk';

import rootReducer from './reducers';
import { saveBoostersOnChangeMiddleware } from './middlewares/boosters';
import { updateNewLifeTimerMiddleware } from './middlewares/lives';
import { saveSettingsOnChangeMiddleware } from './middlewares/settings';
import { finishGameCheatMiddleware } from './middlewares/game';


const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const middlewares = [
	thunkMiddleware,
	saveBoostersOnChangeMiddleware(),
	updateNewLifeTimerMiddleware(),
	saveSettingsOnChangeMiddleware(),
  finishGameCheatMiddleware(),
];

if (process.env.REACT_APP_MODE === 'stage' && process.env.REACT_APP_REDUX_LOGGER === 'true') {
    const logger = require('redux-logger');
    middlewares.push(logger);
}

export default createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(...middlewares)),
);

/* eslint react/jsx-pascal-case: [0, { ignore: 1 }] */

import React from 'react';
import classNames from 'classnames';

// styles
import styles from './PageTitle.module.css';
import 'shared/styles/header.css';


class PageTitle extends React.PureComponent {

  /******************************************
  * COMPONENT HOOKS
  ******************************************/

  render() {
    const { className, children } = this.props;

    const titleClasses = classNames({
      [styles.PageTitle]: true,
      [className]: !!className,
    });

    return (
      <h3 className={titleClasses}>{children}</h3>
    );
  }
}

export default PageTitle;
/* eslint react/jsx-pascal-case: [0, { ignore: 1 }] */

import React, { ComponentType } from 'react';
import { connect } from 'react-redux'
import delay from 'lodash/delay';
import size from 'lodash/size';
import loadable from '@loadable/component';
import { Dispatch } from 'redux';

// constants
import {
  MODAL_APPEAR_TIME, LIFE_REDUCE_MODAL_SHOW_TIME, MODAL_LIFE_REDUCE
} from 'shared/constants';
import { ICustomModalProps } from 'shared/components/CustomModal/CustomModal';

// redux
import {
  hideModal, hideLifeReduceModal, showSecondSessionModal, hideSecondSessionModal,
} from 'redux/actions/modals';
import { removeLife } from 'redux/actions/lives';
import { modalsSelector, isModalShowedSelector } from 'redux/selectors/modals';
import { IModal } from 'redux/reducers/modals';
import { ModalType } from 'redux/actions/modals';

import styles from './ModalManager.module.css';
import 'shared/styles/modalAnimation.css';

// loadable components
const ModalsLoadable = loadable.lib(() => import('shared/components/modals'));

interface IStateToProps {
  modals: IModal[];
  isLifeReduceModalShowed: boolean;
  livesNumber: number;
  isSecondSession: boolean;
}

interface IDispatchToProps {
  hideModal: (modalType: ModalType) => void;
  hideLifeReduceModal: () => void;
  showSecondSessionModal: () => void;
  hideSecondSessionModal: () => void;
  updateSettingsTutorial: () => void;
  removeLife: () => any;
}

type ModalManagerProps = IStateToProps & IDispatchToProps;

const mapStateToProps = (state: any): IStateToProps => {
  const isModalShowed = isModalShowedSelector(state);
  return ({
    modals: modalsSelector(state),
    isLifeReduceModalShowed: isModalShowed(MODAL_LIFE_REDUCE),
    livesNumber: state.lives.livesNumber,
    isSecondSession: state.secondSession.isSecondSession,
  });
};

const mapDispatchToProps = (dispatch: Dispatch<any>) => ({
  hideModal: (modalType: ModalType) => dispatch(hideModal(modalType)),
  hideLifeReduceModal: () => dispatch(hideLifeReduceModal()),
  showSecondSessionModal: () => dispatch(showSecondSessionModal()),
  hideSecondSessionModal: () => dispatch(hideSecondSessionModal()),
  removeLife: () => dispatch(removeLife()),
});

class ModalManager extends React.PureComponent<ModalManagerProps> {

  /******************************************
  * COMPONENT HOOKS
  ******************************************/

  render() {
    return (
      <div className={styles.ModalManager}>
        {size(this.props.modals) &&
          <ModalsLoadable>
            {({ default: loadedModals }) => (
              this.props.modals.map((modal: IModal) => {
                const SpecificModal: ComponentType<ICustomModalProps & any> = loadedModals[modal.type];
                if (!SpecificModal) return null;
                return (
                  <SpecificModal
                    key={modal.type}
                    timeout={MODAL_APPEAR_TIME}
                    isOpen={modal.show}
                    active={modal.active}
                    metrica={modal.metrica}
                    ariaHideApp={false}
                    {...modal.props}
                    onBackBtnClick={this.handlerBackClick.bind(this, modal.type)}>
                  </SpecificModal>
                );
              })
            )}
          </ModalsLoadable>
        }
          {/* <LifeReduceModal lifeNumber={this.props.livesNumber}></LifeReduceModal> */}
      </div>
    );
  }

  componentDidMount() {
    if (this.props.isSecondSession) {
      this.toggleSecondSessionModal();
    }
  }

  componentDidUpdate(prevProps: Readonly<ModalManagerProps>) {
    if (this.props.isLifeReduceModalShowed !== prevProps.isLifeReduceModalShowed &&
      this.props.isLifeReduceModalShowed) {
      delay(() => {
        this.props.hideLifeReduceModal();
        delay(() => {
          this.props.removeLife();
        }, MODAL_APPEAR_TIME);
      }, LIFE_REDUCE_MODAL_SHOW_TIME);
    }

    if (this.props.isSecondSession !== prevProps.isSecondSession) {
      this.toggleSecondSessionModal();
    }
  }

  /******************************************
	* COMPONENT HANDLERS
  ******************************************/

  handlerBackClick = (modalType: ModalType) => {
    this.props.hideModal(modalType);
  }

  /******************************************
	* COMPONENT METHODS
	******************************************/

  toggleSecondSessionModal() {
    if (this.props.isSecondSession) {
      this.props.showSecondSessionModal();
    } else {
      this.props.hideSecondSessionModal();
    }
  }
}

export default connect<IStateToProps, typeof mapDispatchToProps, {}, {}>(mapStateToProps, mapDispatchToProps)(ModalManager);

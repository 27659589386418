import { GET_ADS_REQUEST, GET_ADS_SUCCESS, GET_ADS_FAILURE } from 'redux/actionTypes';
import { getAdsData } from 'shared/api/adsAPI';

/* SYNC ACTION CREATORS */


export const getAdsRequest = () => ({
  type: GET_ADS_REQUEST,
});
export const getAdsSuccess = (ads) => ({
  type: GET_ADS_SUCCESS,
  ads,
});

export const getAdsFailure = () => ({
  type: GET_ADS_FAILURE,
});

/* ASYNC ACTION CREATORS */

export function getAds() {
  return function (dispatch) {
    dispatch(getAdsRequest());

    return getAdsData()
      .then(adsData => {
        dispatch(getAdsSuccess(adsData));
      })
      .catch(() => {
        dispatch(getAdsFailure());
      });
  };
};

import {
  GET_SETTINGS_REQUEST, GET_SETTINGS_SUCCESS, GET_SETTINGS_FAILURE,
  PUT_SETTINGS_REQUEST, PUT_SETTINGS_SUCCESS, PUT_SETTINGS_FAILURE,
  SET_SETTINGS,
  GET_SETTINGS_TUTORIAL_REQUEST,
  GET_SETTINGS_TUTORIAL_SUCCESS,
  GET_SETTINGS_TUTORIAL_ERROR
} from 'redux/actionTypes';


const initialState = {
  music: true,
  sounds: true,
  language: null,
  isGetSettingsInProcess: false,
  isGetSettingsError: false,
  isPutSettingsInProcess: false,
  isPutSettingsError: false,
  isGettingTutorialInProgress: false,
  isGettingTutorialError: false,
  tutorialProgress: null
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_SETTINGS_TUTORIAL_REQUEST: {
      return {
        ...state,
        isGettingTutorialInProgress: true,
        isGettingTutorialError: false
      }
    }
    case GET_SETTINGS_TUTORIAL_SUCCESS: {
      return {
        ...state,
        tutorialProgress: action.data,
        isGettingTutorialInProgress: false,
        isGettingTutorialError: false
      }
    }
    case GET_SETTINGS_TUTORIAL_ERROR: {
      return {
        ...state,
        isGettingTutorialInProgress: false,
        isGettingTutorialError: true
      }
    }
    case GET_SETTINGS_REQUEST: {
      return {
        ...state,
        isGetSettingsInProcess: true,
        isGetSettingsError: false,
      }
    }
    case GET_SETTINGS_SUCCESS: {
      return {
        ...state,
        ...action.settings,
        isGetSettingsInProcess: false,
        isGetSettingsError: false,
      };
    }
    case GET_SETTINGS_FAILURE: {
      return {
        ...state,
        isGetSettingsInProcess: false,
        isGetSettingsError: true,
      };
    }
    case SET_SETTINGS: {
      return {
        ...state,
        ...action.settings,
      };
    }
    case PUT_SETTINGS_REQUEST: {
      return {
        ...state,
        isPutSettingsInProcess: true,
        isPutSettingsError: false,
      }
    }
    case PUT_SETTINGS_SUCCESS: {
      return {
        ...state,
        ...action.settings,
        isPutSettingsInProcess: false,
        isPutSettingsError: false,
      };
    }
    case PUT_SETTINGS_FAILURE: {
      return {
        ...state,
        isPutSettingsInProcess: false,
        isPutSettingsError: true,
      };
    }
    default:
      return state;
  }
}

import { createSelector } from 'reselect';
import memoize from 'lodash/memoize';
import find from 'lodash/find';
import filter from 'lodash/filter';


export const modalsSelector = state => state.modals.modals;

export const isModalShowedSelector = createSelector(
  [modalsSelector],
  modals => memoize(
    modalType => {
      const modal = find(modals, { type: modalType });
      return modal ? modal.show : false;
    }
  )
);

export const showedModalsSelector = createSelector(
  [modalsSelector],
  modals => filter(modals, {show: true})
);
/*
  ACTIONS FOR MODALS MANAGEMENT
*/

import { SHOW_MODAL, HIDE_MODAL } from 'redux/actionTypes';

// constants
import {
  MODAL_LIFE_PURCHASE, MODAL_LIFE_REDUCE, MODAL_SETTINGS, MODAL_AD_BLOCK,
  MODAL_SECOND_SESSION, MODAL_WATCH_AD, MODAL_AD_NOT_FOUND, MODAL_CHANGE_PRICE
} from 'shared/constants';
import { getPrices } from 'shared/api/purchasesAPI';
import { Dispatch } from 'redux';

import { WarningTextType } from 'shared/helpers/adBlockDetector';
import { PurchaseItemType } from 'shared/helpers/modals';

const actionTypes = [
    MODAL_LIFE_PURCHASE, MODAL_LIFE_REDUCE, MODAL_SETTINGS, MODAL_AD_BLOCK,
    MODAL_SECOND_SESSION, MODAL_WATCH_AD, MODAL_AD_NOT_FOUND, MODAL_CHANGE_PRICE
] as const;

export type ModalType = typeof actionTypes[number];

export type ModalAction = {
    type: typeof SHOW_MODAL | typeof HIDE_MODAL;
    modalType: ModalType;
    modalProps?: any;
    metrica?: any;
}

/* SYNC ACTION CREATORS */

export const showModal = (modalType: ModalType, modalProps?: any, metrica?: any) => ({
  type: SHOW_MODAL,
  modalType,
  modalProps,
  metrica
});

export const hideModal = (modalType: ModalType) => ({
  type: HIDE_MODAL,
  modalType,
});

export const showLifeReduceModal = (lifeNumber: number) => {
  return showModal(MODAL_LIFE_REDUCE, { lifeNumber });
};

export const showLifePurchaseModal = (metrica: any) => {
  return (dispatch: Dispatch) => {
    getPrices().then(priceBlob => {
      dispatch(showModal(MODAL_LIFE_PURCHASE, priceBlob, metrica));
    });
  }

};

export const showSettingsModal = () => {
  return showModal(MODAL_SETTINGS);
};

export const showAdBlockModal = (warningTextType: WarningTextType) => {
  return showModal(MODAL_AD_BLOCK, { warningTextType });
};

export const showSecondSessionModal = () => {
  return showModal(MODAL_SECOND_SESSION);
};

export const showWatchAdModal = () => {
  return showModal(MODAL_WATCH_AD);
};

export const showAdNotFoundModal = (purchaseItem: PurchaseItemType) => {
  return showModal(MODAL_AD_NOT_FOUND, { purchaseItem });
};

export const hideLifeReduceModal = () => {
  return hideModal(MODAL_LIFE_REDUCE);
};

export const hideLifePurchaseModal = () => {
  return hideModal(MODAL_LIFE_PURCHASE);
};

export const hideSettingsModal = () => {
  return hideModal(MODAL_SETTINGS);
};

export const hideAdBlockModal = () => {
  return hideModal(MODAL_AD_BLOCK);
};

export const hideSecondSessionModal = () => {
  return hideModal(MODAL_SECOND_SESSION);
};

export const hideWatchAdModal = () => {
  return hideModal(MODAL_WATCH_AD);
};

export const hideAdNotFoundModal = () => {
  return hideModal(MODAL_AD_NOT_FOUND);
};

export const showChangePriceModal = () => showModal(MODAL_CHANGE_PRICE);
export const hideChangePriceModal = () => hideModal(MODAL_CHANGE_PRICE);

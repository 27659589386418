/* eslint react/jsx-pascal-case: [0, { ignore: 1 }] */

import { connect } from 'react-redux'
import React, { Component } from 'react';
import defer from 'lodash/defer';
import classNames from 'classnames';

// components
import BackBtn from 'shared/components/BackBtn';
import UpdateBtn from 'shared/components/UpdateBtn';
import Settings from 'shared/components/Settings';
import UserLives from 'shared/containers/UserLives';

// redux
import { backBtnClick, updateBtnClick } from 'redux/actions/header';

// styles
import styles from './Header.module.css';


const mapStateToProps = state => ({
  infiniteLife: state.lives.infiniteLife,
  title: state.header.title,
  showBackBtn: state.header.showBackBtn,
  showUserLives: state.header.showUserLives,
  showUpdateBtn: state.header.showUpdateBtn,
  showTitle: state.header.showTitle,
  showSettings: state.header.showSettings,
});

const mapDispatchToProps = dispatch => ({
  backBtnClick: (click) => dispatch(backBtnClick(click)),
  updateBtnClick: (click) => dispatch(updateBtnClick(click)),
});


class Header extends Component {

  render() {
    const {
      infiniteLife, title,
      showBackBtn, showUserLives, showUpdateBtn, showTitle, showSettings,
      className,
    } = this.props;

    const headerClasses = classNames({
      [styles.Header]: true,
      [className]: !!className,
    });

    return (
      <header className={headerClasses}>
        {/* Back button */}
        {!!showBackBtn &&
          <BackBtn className={styles.BackBtn}
            onClick={this.handlerBackBtnClick} />}

        {/* User lives */}
        {!!showUserLives && !infiniteLife && <UserLives />}

        {/* Update button */}
        {!!showUpdateBtn &&
          <UpdateBtn className={styles.UpdateBtn}
            onClick={this.handlerUpdateBtnClick} />
        }

        {/* Title */}
        {!!showTitle &&
          <span dangerouslySetInnerHTML={{__html: title}}
            className={styles.HeaderTitle}>
          </span>
        }

        {/* Settings */}
        {!!showSettings &&
          <Settings className={styles.Settigs} />
        }

      </header>
    );
  }

  handlerBackBtnClick = () => {
    // toggle click
    this.props.backBtnClick(true);
    defer(() => this.props.backBtnClick(false));
  }

  handlerUpdateBtnClick = () => {
    // toggle click
    this.props.updateBtnClick(true);
    defer(() => this.props.updateBtnClick(false));
  }

}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Header);
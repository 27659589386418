import React, { FunctionComponent, ReactNode } from 'react';

// styles
import styles from 'shared/styles/Page.module.css';

interface InProps {
    children: ReactNode;
}

/**
 * Wraps pages components in react router 
 * Adds a basic styles to pages for performing animation
 * It was implemented to fix broken animations after adding code splitting
 */
const PageWrap: FunctionComponent<InProps> = ({ children }) => (
    <div className={styles.Page}>{children}</div>
);

export default PageWrap;
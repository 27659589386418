export const boostersConfig = {
  // worldId = 1, packId = 1, levelId = 1
  '100-100-1': {
    hideBoosterHint: true,
    hideBoosterMoveBack: true,
  },
  // worldId = 1, packId = 1, levelId = 2
  '100-100-2': {
    hideBoosterMoveBack: true,
  },
  // worldId = 1, packId = 1, levelId = 3
  /*
  '100-100-3': {
    hideBoosterHint: true,
    hideBoosterMoveBack: true,
  },
  // worldId = 1, packId = 1, levelId = 4
  '10-10-4': {
    hideBoosterMoveBack: true,
  },
  // worldId = 1, packId = 1, levelId = 5
  '10-10-5': {
    hideBoosterMoveBack: false,
  },
  // worldId = 1, packId = 1, levelId = 6
  '10-10-6': {
    hideBoosterHint: true,
    hideBoosterMoveBack: true,
  },
  // worldId = 1, packId = 1, levelId = 7
  '10-10-7': {
    hideBoosterHint: true,
    hideBoosterMoveBack: true,
  },
  // worldId = 1, packId = 1, levelId = 8
  '10-10-8': {
    hideBoosterHint: true,
    hideBoosterMoveBack: true,
  },
  // worldId = 1, packId = 1, levelId = 9
  '10-10-9': {
    hideBoosterHint: true,
    hideBoosterMoveBack: true,
  },
  // worldId = 1, packId = 1, levelId = 10
  '10-10-10': {
    hideBoosterMoveBack: true,
  },
  // worldId = 1, packId = 1, levelId = 11
  '10-10-11': {
    hideBoosterMoveBack: true,
  },
  // worldId = 1, packId = 1, levelId = 12
  '10-10-12': {
    hideBoosterMoveBack: true,
  },
  // worldId = 1, packId = 1, levelId = 13
  '10-10-13': {
    hideBoosterMoveBack: true,
  },
  // worldId = 1, packId = 1, levelId = 14
  '10-10-14': {
    hideBoosterMoveBack: true,
  },
  // worldId = 1, packId = 1, levelId = 15
  '10-10-15': {
    hideBoosterMoveBack: true,
  },
  // worldId = 1, packId = 1, levelId = 16
  '10-10-16': {
    hideBoosterMoveBack: true,
  },
  // worldId = 1, packId = 1, levelId = 17
  '10-10-17': {
    hideBoosterMoveBack: true,
  },
  // worldId = 1, packId = 1, levelId = 18
  '10-10-18': {
    hideBoosterMoveBack: true,
  },
  // worldId = 1, packId = 1, levelId = 19
  '10-10-19': {
    hideBoosterMoveBack: true,
  },
  */
};

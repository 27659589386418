import {
  SUCCESS
} from 'shared/constants';
import loadSdk from 'shared/sdk';
import axiosInstance from 'shared/api/axiosInstance';

export interface IPrices {
  name: 'free_lives';
  amount: number;
}

export const putPrices = (price: number, name: 'free_lives' = 'free_lives'): Promise<IPrices> =>
  axiosInstance.put<IPrices>('/prices', { name, amount: price }) as any

export const getPrices = (name: 'free_lives' = 'free_lives'): Promise<IPrices> =>
  axiosInstance.get(`/prices?name=${name}`).catch(() => {
    const amount = process.env.REACT_APP_MODE === 'prod' ? 99 : 1;
    const inline: IPrices = { amount, name };
    return Promise.resolve(inline);
  }) as Promise<IPrices>;

export const postLifePurchase = (price: number) => loadSdk().then(p => {
    return p.default.showPayment(price);
}, () => Promise.resolve(SUCCESS));

export const deleteLifePurchase = () => Promise.resolve(SUCCESS);

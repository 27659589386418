import {
  HEADER_RESET, HEADER_TOGGLE_SETTINGS, HEADER_TOGGLE_BACK_BTN,
  HEADER_TOGGLE_UPDATE_BTN, HEADER_TOGGLE_USER_LIFES, HEADER_TOGGLE_TITLE,
  HEADER_BACK_BTN_CLICK, HEADER_UPDATE_BTN_CLICK,
} from '../../redux/actionTypes';


export const reset = () => ({
  type: HEADER_RESET,
});

export const toggleSettings = (toggle: boolean) => ({
  type: HEADER_TOGGLE_SETTINGS,
  toggle,
});

export const toggleBackBtn = (toggle: boolean) => ({
  type: HEADER_TOGGLE_BACK_BTN,
  toggle,
});

export const toggleUpdateBtn = (toggle: boolean) => ({
  type: HEADER_TOGGLE_UPDATE_BTN,
  toggle,
});

export const toggleUserLives = (toggle: boolean) => ({
  type: HEADER_TOGGLE_USER_LIFES,
  toggle,
});

export const toggleTitle = (toggle: boolean, title: string) => ({
  type: HEADER_TOGGLE_TITLE,
  toggle,
  title,
});

export const backBtnClick = (backBtnClick: boolean) => ({
  type: HEADER_BACK_BTN_CLICK,
  backBtnClick,
});

export const updateBtnClick = (updateBtnClick: boolean) => ({
  type: HEADER_UPDATE_BTN_CLICK,
  updateBtnClick,
});

import {
  HINT_AI_BEST_VERTEX, HINT_NOT_BEST_VERTEX,
  HINT_GENERAL, HINT_AI_GENERAL,
  HINT_TEXT_BOTTOM, HINT_TEXT_TOP,
  BTN_SKIP_TOP,
  HINT_BOOSTER_HINT, HINT_BOOSTER_MOVE_BACK, HINT_TEXT_STEPS_REGEXP,
} from 'shared/constants';


export const tutorialConfig = {
  // worldId = 1, packId = 1, levelId = 3
  '100-100-1': {
    // tutorial hints on this level
    // they goes one by one
    tutorialHints: [{
      type: HINT_GENERAL,
      delay: 2000, //delay before this hint appears
      text: {
        ru: 'Цель: все числа в&nbsp;кругах должны быть больше либо равны нулю',
        en: 'Goal: all numbers in&nbsp;circles should be&nbsp;more or&nbsp;equal zero',
      },
      textPosition: HINT_TEXT_BOTTOM,
      button: { // if hint object has button click on it move user to the next hint, if not - click on circle area
        ru: 'Понятно!',
        en: 'Got it!',
      },
      // deltaY: -63, // looks like this is not needed in the web version
      deltaCircleR: -10,
    }, {
      type: HINT_GENERAL,
      text: {
        ru: 'Ты&nbsp;можешь изменить баланс если нажмёшь на&nbsp;этот круг',
        en: 'You can change the balance if&nbsp;you click on&nbsp;this circle',
      },
      textPosition: HINT_TEXT_BOTTOM,
      button: { // if hint object has button click on it move user to the next hint, if not - click on circle area
        ru: 'Понятно!',
        en: 'Got it!',
      },
      // deltaY: -63, // looks like this is not needed in the web version
      deltaCircleR: -10,
    }],
    isInfiniteSteps: true,
    isMainTutorialLevel: true,
  },
  // worldId = 1, packId = 1, levelId = 4
  /* '10-10-2': {
    // tutorial hints on this level
    // they goes one by one
    tutorialHints: [{
      type: HINT_GENERAL,
      delay: 2000, //delay before this hint appears
      text: {
        ru: 'Задача усложняется? Но ты уже знаешь что делать!',
        en: 'The task gets harder, but you know what to do!',
      },
      textPosition: HINT_TEXT_BOTTOM,
      button: { // if hint object has button click on it move user to the next hint, if not - click on circle area
        ru: 'Понятно!',
        en: 'Got it!',
      },
      deltaCircleR: -40,
    }],
    waitHint: {
      time: 5000,
      text: {
        ru: 'Ты&nbsp;можешь изменить баланс если нажмёшь на&nbsp;любой круг',
        en: 'You can change the balance if&nbsp;you click on&nbsp;any circle',
      },
    },
    isInfiniteSteps: true,
    isMainTutorialLevel: true,
  },
  // worldId = 1, packId = 1, levelId = 5
  '10-10-3': {
    // there're no tutorial hints on this level
    waitHint: {
      time: 10000,
      text: {
        ru: 'Ты&nbsp;можешь изменить баланс если нажмёшь на&nbsp;любой круг',
        en: 'You can change the balance if&nbsp;you click on&nbsp;any circle',
      },
    },
    isInfiniteSteps: true,
    isMainTutorialLevel: true,
  }, */
  // worldId = 1, packId = 1, levelId = 10
  '100-100-2': {
    tutorialHints: [{
      type: HINT_BOOSTER_HINT,
      delay: 2000, //delay before this hint appears
      text: {
        ru: 'Давай попросим Искусственный Интеллект решить эту задачу? Нажми на&nbsp;лампочку!',
        en: 'Let&rsquo;s ask the Artificial Intelligence to&nbsp;solve this task! Click on&nbsp;this bulb!',
      },
      textPosition: HINT_TEXT_TOP,
      btnSkipPosition: BTN_SKIP_TOP,
      isInfiniteBoosterHint: true,
    }, {
      type: HINT_AI_BEST_VERTEX,
      text: {
        ru: `ИИ: Нажмите сюда, если хотите решить задачу за ${HINT_TEXT_STEPS_REGEXP}`,
        en: `AI: Click here if you want to solve this task in ${HINT_TEXT_STEPS_REGEXP}`,
      },
      textPosition: HINT_TEXT_BOTTOM,
      btnSkipPosition: BTN_SKIP_TOP,
      isInfiniteBoosterHint: true,
    }, {
      type: HINT_AI_BEST_VERTEX,
      text: {
        ru: `ИИ: Нажмите сюда, если хотите решить задачу за ${HINT_TEXT_STEPS_REGEXP}`,
        en: `AI: Click here if you want to solve this task in ${HINT_TEXT_STEPS_REGEXP}`,
      },
      textPosition: HINT_TEXT_BOTTOM,
      btnSkipPosition: BTN_SKIP_TOP,
      isInfiniteBoosterHint: true,
    }, {
      type: HINT_AI_BEST_VERTEX,
      text: {
        ru: `ИИ: Нажмите сюда, если хотите решить задачу за ${HINT_TEXT_STEPS_REGEXP}`,
        en: `AI: Click here if you want to solve this task in ${HINT_TEXT_STEPS_REGEXP}`,
      },
      textPosition: HINT_TEXT_BOTTOM,
      btnSkipPosition: BTN_SKIP_TOP,
      isInfiniteBoosterHint: true,
    }, {
      type: HINT_AI_GENERAL,
      text: {
        ru: `ИИ: До&nbsp;решения задачи осталось ${HINT_TEXT_STEPS_REGEXP}. Теперь вы&nbsp;сами решаете когда вам нужна подсказка.`,
        en: `AI: The task&rsquo;ll be&nbsp;solved in ${HINT_TEXT_STEPS_REGEXP}. Now you can use hint whenewer you want.`,
      },
      deltaCircleR: -20,
      deltaY: 25,
      textPosition: HINT_TEXT_BOTTOM,
      btnSkipPosition: BTN_SKIP_TOP,
      button: { // if hint object has button click on it move user to the next hint, if not - click on circle area
        ru: 'Понятно!',
        en: 'Got it!',
      },
      isInfiniteBoosterHint: true,
    }],
    isInfiniteSteps: true,
    isMainTutorialLevel: true,
  },
  // worldId = 1, packId = 1, levelId = 2 0
  '100-100-3': {
    tutorialHints: [{
      type: HINT_GENERAL,
      delay: 2000, //delay before this hint appears
      text: {
        ru: 'Давай кое-что попробуем?',
        en: 'Let&rsquo;s try something!',
      },
      textPosition: HINT_TEXT_BOTTOM,
      btnSkipPosition: BTN_SKIP_TOP,
      button: { // if hint object has button click on it move user to the next hint, if not - click on circle area
        ru: 'Давай!',
        en: 'Okay!',
      },
      // deltaCircleR: -40,
      isInfiniteBoosterMoveBack: true,
    }, {
      type: HINT_NOT_BEST_VERTEX,
      text: {
        ru: 'Нажми сюда!',
        en: 'Click here!',
      },
      textPosition: HINT_TEXT_BOTTOM,
      btnSkipPosition: BTN_SKIP_TOP,
      isInfiniteBoosterMoveBack: true,
    }, {
      type: HINT_GENERAL,
      text: {
        ru: 'А&nbsp;что, если тебе понадобится отменить ход? Сказать, как можно это сделать?',
        en: 'What if&nbsp;you&nbsp;want to&nbsp;cancel move? Do&nbsp;you want to&nbsp;know how to&nbsp;do&nbsp;it?',
      },
      textPosition: HINT_TEXT_BOTTOM,
      btnSkipPosition: BTN_SKIP_TOP,
      button: { // if hint object has button click on it move user to the next hint, if not - click on circle area
        ru: 'Давай!',
        en: 'Yep!',
      },
      isInfiniteBoosterMoveBack: true,
    }, {
      type: HINT_BOOSTER_MOVE_BACK,
      text: {
        ru: 'Ты&nbsp;можешь отменить ход, если захочешь! Нажми на&nbsp;эту стрелку!',
        en: 'You can cancel last move if&nbsp;you want! Click on&nbsp;this arrow!',
      },
      textPosition: HINT_TEXT_TOP,
      btnSkipPosition: BTN_SKIP_TOP,
      isInfiniteBoosterMoveBack: true,
      }, {
      type: HINT_GENERAL,
      text: {
        ru: 'Мы&nbsp;отменили последний ход! Если тебе нужно отменить неправильный ход, то&nbsp;ты&nbsp;уже знаешь что делать!',
        en: 'This move was canceled! If&nbsp;you want to&nbsp;cancel incorrect move you know what to&nbsp;do, right?',
      },
      textPosition: HINT_TEXT_BOTTOM,
      btnSkipPosition: BTN_SKIP_TOP,
      button: { // if hint object has button click on it move user to the next hint, if not - click on circle area
        ru: 'Да!',
        en: 'Yep!',
      },
      isInfiniteBoosterMoveBack: true,
    }],
    isInfiniteSteps: true,
    isMainTutorialLevel: true,
  },
};

// after you add new variable, you need to restart your local dev, in order to allow postcss get that vars
module.exports = {
	DESKTOP_WIDTH: 1024,
	MOBILE_WIDTH: 767,
	SMALL_MOBILE_WIDTH: 677,
	TINY_MOBILE_WIDTH: 370,
	LEVEL_ITEM_HEIGHT: 85,
	LEVEL_ITEM_BOTTOM_OFFSET: 33,
	MOBILE_LEVEL_ITEM_HEIGHT: 43,
	MOBILE_LEVEL_ITEM_BOTTOM_OFFSET: 17,
	CUSTOM_LIST_ITEM_HEIGHT: 49,
	CUSTOM_LIST_ITEM_BOTTOM_OFFSET: 16,
};
import React, { Component, } from 'react';

import { getAnalyticsID, isAnalyticsEnabled, loadYandexMetrica, loadReactGA } from 'shared/helpers/analytics';

if (isAnalyticsEnabled) {
  loadReactGA().then(({ initialize }) => {
    initialize(getAnalyticsID('GA'));
  });
}

const withTracker = (WrappedComponent, options = {}) => {
  const trackPage = page => {
    if (isAnalyticsEnabled) {
      loadReactGA().then(({ set, pageview }) => {
        set({
          page,
          ...options,
        });
        pageview(page);
      });
      loadYandexMetrica().then(({ default: ym }) => {
        ym('hit', page);
      })
    }
  };

  // eslint-disable-next-line
  const HOC = class extends Component {
    componentDidMount() {
      // eslint-disable-next-line
      const page = this.props.location.pathname + this.props.location.search;
      trackPage(page);
    }

    componentDidUpdate(prevProps) {
      const currentPage =
        prevProps.location.pathname + prevProps.location.search;
      const nextPage =
        this.props.location.pathname + this.props.location.search;

      if (currentPage !== nextPage) {
        trackPage(nextPage);
      }
    }

    render() {
      return <WrappedComponent {...this.props} />;
    }
  };

  return HOC;
};

export default withTracker;
/* eslint react/jsx-pascal-case: [0, { ignore: 1 }] */

import React from 'react';
import classNames from 'classnames';

// styles
import styles from './OKShareBtn.module.css';


class OKShareBtn extends React.PureComponent {

  /******************************************
  * COMPONENT HOOKS
  ******************************************/

  render() {
    const btnClasses = classNames({
      [styles.OKShareBtn]: true,
      [this.props.className]: !!this.props.className,
    });

    return (
      <button className={btnClasses}
        onClick={this.props.onClick}>
        <i className={styles.OK}></i>
        <span className={styles.OKShareBtnTitle}>{this.props.title}</span>
      </button>
    );
  }
}

export default OKShareBtn;

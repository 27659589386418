/* eslint react/jsx-pascal-case: [0, { ignore: 1 }] */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import classNames from 'classnames';
import get from 'lodash/get';
import { Scrollbars } from 'react-custom-scrollbars';
import { withNamespaces } from 'react-i18next';

// components
import Stars from 'shared/components/Stars';
import PageTitle from 'shared/components/PageTitle';
import StarsCounter from 'shared/components/StarsCounter';
import PageHeaderManager from 'shared/containers/PageHeaderManager';
import ScrollbarHeightAdjuster from 'shared/components/ScrollbarHeightAdjuster';
import withTracker from 'shared/components/withTracker';

// constants
import cssEnvVars from 'shared/cssEnvironmentVariables';

// helpers
import { hasPackAccess, hasLevelAccess, getAchievedStarsAmount, } from 'shared/helpers/progress';
import { windowInnerWidth } from 'shared/helpers/resize';

import AppSoundManager from 'shared/modules/AppSoundManager';

// styles
import styles from './LevelsPage.module.css';

// redux
import { getPackWithLevelsSelector } from 'redux/selectors/worlds';
import {
  getAchievedStarsInPack, getAllAchievedStars, getLevelProgress
} from 'redux/selectors/progress';


const mapStateToProps = (state, props) => ({
  progress: state.progress.progress,
  pack: getPackWithLevelsSelector(state, props),
  achievedStars: state.progress.achievedStars,
});

class LevelsPage extends Component {

  constructor(props) {
    super(props);
    this.isActive = true;
  }

  get worldId() {
    return +get(this.props.match.params, 'worldId');
  }

  get packId() {
    return +get(this.props.match.params, 'packId');
  }

  /******************************************
  * COMPONENT HOOKS
  ******************************************/

  render() {
    const { t, pack, achievedStars, progress } = this.props;
    const packName = t(`worlds.${this.worldId}.packs.${this.packId}`);

    return (
      <div className={styles.LevelsPage}>
        <PageHeaderManager
          showSettings={true}
          showBackBtn={true}
          showUserLives={true}
          onBackClick={this.handlerBackClick}
        />
        {!!pack.Levels.length && 
          <nav className={classNames([
            styles.LevelsContent,
            styles.LevelsShowed
          ])}>
            <PageTitle className={styles.LevelsPageTitle}>{packName}</PageTitle>
            <StarsCounter
              className={styles.StarsCounter}
              currentStarsNumber={getAchievedStarsInPack(this.worldId, this.packId, achievedStars)}
              maxStarsNumber={pack.Levels.length * pack.MaxStars} />
            <ScrollbarHeightAdjuster
              className={classNames(styles.LevelsListScrollWrap, (process.env.REACT_APP_SOCIAL_MEDIA === 'ok' && styles.OK))}
              listItem={{
                height: windowInnerWidth < cssEnvVars.MOBILE_WIDTH ? cssEnvVars.MOBILE_LEVEL_ITEM_HEIGHT : cssEnvVars.LEVEL_ITEM_HEIGHT,
                marginBottom: windowInnerWidth < cssEnvVars.MOBILE_WIDTH ? cssEnvVars.MOBILE_LEVEL_ITEM_BOTTOM_OFFSET : cssEnvVars.LEVEL_ITEM_BOTTOM_OFFSET
              }}>
              <Scrollbars autoHide>
                <ul className={styles.LevelList}>
                  {pack.Levels && pack.Levels.map((level, index) => {
                    const levelProgress = getLevelProgress(this.worldId, this.packId, level.Id, progress);
                    const stepsSpent = get(levelProgress, 'stepsSpent');
                    const cheatStars = get(levelProgress, 'cheatStars');
                    const isLevelLocked = !hasLevelAccess(this.worldId, this.packId, level.Id, progress);
                    const levelItemContentClasses = classNames({
                      [styles.LevelItem]: true,
                      [styles.Locked]: isLevelLocked
                    });
                    const levelAchievedStars = getAchievedStarsAmount(
                      level.Graph.MaxMovesCount,
                      level.Graph.BestMovesCount,
                      stepsSpent,
                      pack.MaxStars,
                      cheatStars,
                    );
                    return (
                      <li key={index} className={levelItemContentClasses}>
                        <div className={styles.LevelItemWrap}
                          onClick={this.handlerLevelClick.bind(this, level.Id, isLevelLocked)}>
                          <p className={styles.LevelItemNumber}>
                            {index + 1}
                          </p>
                          {(cheatStars || (!!stepsSpent && !isLevelLocked)) &&
                          <Stars
                            className={styles.LevelItemStars}
                            starsNumber={levelAchievedStars}
                            maxStars={pack.MaxStars}
                            resizeOnMobile={true} />
                          }
                          {isLevelLocked &&
                            <i className={styles.LevelLocked}></i>
                          }
                        </div>
                      </li>
                    );
                  })}
                </ul>
              </Scrollbars>
            </ScrollbarHeightAdjuster>
          </nav>
        }
      </div>
    );
  }

  componentDidMount() {
    const { pack, achievedStars } = this.props;
    if (!this.isActive) return;
    if (!this.props.pack) return this.goPageBack();
    // user must achieve stars more or equal than pack requires
    if (!hasPackAccess(pack.StarsRequired, getAllAchievedStars(achievedStars))) {
      this.goPageBack();
      return;
    }
  }

  componentWillUnmount() {
    this.isActive = false;
  }

  /******************************************
  * COMPONENT HANDLERS
  ******************************************/
 
  handlerLevelClick(levelId, isLevelLocked) {
    if (isLevelLocked) return;
    AppSoundManager.playButtonSound();
    if (this.worldId) {
      this.props.history.push(`/game/level/${this.worldId}/${this.packId}/${levelId}`);
    }
  }

  handlerBackClick = () => {
    AppSoundManager.playButtonSound();
    if (this.worldId) {
      this.goPageBack();
    } else {
      this.props.history.push('/', {routeBack: true});
    }
  }

  /******************************************
  * COMPONENT METHODS
  ******************************************/

  goPageBack() {
    this.props.history.push(`/world/${this.worldId}`, {routeBack: true});
  }
}

export default withTracker(withNamespaces()(withRouter(connect(mapStateToProps, null)(LevelsPage))));

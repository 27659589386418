import advStub from './advStub';
import delay from 'lodash/delay';
import { SUCCESS, ERROR } from 'shared/constants';
import { Observable } from 'rxjs';

import loadSDK from 'shared/sdk';
import store from 'redux/store';
import { showAdNotFoundModal } from 'redux/actions/modals';
import { MODAL_APPEAR_TIME } from 'shared/constants';
import { PurchaseItemType } from 'shared/helpers/modals';

export default class Advertizer {
  static isInited = false;
  static isAdvShowing = false; // indicates showing adverticement at the moment
  static advKey = false; // key which advertizer generates to return to caller
  static isSDKOK = false;
  static sdk: any;
  /******************************************
   * CLASS METHODS
   ******************************************/
  /*
   * Inits SDK
   */
  static init() {
    if (!process.env.REACT_APP_SHOW_ADV) return;

    switch (process.env.REACT_APP_SOCIAL_MEDIA) {
      case 'ok':
        this.isSDKOK = true;
        break;
      default:
        break;
    }

    if (this.isSDKOK) {
      loadSDK().then(({default: sdk}: any) => {
        this.sdk = sdk;
        sdk.init().then(({ status }: any) => {
          this.isInited = status === SUCCESS;
          sdk.prepareAdverticementCycle();
        });
      }, _ => {}) ;
    }
  }

  static show(purchaseItem: PurchaseItemType) {
    return new Observable(observer => {
      if (!process.env.REACT_APP_SHOW_ADV || this.isAdvShowing) {
        observer.next({ status: ERROR });
        observer.complete();
        return;
      }

      this.isAdvShowing = true;

      if (this.isInited && this.sdk && process.env.REACT_APP_SOCIAL_MEDIA) {
        this.sdk.showAdv().subscribe((response: any) => {
          this.isAdvShowing = false;
          observer.next(response);
          observer.complete();
        });
      } else {
        // If advertisement wasn't found - show fake advertisement
        // this.showFakeAdv().subscribe(response => {
        //   this.isAdvShowing = false;
        //   observer.next(response);
        //   observer.complete();
        // });
        
        // Show ADNotFoundModal
        store.dispatch(showAdNotFoundModal(purchaseItem));
        delay(() => {
          this.isAdvShowing = false;
          observer.next({ status: SUCCESS });
          observer.complete();
        }, MODAL_APPEAR_TIME);
      }
    });
  }

  static showFakeAdv() {
    return new Observable(observer => {
      const bodyEl = document.body;
      bodyEl.appendChild(advStub);
      delay(() => {
        bodyEl.removeChild(advStub);
        observer.next({ status: SUCCESS });
        observer.complete();
      }, 1500);
    });
  }
}

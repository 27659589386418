import React from 'react';
import classNames from 'classnames';
import { connect } from 'react-redux'

// modules
import { sendAnalyticsEvent, AdWatchRewards, AdWatchResults, withCount } from 'shared/modules/AnalyticsEvents';

// styles
import styles from './UserLives.module.css';

// components
import LivesNumber from 'shared/components/LivesNumber';
import NewLifeTime from 'shared/components/NewLifeTime';
import AddBtn from 'shared/components/AddBtn';
import PayBtn from 'shared/components/PayBtn';

// helpers
import { warningTextTypes } from 'shared/helpers/adBlockDetector';

// redux
import { showAdBlockModal, showLifePurchaseModal, showWatchAdModal } from 'redux/actions/modals';

const mapStateToProps = state => ({
	livesNumber: state.lives.livesNumber,
	maxLivesNumber: state.lives.maxLivesNumber,
	infiniteLife: state.lives.infiniteLife,
	lifeRestoreMinutes: state.lives.lifeRestoreMinutes,
	newLifeTimer: state.lives.newLifeTimer,
	adBlockDetected: state.adBlock.adBlockDetected,
});

const mapDispatchToProps = dispatch => ({
	showAdBlockModal: warningTextType => dispatch(showAdBlockModal(warningTextType)),
	showLifePurchaseModal: () => dispatch(showLifePurchaseModal({ entryPoint: 'UserLives' })),
	showWatchAdModal: () => dispatch(showWatchAdModal()),
});

class UserLives extends React.PureComponent {

	/******************************************
	* COMPONENT HOOKS
	******************************************/

	render() {
		const {
			livesNumber, newLifeTimer, maxLivesNumber,
			lifeRestoreMinutes,
		} = this.props;

		const wrapperClasses = classNames({
		  [styles.UserLives]: true,
		  [this.props.className]: !!this.props.className,
		});

		return (
			<div className={wrapperClasses}>
				{!this.props.infiniteLife && <PayBtn onClick={this.handlerPayBtnClick}></PayBtn>}
				<LivesNumber
					className={styles.UserLivesNumberWrap}
					livesNumber={livesNumber} />
				<NewLifeTime
					className={styles.UserLifeTimeWrap}
					maxLives={maxLivesNumber}
					livesNumber={livesNumber}
					newLifeTimer={newLifeTimer}
					lifeRestoreMinutes={lifeRestoreMinutes} />
					<AddBtn
						className={styles.UserLivesAddButton}
						disabled={livesNumber === maxLivesNumber}
						onClick={this.handlerShowADModalClick} />
			</div>
		);
	}

	/******************************************
	* COMPONENT HANDLERS
	******************************************/

	handlerShowADModalClick = () => {
		if (this.props.livesNumber === this.props.maxLivesNumber) return;
		if (this.props.adBlockDetected) {
			this.props.showAdBlockModal(warningTextTypes.lives);
			sendAnalyticsEvent('adWatch', withCount({
				[AdWatchRewards.live]: withCount({
					result: AdWatchResults.blocked
				})
			}));
		} else {
			this.props.showWatchAdModal();
		}
	}

	handlerPayBtnClick = () => {
		this.props.showLifePurchaseModal();
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(UserLives);

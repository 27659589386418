import { SET_SECOND_SESSION } from 'redux/actionTypes';

const initialState = {
  isSecondSession: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_SECOND_SESSION: {
      return {
        ...state,
        isSecondSession: action.isSecondSession,
      }
    }
    default:
      return state;
  }
}

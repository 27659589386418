/* eslint react/jsx-pascal-case: [0, { ignore: 1 }] */

import React from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { fromEvent, Unsubscribable, Observable, Observer } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import indexOf from 'lodash/indexOf';
import keys from 'lodash/keys';
import delay from 'lodash/delay';
import get from 'lodash/get';
import { withNamespaces, WithNamespaces } from 'react-i18next';

// components
import Hint from 'shared/containers/Hint';

// modules
import TutorialManager from 'shared/modules/TutorialManager';
import { sendAnalyticsEvent, withCount } from 'shared/modules/AnalyticsEvents';

// redux
import * as hintActions from 'redux/actions/hint';
import * as settingsActions from 'redux/actions/settings';

import { tutorialConfig } from 'shared/config/tutorialConfig';

import {
  HINT_BEST_VERTEX, HINT_AI_BEST_VERTEX, HINT_NOT_BEST_VERTEX,
  HINT_GENERAL, HINT_AI_GENERAL,
  HINT_BOOSTER_HINT, HINT_BOOSTER_MOVE_BACK, HINT_TEXT_STEPS_REGEXP,
  BTN_SKIP_BOTTOM
} from 'shared/constants';
import { boostersConfig } from 'shared/config/boostersConfig';

// styles
import styles from './Tutorial.module.css';
import { Dispatch } from 'redux';

interface InProps {
  className?: string;
}

interface ITutorialState {
  showHint: boolean,
  bgWidth: number | null,
  bgHeight: number | null,
  currTutorialHintIndex: number;
}

interface IStateToProps {
  language: string;
  userId: number;
  tutorialProgress: string[];

  // game steps spent for identifying user activity
  stepsSpent: number;
  // coords of best vertex
  bestVertexX: number;
  bestVertexY: number;
  bestVertexR: number;
  // coords of not best vertex
  notBestVertexY: number;
  notBestVertexX: number;
  notBestVertexR: number;
  notBestVertexIndex: number;
  // coords of whole graph
  graphX: number;
  graphY: number;
  graphR: number;
  isGamePassed: boolean;

  // // coords of booster hint
  boosterHintX: number;
  boosterHintY: number;
  boosterHintR: number;

  // // coords of booster move back
  boosterMoveBackY: number;
  boosterMoveBackX: number;
  boosterMoveBackR: number;

  // tutorial
  tutorialLevelId: string;
  infiniteBoosterHint: boolean;
  infiniteBoosterMoveBack: boolean;
  stepsLeft: number;
}

interface IDispatchToProps {
  setHintBgSize: (width: number, height: number) => void;
  setHintCircle: (x: number, y: number, r: number) => void;
  addSettingsTutorial: (level: string) => void;
  updateSettingsTutorial: (levels: string[]) => void;
}

type TutorialProps = InProps & IStateToProps & IDispatchToProps & WithNamespaces;

const mapStateToProps = (state: any): IStateToProps => ({
  language: state.settings.language,
  userId: state.user.userId,
  tutorialProgress: state.settings.tutorialProgress,

  // game steps spent for identifying user activity
  stepsSpent: state.game.stepsSpent,
  // coords of best vertex
  bestVertexX: state.game.bestVertexX,
  bestVertexY: state.game.bestVertexY,
  bestVertexR: state.game.bestVertexR,
  // coords of not best vertex
  notBestVertexY: state.game.notBestVertexY,
  notBestVertexX: state.game.notBestVertexX,
  notBestVertexR: state.game.notBestVertexR,
  notBestVertexIndex: state.game.notBestVertexIndex,
  // coords of whole graph
  graphX: state.game.graphX,
  graphY: state.game.graphY,
  graphR: state.game.graphR,
  // is game passed
  isGamePassed: state.game.passed,
  // // coords of booster hint
  boosterHintX: state.boosters.boosterHintX,
  boosterHintY: state.boosters.boosterHintY,
  boosterHintR: state.boosters.boosterHintR,
  // // coords of booster move back
  boosterMoveBackY: state.boosters.boosterMoveBackY,
  boosterMoveBackX: state.boosters.boosterMoveBackX,
  boosterMoveBackR: state.boosters.boosterMoveBackR,

  // tutorial
  tutorialLevelId: state.tutorial.activeLevelId,

  infiniteBoosterHint: state.boosters.infiniteBoosterHint,
  infiniteBoosterMoveBack: state.boosters.infiniteBoosterMoveBack,

  // game steps left (AI data)
  stepsLeft: state.game.stepsLeft,
});

const mapDispatchToProps = (dispatch: Dispatch<any>) => ({
  setHintBgSize: (width: number, height: number) => dispatch(hintActions.setBgSize(width, height)),
  setHintCircle: (x: number, y: number, r: number) => dispatch(hintActions.setCircle(x, y, r)),
  addSettingsTutorial: (level: string) => dispatch(settingsActions.addSettingsTutorial(level)),
  updateSettingsTutorial: (levels: string[]) => dispatch(settingsActions.updateSettingsTutorial(levels))
});


class Tutorial extends React.Component<TutorialProps, ITutorialState> {

  state: ITutorialState = {
    showHint: false,
    bgWidth: null,
    bgHeight: null,
    currTutorialHintIndex: -1, // current index of tutorial hint from config
  }

  // delta to add to the circle radius if there's no deltaCircleR in hint config
  private defaultDeltaCircleR: number = 25;
  private tutorialLevels: string[] = keys(tutorialConfig);
  private isTransitionBetweenHints: boolean = false;
  private resizeEventSubscr?: Unsubscribable;
  private hint?: Hint;
  // waiting mode
  private isWaitingMode: boolean = false;
  private waitingHintTimeout?: any;

  get hasTutorial() {
    // check if current tutorialLevelId has tutorial in config
    return indexOf(this.tutorialLevels, this.props.tutorialLevelId) >= 0;
  }

  get tutorialConfigOfLevel() {
    return get(tutorialConfig, this.props.tutorialLevelId);
  }

  get boostersConfigOfLevel() {
    return get(boostersConfig, this.props.tutorialLevelId);
  }

  get tutorialHintsOfLevel() {
    if (this.hasTutorial) {
      return get(this.tutorialConfigOfLevel, 'tutorialHints');
    }
    return null;
  }

  get waitingHintOfLevel() {
    if (this.hasTutorial) {
      return get(this.tutorialConfigOfLevel, 'waitHint');
    }
    return null;
  }

  get currTutorialHint() {
    if (this.tutorialHintsOfLevel) {
      return this.tutorialHintsOfLevel[this.state.currTutorialHintIndex];
    }
    return null;
  }

  get deltaYOfCurrTutorialHint() {
    return get(this.currTutorialHint, 'deltaY', 0);
  }

  get isLevelPassed() {
    const { tutorialProgress, tutorialLevelId } = this.props;
    if (!tutorialProgress) {
      return false;
    }
    return tutorialProgress.indexOf(tutorialLevelId) >= 0;
  }

  get hintCircle() {
    if (!this.props.tutorialLevelId) return null;

    const {
      bestVertexX, bestVertexY, bestVertexR, graphX, graphY, graphR,
      boosterHintX, boosterHintY, boosterHintR,
      boosterMoveBackX, boosterMoveBackY, boosterMoveBackR,
      notBestVertexX, notBestVertexY, notBestVertexR,
    } = this.props;

    if (this.isWaitingMode) {
      // if it's waiting hint - it's always best vertex hint
      return this.createHintCircleData(bestVertexX, bestVertexY, bestVertexR + this.defaultDeltaCircleR);
    } else if (this.currTutorialHint) {
      const deltaCircleR = get(this.currTutorialHint, 'deltaCircleR', this.defaultDeltaCircleR);

      switch (this.currTutorialHint.type) {
        case HINT_BEST_VERTEX:
        case HINT_AI_BEST_VERTEX:
          return bestVertexR ? this.createHintCircleData(bestVertexX, bestVertexY, bestVertexR + deltaCircleR) : null;
        case HINT_NOT_BEST_VERTEX:
          return notBestVertexY ? this.createHintCircleData(notBestVertexX, notBestVertexY, notBestVertexR + deltaCircleR) : null;
        case HINT_GENERAL:
        case HINT_AI_GENERAL:
          return graphR ? this.createHintCircleData(graphX, graphY, graphR + deltaCircleR) : null;
        case HINT_BOOSTER_HINT:
          return boosterHintR ? this.createHintCircleData(boosterHintX, boosterHintY, boosterHintR + deltaCircleR) : null;
        case HINT_BOOSTER_MOVE_BACK:
        default:
          return boosterMoveBackR ? this.createHintCircleData(boosterMoveBackX, boosterMoveBackY, boosterMoveBackR + deltaCircleR) : null;
      }
    }

    return null;
  }

  get hintTextData(): { text: string | null , textPosition: string | null } {
    if (!this.props.tutorialLevelId) {
      return this.createHintTextData(null, null);
    }

    if (this.isWaitingMode) {
      // if it's waiting hint - it's always best vertex hint
      return this.createHintTextData(
        this.waitingHintOfLevel.text[this.props.language],
        this.waitingHintOfLevel.textPosition,
        );
    } else if (this.currTutorialHint) {
      return this.createHintTextData(
        this.currTutorialHint.text[this.props.language],
        this.currTutorialHint.textPosition,
      );
    } else {
      return this.createHintTextData(null, null);
    }
  }

  get hintBtnText() {
    if (!this.props.tutorialLevelId || this.isWaitingMode) return null;
    return get(this.currTutorialHint, `button[${this.props.language}]`);
  }

  /******************************************
  * COMPONENT HOOKS
  ******************************************/

  render() {
    if (!this.hintCircle) {
      return '';
    }

    const tutorialClasses = classNames({
      [styles.Tutorial]: true,
      [styles.HintShow]: this.state.showHint,
      [this.props.className!]: !!this.props.className,
    });

    const { circleX, circleY, circleR } = this.hintCircle;

    const { textPosition } = this.hintTextData;
    const text = this.handleHintText(this.hintTextData.text);

    const { bgWidth, bgHeight } = this.state;
    const btnSkipPosition = get(this.currTutorialHint, 'btnSkipPosition', BTN_SKIP_BOTTOM)
    return (
      <div className={tutorialClasses}>
        <Hint className={classNames({
          [styles.Hint]: true,
          [styles.HintShow]: this.state.showHint,
        })}
          ref={node => {
            this.hint = node!;
          }}
          circleX={circleX}
          circleY={circleY + this.deltaYOfCurrTutorialHint}
          circleR={circleR}
          text={text}
          textPosition={textPosition}
          btnSkipPosition={btnSkipPosition}
          bgWidth={bgWidth}
          bgHeight={bgHeight}
          btnText={this.hintBtnText}
          skipBtnText={this.props.t('tutorial.skipTutorial')}
          onCircleClick={this.handleCircleClick}
          onBtnClick={this.handleBtnClick}
          onSkipBtnClick={this.handleSkipBtnClick}>
        </Hint>
      </div>
    );
  }

  componentWillUnmount() {
    if (this.isLevelPassed) return;
    if (this.resizeEventSubscr) this.resizeEventSubscr.unsubscribe();
  }

  componentDidUpdate(prevProps: TutorialProps) {
    // if level Id was changed - start tutorial for this level
    if (prevProps.tutorialLevelId !== this.props.tutorialLevelId && this.hasTutorial) {
      this.start();
    }

    // if we're in the waiting mode and user do some actions - restart waiting hint timeout
    if (prevProps.stepsSpent !== this.props.stepsSpent && this.isWaitingMode) {
      this.callWaitingHintIteration();
    }

    // if game passed
    if (prevProps.isGamePassed !== this.props.isGamePassed && this.props.isGamePassed) {
      this.setTutorialPassed();
    }
  }

  /******************************************
  * COMPONENT HANDLERS
  ******************************************/

  handleBtnClick = () => {
    if (this.isTransitionBetweenHints) return;
    if (this.currTutorialHint.type === HINT_GENERAL ||
      this.currTutorialHint.type === HINT_AI_GENERAL) {
      this.isTransitionBetweenHints = true;
      this.showNextHint();
    }
  }

  handleCircleClick = () => {
    if (!this.props.tutorialLevelId || this.isTransitionBetweenHints) return;
    if (this.isWaitingMode) {
      TutorialManager.callGameBestVertexClick();
      this.setShowHint(false).subscribe(() => {
        this.isTransitionBetweenHints = false;
        this.callWaitingHintIteration();
      });
    } else if (this.currTutorialHint) {
      switch (this.currTutorialHint.type) {
        case HINT_BEST_VERTEX:
        case HINT_AI_BEST_VERTEX:
          TutorialManager.callGameBestVertexClick();
          break;
        case HINT_NOT_BEST_VERTEX:
          TutorialManager.callGameNotBestVertexClick();
          break;
        case HINT_BOOSTER_HINT:
          TutorialManager.callBoosterHintClick();
          break;
        case HINT_BOOSTER_MOVE_BACK:
        default:
          TutorialManager.callBoosterMoveBackClick();
          break;
      }
      if (this.currTutorialHint.type !== HINT_GENERAL &&
        this.currTutorialHint.type !== HINT_AI_GENERAL) {
        this.isTransitionBetweenHints = true;
        this.showNextHint();
      }
    }
  }

  handleSkipBtnClick = () => {
    const { userId, tutorialLevelId } = this.props;
    sendAnalyticsEvent('skipTutorial', withCount<{ [key: string]: any }>({
      [tutorialLevelId]: withCount({ userId }),
    }));
    this.skipAllTutorials();
  }

  /******************************************
  * COMPONENT METHODS
  ******************************************/

  // Start tutorial for level with Id = this.props.tutorialLevelId
  start() {
    if (this.isLevelPassed) return;

    this.callUpdateGameData();
    // reset tutorialHint index
    if (this.tutorialHintsOfLevel) {
      this.setCurrTutorialHintIndex(0)
        .subscribe(() => {
          this.setShowHint(true).subscribe();
        });
    } else if (this.waitingHintOfLevel) {
      this.startWaitingMode();
    }
    this.startListeningResize();
    this.updateHintBgSize();
  }

  startListeningResize() {
    const resizeEvent$ = fromEvent(window, 'resize').pipe(debounceTime(200));

    this.resizeEventSubscr = resizeEvent$.subscribe(e => {
      this.updateHintBgSize();
      this.callUpdateGameData();
      this.updateBoostersCoords();
    });
  }

  setCurrTutorialHintIndex(index: number) {
    return Observable.create((observer: Observer<void>) => {
      this.setState({
        currTutorialHintIndex: index
      }, () => {
        observer.next();
        observer.complete();
      });
    });
  }

  showNextHint() {
    if (this.state.currTutorialHintIndex < this.tutorialHintsOfLevel.length - 1) {
      this.hint && this.hint.animateDisappear()
        .subscribe(() => {
          this.setCurrTutorialHintIndex(this.state.currTutorialHintIndex + 1)
            .subscribe(() => {
              this.updateHint();
              this.callUpdateGameData();
              delay(() => {
                if (this.hint) {
                  this.hint.animateAppear().subscribe();
                }
              }, 100);
            });
        });
    } else {
      this.setShowHint(false)
        .subscribe(() => {
          this.setCurrTutorialHintIndex(-1)
            .subscribe(() => {
              // set waiting mode if we have one
              this.startWaitingMode();
              this.updateBoostersInfinities();
            });
        });
    }
    this.isTransitionBetweenHints = false;
  }

  updateHint() {
    this.updateBoostersCoords();
    this.updateBoostersInfinities();

    if (get(this.currTutorialHint, 'type') === HINT_BEST_VERTEX) {
      TutorialManager.callGameShowBestMoveHint();
    }

    if (get(this.currTutorialHint, 'type') === HINT_AI_BEST_VERTEX) {
      TutorialManager.callGameShowBestMoveHint();
      TutorialManager.callGameUpdateStepsLeft();
    }

    if (get(this.currTutorialHint, 'type') === HINT_NOT_BEST_VERTEX) {
      TutorialManager.callGameShowNotBestMoveHint();
    }

    if (get(this.currTutorialHint, 'type') === HINT_AI_GENERAL) {
      TutorialManager.callGameUpdateStepsLeft();
    }
  }

  // update all hint parameters based on current config item
  updateHintBgSize() {
    this.setState({
      bgWidth: window.innerWidth,
      bgHeight: window.innerHeight
    });
  }

  startWaitingMode() {
    if (!this.props.tutorialLevelId) this.stopWaitingMode();
    if (this.isWaitingMode || this.isLevelPassed) return;
    if (this.waitingHintOfLevel) {
      this.isWaitingMode = true;
      // universal for all waiting hints
      this.callWaitingHintIteration();
    }
  }

  stopWaitingMode() {
    if ((!this.isWaitingMode || this.isLevelPassed) && this.props.tutorialLevelId) return;

    this.isWaitingMode = false;
    clearTimeout(this.waitingHintTimeout);
  }

  callWaitingHintIteration() {
    if (!this.props.tutorialLevelId || this.isLevelPassed) return;

    if (this.waitingHintTimeout) {
      clearTimeout(this.waitingHintTimeout);
    }
    if (!this.waitingHintOfLevel) {
      console.warn('No waiting hint');
      return;
    }
    this.waitingHintTimeout = delay(this.executeWaitingHintIteration, this.waitingHintOfLevel.time);
  }

  executeWaitingHintIteration = () => {
    if (!this.state.showHint) {
      TutorialManager.callGameShowBestMoveHint();
      this.setShowHint(true).subscribe();
    }
    this.callWaitingHintIteration();
  }

  setTutorialPassed() {
    if (this.isLevelPassed) return;
    this.stopWaitingMode();
    this.props.addSettingsTutorial(this.props.tutorialLevelId);
  }

  setShowHint(showHint: boolean) {
    return Observable.create((observer: Observer<void>) => {
      if (showHint) {
        const showHintDelay = get(this.currTutorialHint, 'delay', 0);

        setTimeout(() => {
          this.setState({ showHint });
          this.updateHint();
          this.hint && this.hint.animateAppear().subscribe(() => {
            observer.next();
            observer.complete();
          });
        }, showHintDelay);
      } else {
        this.hint && this.hint.animateDisappear().subscribe(() => {
          observer.next();
          observer.complete();
          this.updateHint();
          this.setState({ showHint });
        });
      }
    });
  }

  createHintCircleData(circleX: number, circleY: number, circleR: number) {
    return { circleX, circleY, circleR };
  }

  createHintTextData(text: string | null, textPosition: string | null) {
    return { text, textPosition };
  }

  callUpdateGameData() {
    TutorialManager.callGameUpdateGraphCoord();
    TutorialManager.callGameUpdateBestVertexCoord();
    TutorialManager.callGameUpdateNotBestVertexData();
  }

  updateBoostersCoords() {
    if (this.currTutorialHint) {
      const boosterR = 15;
      switch (this.currTutorialHint.type) {
        case HINT_BOOSTER_HINT:
          if (!this.boostersConfigOfLevel.hideBoosterHint) {
            // if there's only booster hint - it's in the middle
            if (this.boostersConfigOfLevel.hideBoosterMoveBack) {
              TutorialManager.setBoosterHintCoords(
                window.innerWidth / 2,
                window.innerHeight - 40,
                boosterR
              );
            } else {
              TutorialManager.setBoosterHintCoords(
                window.innerWidth / 2 - 40,
                window.innerHeight - 40,
                boosterR
              );
            }
          }
          return;
        case HINT_BOOSTER_MOVE_BACK:
          TutorialManager.setBoosterMoveBackCoords(
            window.innerWidth / 2 + 35,
            window.innerHeight - 40,
            boosterR
          );
          return;
        default:
          return;
      }
    }
  }

  updateBoostersInfinities() {
    if (process.env.REACT_APP_INFINITE_BOOSTERS === 'true') {
      TutorialManager.setInfiniteBoosterHint(true);
      TutorialManager.setInfiniteBoosterMoveBack(true);
      return
    }

    if (!this.currTutorialHint) {
      TutorialManager.setInfiniteBoosterHint(false);
      TutorialManager.setInfiniteBoosterMoveBack(false);
      return;
    }

    if (this.currTutorialHint.isInfiniteBoosterHint !== this.props.infiniteBoosterHint) {
      TutorialManager.setInfiniteBoosterHint(!!this.currTutorialHint.isInfiniteBoosterHint);
    }

    if (this.currTutorialHint.isInfiniteBoosterMoveBack !== this.props.infiniteBoosterMoveBack) {
      TutorialManager.setInfiniteBoosterMoveBack(!!this.currTutorialHint.isInfiniteBoosterMoveBack);
    }
  }

  handleHintText(text: string | null) {
    if (!text) return text;

    if (text.indexOf(HINT_TEXT_STEPS_REGEXP) >= 0) {
      let movesText = '';
      const { stepsLeft } = this.props;
      if (this.props.language === 'ru') {
        const rest = stepsLeft % 10;
        if (rest === 1) {
          movesText = 'шаг';
        } else if (rest > 1 && rest < 5 ) {
          movesText = 'шага';
        } else {
        movesText = 'шагов';
        }
      } else if (this.props.language === 'en') {
        if (stepsLeft === 1) {
          movesText = 'move';
        } else {
          movesText = 'moves';
        }
      }
      return text.replace(HINT_TEXT_STEPS_REGEXP, `${this.props.stepsLeft} ${movesText}`);
    }
    return text;
  }

  skipAllTutorials() {
    this.stopWaitingMode();
    this.props.updateSettingsTutorial(this.tutorialLevels);
    this.setShowHint(false).subscribe();
  }
}

export default withNamespaces()(
  connect<IStateToProps, any, InProps, {}>(mapStateToProps, mapDispatchToProps)(Tutorial)
);

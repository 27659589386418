import axiosInstance from '../api/axiosInstance';

export interface LevelProgress {
  id: number;
  completed: boolean;
  stepsSpent?: number;
  hash?: string;
  addMovesAttempts: number;
};

export interface PackProgress {
  packId: number;
  worldId?: number;
  levels: Array<LevelProgress | string>;
};

export interface WorldProgress {
  worldId: number;
  packs: Array<PackProgress>;
}

/**
 * Get all worlds progresses
 */
export const fetchProgress = (): Promise<WorldProgress[]> => {
  return axiosInstance.get<WorldProgress[]>('/progresses');
};

/**
 * Save new level's progress after passing a game
 */
export const postProgress = (
  worldId: number, packId: number, levelId: number, hash: string,
  completed: boolean, stepsSpent: number, addMovesAttempts: number,
): Promise<any> => {
  return axiosInstance.post('/progresses', {
    worldId, packId, levelId, hash,
    completed, stepsSpent, addMovesAttempts,
  });
};

/**
 * Update existing level's progress after passing a game
 */
export const putProgress = (
  worldId: number, packId: number, levelId: number, hash: string,
  completed: boolean, stepsSpent: number, addMovesAttempts: number,
): Promise<any> => {
  return axiosInstance.put(`/progresses/${worldId}`, {
    packId, levelId, hash, completed, stepsSpent, addMovesAttempts,
  });
};

/**
 * Delete all user's progress (all worlds)
 */
export const deleteProgress = (): Promise<any> => {
  return axiosInstance.delete('/progresses/all');
};

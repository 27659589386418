import i18n from 'i18next';
import LngDetector from 'i18next-browser-languagedetector';
import { reactI18nextModule } from 'react-i18next';

import translationEN from 'shared/locales/en/translation.json';
import translationRU from 'shared/locales/ru/translation.json';
import langCookieName from 'shared/locales/langCookieName';
import thedollarGameLngDetector from 'shared/locales/lngDetector';


// the translations
const resources = {
  en: {
    translation: translationEN
  },
  ru: {
    translation: translationRU
  }
};

const detectionOptions = {
  // order and from where user language should be detected
  order: ['thedollarGameLngDetector'],

  // keys or params to lookup language from
  lookupCookie: langCookieName,

  // cache user language on
  caches: ['thedollarGameLngDetector'],

  // optional expire and domain for set cookie
  cookieMinutes: 10,
};

const lngDetector = new LngDetector();
lngDetector.addDetector(thedollarGameLngDetector);

i18n
  .use(reactI18nextModule) // passes i18n down to react-i18next
  .use(lngDetector)
  .init({
    detection: detectionOptions,
    debug: true,
    resources,
    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

export default i18n;
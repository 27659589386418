import axios from 'axios';
import store from '../../redux/store';

import { NOT_FOUND, ERROR } from '../../shared/constants';


// configure base url
const instance = axios.create({
  baseURL: process.env.REACT_APP_API_SERVER,
});

// intercept requests and add authorization token
instance.interceptors.request.use(config => {
  const token = store.getState().user.token;
  if (token) {
    config.headers['X-Session-Token'] = token;
  }
  return config;
});

type IStatus = typeof NOT_FOUND | typeof ERROR;

export class AxiousError extends Error {
  public response: Response;
  public status: IStatus;

  constructor(message: string, status: IStatus, response: Response) {
    super(message);
    this.response = response;
    this.status = status;
    Object.setPrototypeOf(this, AxiousError.prototype);
  }
}

instance.interceptors.response.use(
  response => response.data,
  error => {
    if (error.response) {
      console.log('error.response = ', error.response);
      if (error.response.status === 404) {
        return Promise.reject(new AxiousError(error.message, NOT_FOUND, error.response));
      }
    }
    return Promise.reject(new AxiousError(error.message || 'No Response', ERROR, error.response));
  }
);

export default instance;
(window as any).instance = instance;

import store from 'redux/store';
import keys from 'lodash/keys';
import get from 'lodash/get';
import indexOf from 'lodash/indexOf';
import reduce from 'lodash/reduce';
import isEqual from 'lodash/isEqual';
import intersection from 'lodash/intersection';

import { createLevelEntityId, parseLevelEntityId } from 'shared/helpers/schemas';

// redux
import { updateActiveLevelId } from 'redux/actions/tutorial';
import {
	boosterHintClickRequire, boosterMoveBackClickRequire,
	setBoosterHintCoords, setBoosterMoveBackCoords,
	setInfiniteBoosterHint, setInfiniteBoosterMoveBack,
} from 'redux/actions/boosters';
import {
	setCheatStars, bestVertexClickRequire, notBestVertexClickRequire,
	showHintRequire, updateBestVertexCoordRequire, updateGraphCoordRequire,
	updateStepsLeftRequire, updateNotBestVertexDataRequire,
	setInfiniteSteps,
} from 'redux/actions/game';

// constants
import { tutorialConfig } from 'shared/config/tutorialConfig';


/**
 * Tutorial logic, hint management
 */
export default class TutorialManager {

	static _hintType = null;

	/******************************************
	* CLASS METHODS
	******************************************/

	static isMainTutorialPassed() {
		const { settings: { tutorialProgress } } = store.getState();
		if (tutorialProgress === null) {
			return undefined;
		}

		const tutorialConfigLevels = keys(tutorialConfig);

		if (!tutorialProgress) {
			return tutorialConfigLevels.length === 0;
		}

		const tutorialConfigMainLevels = reduce(tutorialConfig, (result, value, key) => {
			if (!!value.isMainTutorialLevel) {
				result.push(key);
			}
			return result;
		}, []);
		const progressOfMainLevels = intersection(tutorialConfigMainLevels, tutorialProgress);
		return isEqual(tutorialConfigMainLevels, progressOfMainLevels);
	}

	static getNextLevel() {
		const lastLevel = this.getLastPassedTutorialLevel();
		const storeLevelsData = get(store.getState(), 'worlds.entities.levels');

		if (!storeLevelsData) return null;

		const storeLevels = keys(storeLevelsData);

		const lastLevelIndex = indexOf(storeLevels, lastLevel);
		return storeLevels[lastLevelIndex + 1];
	}

	static getLastPassedTutorialLevel() {
			const { settings: { tutorialProgress } } = store.getState();
			if (!tutorialProgress) return null;
			return tutorialProgress[tutorialProgress.length - 1];
	}
	/* it returns {
		worldId: string,
		packId: string,
		levelId: string,
	}
	if page need to be redirected to certain level
	*/
	static getLevelForRedirect(url) {
		// if tutorial is passed - no redirect is needed
		const isPassed = this.isMainTutorialPassed();

		console.log(`TUTORIAL PASSED: ${isPassed}`);

		if (isPassed) return null;

		let levelForRedirect = this.getNextLevel();

		if (!levelForRedirect) {
			// if it's totally new user - set leas level to the first in tutorial
			levelForRedirect = keys(tutorialConfig)[0];
		}

		// get data about worldId, packId, levelId
		let levelForRedirectData = parseLevelEntityId(levelForRedirect);

		if (url.indexOf('/game/level/') < 0) {
			return levelForRedirectData;
		}

		// if we're not in this game page - set redirect to true
		if (levelForRedirect !== url.replace('/game/level/', '').replace(/\//g, '-')) {
			return levelForRedirectData;
		}

		return null;
	}

	static start(worldId, packId, levelId) {
		const levelEntityId = createLevelEntityId(worldId, packId, levelId);

		// if there's no tutorial for this level
		if (!tutorialConfig[levelEntityId]) return;

		const isInfiniteSteps = !!tutorialConfig[levelEntityId].isInfiniteSteps;

		store.dispatch(setInfiniteSteps(isInfiniteSteps));
		if (isInfiniteSteps) {
			store.dispatch(setCheatStars(store.getState().game.maxStars));
		}

		store.dispatch(updateActiveLevelId(levelEntityId));
	}

	static setBoosterHintCoords(x, y, r) {
		store.dispatch(setBoosterHintCoords(x, y, r));
	}

	static setBoosterMoveBackCoords(x, y, r) {
		store.dispatch(setBoosterMoveBackCoords(x, y, r));
	}

	static setInfiniteBoosterHint(infinite) {
		store.dispatch(setInfiniteBoosterHint(infinite));
	}

	static setInfiniteBoosterMoveBack(infinite) {
		store.dispatch(setInfiniteBoosterMoveBack(infinite));
	}

	// call game best vertex click if user is passing tutorial and clicks on hint circle
	static callGameBestVertexClick() {
		store.dispatch(bestVertexClickRequire());
	}

	// call game not best vertex click if user is passing tutorial and clicks on hint circle
	static callGameNotBestVertexClick() {
		store.dispatch(notBestVertexClickRequire());
	}

	static callGameShowBestMoveHint() {
		store.dispatch(showHintRequire());
	}

	static callGameShowNotBestMoveHint() {
		store.dispatch(showHintRequire());
	}

	static callGameUpdateBestVertexCoord() {
		store.dispatch(updateBestVertexCoordRequire());
	}

	static callGameUpdateGraphCoord() {
		store.dispatch(updateGraphCoordRequire());
	}

	static callBoosterHintClick() {
		store.dispatch(boosterHintClickRequire());
	}

	static callBoosterMoveBackClick() {
		store.dispatch(boosterMoveBackClickRequire());
	}

	static callGameUpdateStepsLeft() {
		store.dispatch(updateStepsLeftRequire());
	}

	static callGameUpdateNotBestVertexData() {
		store.dispatch(updateNotBestVertexDataRequire());
	}

}

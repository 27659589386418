import store from 'redux/store';

// constants
import { NOT_FOUND } from 'shared/constants';

// redux
import { checkUserExists, createUser, authUser } from 'redux/actions/user';

// modules
import { sendAnalyticsEvent, composeAnalyticsEntityId, withCount } from 'shared/modules/AnalyticsEvents';

// helpers
import { loadYandexMetrica } from 'shared/helpers/analytics';
import { IUserData } from 'shared/api/userAPI';

function sendUserLoginEvent(user: IUserData) {
	const { userId, logins } = user;
	sendAnalyticsEvent('login', withCount({
		[composeAnalyticsEntityId(userId)]: withCount({
			logins
		}),
    }));
    loadYandexMetrica().then(({ default: ym }) => {
        ym('userParams', {
            UserId: userId
        });
    });
}

/**
 * Manages user authentication in applicaiton
 */
export default class Auth {

	/******************************************
	* CLASS METHODS
	******************************************/

	static async authUser(uuid: string) {
		try {
			await store.dispatch(checkUserExists(uuid));
			const user: IUserData = await store.dispatch(authUser(uuid));
			sendUserLoginEvent(user);
			/* ONLY FOR TESTING: Uncommit it if you want to delete this user*/
			// 	store.dispatch(deleteUser());
		} catch (error) {
			// this user isn't exist
			if (error.status && error.status === NOT_FOUND) {
				try {
					// create new user
					await store.dispatch(createUser(uuid));
					// auth user
					const user = await store.dispatch(authUser(uuid));
					sendUserLoginEvent(user);
				} catch (error) { console.log(error) };
			}
		}
	}
}

import isNil from 'lodash/isNil';
import {
  SHOW_HINT, SET_HINT_TYPE, SET_HINT_CIRCLE, SET_HINT_TEXT, SET_HINT_BG_SIZE,
} from 'redux/actionTypes';

import { HINT_TEXT_BOTTOM } from 'shared/constants';


const initialState = {
  circleX: 0,
  circleY: 0,
  circleR: 0,
  text: null,
  textPosition: HINT_TEXT_BOTTOM,
  bgWidth: 0,
  bgHeight: 0,
  show: false,
  hintType: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SHOW_HINT: {
      return {
        ...state,
        show: isNil(action.show) ? true : action.show,
      };
    }
    case SET_HINT_TYPE: {
      return {
        ...state,
        hintType: action.hintType,
      };
    }
    case SET_HINT_CIRCLE: {
      return {
        ...state,
        circleX: action.x,
        circleY: action.y,
        circleR: action.r,
      };
    }
    case SET_HINT_TEXT: {
      return {
        ...state,
        text: action.text,
        textPosition: action.textPosition ? action.textPosition : state.textPosition,
      };
    }
    case SET_HINT_BG_SIZE: {
      return {
        ...state,
        bgWidth: action.bgWidth,
        bgHeight: action.bgHeight,
      };
    }
    default:
      return state;
  }
}

import React from 'react';
import classNames from 'classnames';
import { connect } from 'react-redux';

// modules
import AppSoundManager from 'shared/modules/AppSoundManager';

// redux
import { showSettingsModal } from 'redux/actions/modals';

import styles from './Settings.module.css';


const mapDispatchToProps = dispatch => ({
	showSettingsModal: () => dispatch(showSettingsModal()),
});

class Settings extends React.PureComponent {
	constructor(props) {
	  super(props);
	  this.handlerSettingsBtnClick = this.handlerSettingsBtnClick.bind(this);
	}

	/******************************************
	* COMPONENT HOOKS
	******************************************/

	render() {
		const wrapperClasses = classNames({
		  [styles.Settings]: true,
		  [this.props.className]: !!this.props.className,
		});

		return (
			<div className={wrapperClasses}>
				<button className={styles.SettingsBtn}
					onClick={this.handlerSettingsBtnClick}>
				</button>
			</div>
		);
	}

	/******************************************
	* COMPONENT HANDLERS
	******************************************/

	handlerSettingsBtnClick() {
		AppSoundManager.playButtonSound();
		this.props.showSettingsModal();
	}
}

export default connect(null, mapDispatchToProps)(Settings);
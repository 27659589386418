/**
 * User ID is being stored in localStorage on App initialization
 * Used in the game statistics
 * @type {String}
 */
export const UUID_STORAGE_NAME = 'userId';

/**
 * Page forward/back animation
 * You need to change shared/styles/animation.css after changing this variable
 * @type {Number}
 */
export const PAGE_ANIMATION_TIME = 500;

// boosters
export const BOOSTER_HINT_TYPE = 'hint';
export const BOOSTER_MOVE_BACK_TYPE = 'move-back';


// modals
export const MODAL_APPEAR_TIME = 1000;
export const LIFE_REDUCE_MODAL_SHOW_TIME = 2500;
export const AD_NOT_FOUND_MODAL_SHOW_TIME = 3000;
/**
 * Types of modals
 * @type {String}
 */
export const MODAL_LIFE_PURCHASE = 'MODAL_LIFE_PURCHASE';
export const MODAL_CHANGE_PRICE = 'MODAL_CHANGE_PRICE';
export const MODAL_LIFE_REDUCE = 'MODAL_LIFE_REDUCE';
export const MODAL_SETTINGS = 'MODAL_SETTINGS';
export const MODAL_AD_BLOCK = 'MODAL_AD_BLOCK';
export const MODAL_SECOND_SESSION = 'MODAL_SECOND_SESSION';
export const MODAL_WATCH_AD = 'MODAL_WATCH_AD';
export const MODAL_AD_NOT_FOUND = 'MODAL_AD_NOT_FOUND';

// game
/**
 * Maximum abilities to add moves count per level
 * @type {Number}
 */
export const MAX_ADD_MOVES_ATTEMPTS = 3;

/**
 * Game Win/Fail state change delay
 * @type {Number}
 */
export const CHANGE_STATE_DELAY = 1500;

// Network statuses
export const SUCCESS = 'SUCCESS';
export const ERROR = 'ERROR';
export const NOT_FOUND = 'NOT_FOUND';

/**
 * Maximum levels on tutorial page
 * @type {Number}
 */
export const MAX_TUTORIAL_LEVELS = 3;

/**
 * Hints
 */
// types
export const HINT_BEST_VERTEX = 'best-vertex';
export const HINT_AI_BEST_VERTEX = 'ai-best-vertex';
export const HINT_NOT_BEST_VERTEX = 'not-best-vertex';
export const HINT_GENERAL = 'general';
export const HINT_AI_GENERAL = 'ai-general';
export const HINT_BOOSTER_HINT = 'booster-hint';
export const HINT_BOOSTER_MOVE_BACK = 'booster-move-back';

// text positions
export const HINT_TEXT_TOP = 'top';
export const HINT_TEXT_BOTTOM = 'bottom';
export const HINT_TEXT_STEPS_REGEXP = '%%steps%%';


// button skip postion
export const BTN_SKIP_TOP = 'top';
export const BTN_SKIP_BOTTOM = 'bottom';

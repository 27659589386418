import {
  SET_LIFE_PURCHASE_REQUEST, SET_LIFE_PURCHASE_SUCCESS, SET_LIFE_PURCHASE_FAILURE,
} from 'redux/actionTypes';


const initialState = {
  isSetLifePurchaseInProcess: false,
  isSetLifePurchaseError: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_LIFE_PURCHASE_REQUEST: {
      return {
        ...state,
        isSetLifePurchaseInProcess: true,
        isSetLifePurchaseError: false,
      }
    }
    case SET_LIFE_PURCHASE_SUCCESS: {
      return {
        ...state,
        isSetLifePurchaseInProcess: false,
        isSetLifePurchaseError: false,
      }
    }
    case SET_LIFE_PURCHASE_FAILURE: {
      return {
        ...state,
        isSetLifePurchaseInProcess: false,
        isSetLifePurchaseError: true,
      }
    }
    default:
      return state;
  }
}

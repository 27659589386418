/* eslint react/jsx-pascal-case: [0, { ignore: 1 }] */

import React from 'react';
import { connect } from 'react-redux'
import delay from 'lodash/delay';

// redux
import { getAds } from 'redux/actions/ads';


const mapStateToProps = state => {
  return {
    isPageWithGame: state.cheats.isPageWithGame,
    suggestTime: state.ads.androidOkAppSuggestDesktopIconTimeoutMinutes * 60000, // in ms
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getAds: () => dispatch(getAds()), // important to return it because it's Promise
  };
};

class OKAndroidManager extends React.PureComponent {

  constructor(props) {
    super(props);
    this.isSuggested = false;
    this.suggestTimeout = null;
  }

  /******************************************
  * COMPONENT HOOKS
  ******************************************/

  render() { return null }

  componentDidMount() {
    this.props.getAds()
      .then(() => {
        delay(() => {
          this.suggestCycle();
        }, this.props.suggestTime);
      });
  }
  
  componentWillUnmount() {
    if (this.suggestTimeout) clearTimeout(this.suggestTimeout);
  }

  /******************************************
  * COMPONENT METHODS
  ******************************************/

  suggestCycle() {
    // if we've already suggested icon for this session - stop suggesting
    if (this.isSuggested && this.suggestTimeout) {
      clearTimeout(this.suggestTimeout);
    }

    this.suggestDesktopIcon();
    
    // we're on game page
    if (!this.isSuggested) {
      delay(() => {
        this.suggestCycle();
      }, 1000);
    }
  }

  suggestDesktopIcon() {
    if (!this.props.isPageWithGame) {
      window.OKApp.suggestDesktopIcon();
      this.isSuggested = true;
    }
  }

}

export default connect(mapStateToProps, mapDispatchToProps)(OKAndroidManager);

/* eslint react/jsx-pascal-case: [0, { ignore: 1 }] */

import React, { Component } from 'react';
import { Observable } from 'rxjs';
import classNames from 'classnames';
import anime from 'animejs';

import { propTypes, defaultProps } from './props';

import { HINT_TEXT_TOP, BTN_SKIP_TOP } from 'shared/constants';

// styles
import styles from './Hint.module.css';


class Hint extends Component {

  constructor(props) {
    super(props);
    this.animationDuration = 300;
  }

  /******************************************
  * COMPONENT HOOKS
  ******************************************/

  render() {
    const hintClasses = classNames({
      [styles.Hint]: true,
      [this.props.className]: !!this.props.className,
    });

    const textClasses = classNames({
      [styles.TextWrap]: true,
    });

    const { bgWidth, bgHeight, skipBtnText } = this.props;

    return (
      <div className={hintClasses}>
        <svg className={styles.HintWrap} viewBox={`0 0 ${bgWidth} ${bgHeight}`} >
          <defs>
            <mask id="mask" x="0" y="0" width="80" height="30">
              <rect x='0' y='0' width={bgWidth} height={bgHeight} fill="#fff" />
              <circle ref={node => {
                this.circle = node;
              }}/>
            </mask>
          </defs>
          <rect x="0" y="0" width={bgWidth} height={bgHeight} mask="url(#mask)" fillOpacity="0.7"
            onClick={this.handleRectClick}/>
        </svg>
        <div className={textClasses} ref={ node => {
          this.textWrap = node;
        }}>
          <p className={styles.Text} ref={node => {
            this.textNode = node;
          }}></p>
          <button className={styles.Btn}
            onClick={this.props.onBtnClick}
            ref={node => this.btnNode = node}>
          </button>
        </div>
        <button className={classNames([styles.SkipBtn, styles.SkipBtnPosition])}
          onClick={this.props.onSkipBtnClick}
          ref={node => this.skipBtnNode = node }>
          {skipBtnText}
        </button>
      </div>
    );
  }

  shouldComponentUpdate(nextProps) {
    if (this.props.className !== nextProps.className) return true;
    if (this.props.bgWidth !== nextProps.bgWidth) return true;
    if (this.props.bgHeight !== nextProps.bgHeight) return true;
    if (this.props.text !== nextProps.text) {
      this.textNode.innerHTML = nextProps.text;
      return false;
    }
    if (this.props.btnText !== nextProps.btnText) {
      this.btnNode.innerHTML = nextProps.btnText;
      this.btnNode.classList.toggle(styles.Hidden, !nextProps.btnText);
      return false;
    }
    if (this.props.skipBtnText !== nextProps.skipBtnText) {
        this.skipBtnNode.innerHTML = nextProps.skipBtnText;
    }

    return false;
  }

  /******************************************
  * COMPONENT HANDLERS
  ******************************************/

  handleRectClick = e => {
    // if click was in the circle area
    if (this.isInsideCircle(e.clientX, e.clientY)) {
      this.props.onCircleClick();
    }
  }

  /******************************************
  * COMPONENT METHODS
  ******************************************/

  isInsideCircle(x, y) {
    const { circleX, circleY, circleR } = this;

    const d = Math.sqrt(Math.pow(x - circleX, 2) + Math.pow(y - circleY, 2));

    return d <= circleR;
  }

  getSkipBtnPosition() {
    const { btnSkipPosition } = this;
    switch (btnSkipPosition) {
      case BTN_SKIP_TOP:
        return `bottom: auto; top: 15%`;
      default:
        return '';
    }
  }

  getTextCSSProps() {
    const { circleX, circleY, circleR, textPosition } = this;

    const textWidth = Math.max(circleR * 2, 180);
    const textLeft = circleX - textWidth / 2;

    switch (textPosition) {
      case HINT_TEXT_TOP:
        const textBottom = window.innerHeight - circleY + circleR + 20;
        return `width: ${textWidth}px; bottom: ${textBottom}px; left: ${textLeft}px`;
      default: // HINT_TEXT_BOTTOM by default
        const textTop = circleY + circleR + 20;
        return `width: ${textWidth}px; top: ${textTop}px; left: ${textLeft}px`;
    }
  }

  animateAppear() {
    const { circleX, circleY, circleR, text, textPosition, btnText, btnSkipPosition } = this.props;
    // save props
    this.circleX = circleX;
    this.circleY = circleY;
    this.circleR = circleR;
    this.textPosition = textPosition;
    this.btnSkipPosition = btnSkipPosition;

    this.circle.setAttribute('cx', circleX);
    this.circle.setAttribute('cy', circleY);

    this.textWrap.setAttribute('style', this.getTextCSSProps());
    this.textWrap.classList.toggle(styles.Visible, true);
    this.textNode.innerHTML = text;

    this.btnNode.innerHTML = btnText;
    this.btnNode.classList.toggle(styles.Hidden, !btnText);

    this.skipBtnNode.setAttribute('style', this.getSkipBtnPosition());

    return Observable.create(observer => {
      anime({
        targets: this.circle,
        r: this.circleR,
        easing: 'easeOutQuad',
        duration: this.animationDuration,
        complete: (anim) => {
          observer.next();
          observer.complete();
        },
      });
    });
  }

  animateDisappear() {
    this.textWrap.classList.toggle(styles.Visible, false);

    return Observable.create(observer => {
      anime({
        targets: this.circle,
        r: 0,
        easing: 'easeOutQuad',
        duration: this.animationDuration,
        complete: (anim) => {
          observer.next();
          observer.complete();
        },
      });
    });
  }
}

Hint.propTypes = propTypes;
Hint.defaultProps = defaultProps;

export default Hint;

import { createSelector } from 'reselect';
import get from 'lodash/get';
import values from 'lodash/values';

import { World, Pack, Level } from '../../shared/api/worldsAPI';
import { WorldsEntities, WorldsEntity } from '../../shared/schemas/worlds';
import { createPackEntityId, createLevelEntityId } from '../../shared/helpers/schemas';

const getEntitiesData = (state: any): WorldsEntities => state.worlds.entities;
const getWorldsData = (state: any, props: any): WorldsEntity  => state.worlds.entities.worlds;
const getPropsPackID = (state: any, props: any): number => +props.match.params.packId;
const getPropsLevelID = (state: any, props: any): number => +props.match.params.levelId;
export const getPropsWorldID = (state: any, props: any): number => +props.match.params.worldId;

// Reselect cached selectors

/**
 * Converts entities.worlds object into array
 * @return {Array<World>}
 */
export const getWorldsSelector = createSelector(
  [ getWorldsData ],
  (worldsEntity: WorldsEntity): Array<World> => values(worldsEntity)
);

export const getWorldSelector = createSelector(
  [ getWorldsData, getPropsWorldID ],
  (worlds: WorldsEntity, worldId: number): World => get(worlds, worldId)
);

/**
 * Warning: Pack levels array contains level id's only
 * E.g Pack.Levels = ['1-1-1', '1-1-2']
 */
export const getPackSelector = createSelector(
  [ getPropsWorldID, getPropsPackID, getEntitiesData ],
  (worldId: number, packId: number, entities: WorldsEntities): Pack => {
  	return getPack(worldId, packId, entities);
  }
);

export const getLevelSelector = createSelector(
  [ getPropsWorldID, getPropsPackID, getPropsLevelID, getEntitiesData ],
  (worldId: number, packId: number, levelId: number, entities: WorldsEntities): Level => {
    return getLevel(worldId, packId, levelId, entities);
  }
);

/**
 * Finds Pack and fills Levels array with level entities
 * Pack.Levels = Array<Level>
 */
export const getPackWithLevelsSelector = createSelector(
  [ getPropsWorldID, getPropsPackID, getPackSelector, getEntitiesData ],
  (worldId: number, packId: number, pack: Pack, entities: WorldsEntities): Pack => {
    return {...pack, Levels: getPackLevels(worldId, packId, entities) as any};
  }
);

export const getWorldPacksSelector = createSelector(
  [ getPropsWorldID, getEntitiesData ],
  (worldId: number, entities: WorldsEntities): Array<Pack> => {
    return getWorldPacks(worldId, entities);
  }
);

/**
 * Gets game data for Tutorial page
 */
export const getTutorialGamesSelector = createSelector(
  [ getWorldsSelector, getEntitiesData ],
  (worldsArray: Array<World>, entities: WorldsEntities): any => {
    const firstWorld = worldsArray[0];
    const firstPack = get(entities, `packs.${firstWorld.Packs[0]}`);
    const packLevelsIds = get(firstPack, 'Levels');
    return {
      worldId: firstWorld.Id,
      packId: firstPack.Id,
      levels: packLevelsIds.map((levelId: any) => getLevelByEntityId(levelId, entities))
    };
  }
);

// other selectors

/**
 * Warning: Pack levels array contains level id's only
 * E.g Pack.Levels = ['1-1-1', '1-1-2']
 * @type {Array<Pack>}
 */
export const getWorldPacks = (worldId: number, entities: WorldsEntities): Array<Pack> => {
  const packs = get(entities, `worlds.${worldId}.Packs`);
  if (!packs) return [];
  return packs.map((packId: any) => entities.packs[packId] as Pack);
};

export const getPackLevels = (worldId: number, packId: number, entities: WorldsEntities): Array<Pack> => {
  const packLevels = get(entities, `packs.${createPackEntityId(worldId, packId)}.Levels`);
  if (!packLevels) return [];
  return packLevels.map((levelId: any) => getLevelByEntityId(levelId, entities));
};

export const getLevel = (worldId: number, packId: number, levelId: number, entities: WorldsEntities): Level => {
  return get(entities, `levels.${createLevelEntityId(worldId, packId, levelId)}`);
};

export const getPack = (worldId: number, packId: number, entities: WorldsEntities): Pack => {
  return get(entities, `packs.${createPackEntityId(worldId, packId)}`);
};

export const getLevelByEntityId = (levelId: string, entities: WorldsEntities): Level => {
  return get(entities, `levels.${levelId}`);
};

export const getMaxStarsInWorld = (worldId: number, worldsMaxStars: any): number => {
  return get(worldsMaxStars, worldId, 0);
};

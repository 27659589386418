import PropTypes from 'prop-types';


export const propTypes = {
  livesNumber: PropTypes.number,
  maxLives: PropTypes.number,
  timerStartDate: PropTypes.number,
};

export const defaultProps = {
  livesNumber: 0,
  maxLives: 5,
  timerStartDate: 0,
};
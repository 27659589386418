import React from 'react'
import ReactDOM from 'react-dom'
import 'reset-css';
import 'index.css';
import { Router } from 'react-router-dom'
// import registerServiceWorker from './registerServiceWorker';
import { unregister } from './registerServiceWorker';
import { I18nextProvider } from 'react-i18next';
import { Provider } from 'react-redux';
import { createBrowserHistory } from 'history';

import App from './App'
import i18n from 'shared/i18n'
import store from 'redux/store';

import 'shared/styles/fonts.css';

export default function() {
    const rootEl = document.getElementById('root');
    const history = createBrowserHistory();

    ReactDOM.render((
        <Router history={history}>
            <I18nextProvider i18n={i18n}>
            <Provider store={store}>
                <App />
            </Provider>
            </I18nextProvider>
        </Router>
    ), rootEl);

    unregister();
}
// window.store = store;

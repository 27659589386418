/* eslint react/jsx-pascal-case: [0, { ignore: 1 }] */
import React, { PureComponent } from 'react';
import classNames from 'classnames';
import range from 'lodash/range';

import { propTypes, defaultProps } from './props';

// styles
import styles from './Stars.module.css';


class Stars extends PureComponent {

  constructor(props) {
    super(props);
    this._setRange(props.maxStars);
  }

  /******************************************
  * COMPONENT HOOKS
  ******************************************/

  render() {
    const { resizeOnMobile, starsNumber } = this.props;
    return (
      <div className={classNames(
          styles.StarsWrap,
          (resizeOnMobile ? styles.ResizeOnMobile : null),
          (!!this.props.className ? this.props.className : null)
        )}>
        {this.starsRange.map((num) => {
          const classes = classNames([
            styles.Star,
            (num > starsNumber ? styles.StarOutline : styles.StarFilled)
          ]);
          return (<div key={num} className={classes}></div>);
        })}
      </div>
    );
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.maxStars !== this.props.maxStars) {
      this._setRange(nextProps.maxStars);
    }
  }

  /******************************************
  * COMPONENT METHODS
  ******************************************/

  _setRange(maxStars) {
    this.starsRange = range(1, maxStars + 1);
  }
}


Stars.propTypes = propTypes;
Stars.defaultProps = defaultProps;

export default Stars;

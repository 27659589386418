/* eslint react/jsx-pascal-case: [0, { ignore: 1 }] */

import React from 'react';
import classNames from 'classnames';

// styles
import styles from './AddBtn.module.css';


class AddBtn extends React.PureComponent {

  /******************************************
  * COMPONENT HOOKS
  ******************************************/

  render() {
    const btnClasses = classNames({
      [styles.AddBtn]: true,
      [this.props.className]: !!this.props.className,
      [styles.AddBtnDisabled]: this.props.disabled
    });

    return (
      <button className={btnClasses}
        onClick={this.props.onClick.bind(this)}>
        <i className={styles.AddBtnPlusIcon}></i>
      </button>
    );
  }
}

export default AddBtn;
/*
  REDUCERS FOR HEADER MANAGEMENT
*/
import isNil from 'lodash/isNil';

import {
  HEADER_RESET, HEADER_TOGGLE_SETTINGS, HEADER_TOGGLE_BACK_BTN,
  HEADER_TOGGLE_UPDATE_BTN, HEADER_TOGGLE_USER_LIFES, HEADER_TOGGLE_TITLE,
  HEADER_BACK_BTN_CLICK, HEADER_UPDATE_BTN_CLICK,
} from 'redux/actionTypes';


const initialState = {
  showSettings: false,
  showBackBtn: false,
  showUpdateBtn: false,
  showUserLives: false,
  showTitle: false,
  backBtnClick: false,
  updateBtnClick: false,
};

const getNextShowValue = (actionToggle, currentShowValue) => {
  return isNil(actionToggle) ? !currentShowValue : actionToggle;
};

export default function (state = initialState, action) {
  switch (action.type) {
    case HEADER_RESET:
      return {
        ...initialState,
      };
    case HEADER_TOGGLE_SETTINGS:
      return {
        ...state,
        showSettings: getNextShowValue(action.toggle, state.showSettings),
      };
    case HEADER_TOGGLE_BACK_BTN:
      return {
        ...state,
        showBackBtn: getNextShowValue(action.toggle, state.showBackBtn),
      };
    case HEADER_TOGGLE_UPDATE_BTN:
      return {
        ...state,
        showUpdateBtn: getNextShowValue(action.toggle, state.showUpdateBtn),
      };
    case HEADER_TOGGLE_USER_LIFES:
      return {
        ...state,
        showUserLives: getNextShowValue(action.toggle, state.showUserLives),
      };
    case HEADER_TOGGLE_TITLE:
      return {
        ...state,
        showTitle: getNextShowValue(action.toggle, state.showTitle),
        title: action.title,
      };
    case HEADER_BACK_BTN_CLICK:
      return {
        ...state,
        backBtnClick: !isNil(action.backBtnClick) ? action.backBtnClick : true,
      };
    case HEADER_UPDATE_BTN_CLICK:
      return {
        ...state,
        updateBtnClick: !isNil(action.updateBtnClick) ? action.updateBtnClick : true,
      };
    default:
      return state;
  }
}

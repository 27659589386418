/* eslint react/jsx-pascal-case: [0, { ignore: 1 }] */
import React, { PureComponent } from 'react';
import classNames from 'classnames';
import range from 'lodash/range';

// styles
/* eslint-disable */
// not css module
import styles from './Preloader.css';
/* eslint-enable */

/**
 * Preloader from https://github.com/tobiasahlin/SpinKit
 */
class Preloader extends PureComponent {

  constructor(props) {
    super(props);
    this.circlesRange = range(1, 13);
  }

  /******************************************
  * COMPONENT HOOKS
  ******************************************/

  render() {
    return (
      <div className={classNames(
          'sk-circle',
          (this.props.size === 'big' && 'sizeBig'),
          (!!this.props.className ? this.props.className : null)
        )}>
        {this.circlesRange.map((itemNumber) => {
          return <div className={classNames(['sk-child', `sk-circle${itemNumber}`])} key={itemNumber}></div>
        })}
      </div>
    );
  }

}

export default Preloader;

const mode: "prod" | "stage" = (process as any).env.REACT_APP_MODE;
const appID = {
  prod: 1278241024,
  stage: 1271901952
};

const analyticsConfig = {
  default: {
    prod: {
      YM: 50196268,
      GA: "UA-125048542-1",
    },
    stage: {
      YM: 56762791,
      GA: 'UA-154895611-1'
    }
  },
  ok: {
    stage: {
      YM: 53358607,
      GA: "UA-138686692-1"
    },
    prod: {
      YM: 53415310,
      GA: "UA-138979085-1"
    }
  }
}
const analytics = (process.env.REACT_APP_MODE === 'ok' ? analyticsConfig.ok : analyticsConfig.default)[mode];

export default {
  reviewLink: !process.env.REACT_APP_MODE ? "" : "https://docs.google.com/forms/d/e/1FAIpQLScasE8ms2OCZMfITXGeziVTT78-OmVwQcBpUfZMzHXUyANvPg/viewform",
  secretApiToken: "cbfb633cade1cc5f605d6ac3e4f19021f53960132af18",
  ok: {
    appID: appID[mode],
    appServerURL: "https://ok.thedollargame.io",
    logged_user_id_test: "thedollargame-test-442",
    reviewLink: "https://ok.ru/group/55822418706461/messages",
  },
  analytics
};

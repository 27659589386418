import React, { Component } from 'react';

import { propTypes, defaultProps } from './props';

import { calcCustomListHeight } from 'shared/helpers/lists';

/**
 * Component is using for lists
 * Sets scroll area height according on available height and one list item height 
 */
class ScrollbarHeightAdjuster extends Component {
	constructor(props) {
		super(props);
		this.state = {
			maxHeight: 0,
		}
		this.scrollWrapRef = React.createRef();
	}

	render() {
		const { maxHeight } = this.state;
		return (
			<div className={this.props.className}
				ref={this.scrollWrapRef}
				style={{maxHeight: maxHeight ? calcCustomListHeight(maxHeight, this.props.listItem) : null}}>
					{this.props.children}
			</div>
		);
	}
	componentDidMount() {
		this.setState({
			maxHeight: this.scrollWrapRef.current.clientHeight
		})
	}
}

ScrollbarHeightAdjuster.propTypes = propTypes;
ScrollbarHeightAdjuster.defaultProps = defaultProps;

export default ScrollbarHeightAdjuster
import isNil from 'lodash/isNil';
import {
  GET_BOOSTERS_REQUEST, GET_BOOSTERS_SUCCESS, GET_BOOSTERS_FAILURE,
  PUT_BOOSTERS_REQUEST, PUT_BOOSTERS_SUCCESS, PUT_BOOSTERS_FAILURE,
  BOOSTER_HINT_INCREMENT, BOOSTER_HINT_DECREMENT,
  BOOSTER_MOVE_BACK_INCREMENT, BOOSTER_MOVE_BACK_DECREMENT, RESET_BOOSTERS,
  SET_BOOSTER_HINT_COORDS, SET_BOOSTER_MOVE_BACK_COORDS,
  BOOSTER_HINT_CLICK_REQUIRE, BOOSTER_MOVE_BACK_CLICK_REQUIRE,
  SET_INFINITE_BOOSTER_HINT, SET_INFINITE_BOOSTER_MOVE_BACK,
} from 'redux/actionTypes';


const boosterHintIncCount = 3;
const boosterMoveBackIncCount = 1;

const initialState = {
  hintCount: null,
  maxHintCount: 15,
  moveBackCount: null,
  maxMoveBackCount: 5,
  // api requests
  isGetBoostersInProcess: false,
  isGetBoostersError: false,
  isPutBoostersInProcess: false,
  isPutBoostersError: false,
  // coords
  boosterHintX: 0,
  boosterHintY: 0,
  boosterHintR: 0,
  boosterMoveBackX: 0,
  boosterMoveBackY: 0,
  boosterMoveBackR: 0,
  // requests for actions
  hasBoosterHintClickRequire: false,
  hasBoosterMoveBackClickRequire: false,
  
  infiniteBoosterHint: false,
  infiniteBoosterMoveBack: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_BOOSTERS_REQUEST: {
      return {
        ...state,
        isGetBoostersInProcess: true,
        isGetBoostersError: false,
      };
    }
    case GET_BOOSTERS_SUCCESS: {
      if (action.boosters) {
        const { hintCount, maxHintCount, moveBackCount, maxMoveBackCount } = action.boosters;
        return {
          ...state,
          hintCount: Math.min(hintCount, maxHintCount),
          maxHintCount,
          moveBackCount: Math.min(moveBackCount, maxMoveBackCount),
          maxMoveBackCount,
          isGetBoostersInProcess: false,
          isGetBoostersError: false,
        };
      }
      return {
        ...state,
        hintCount: state.maxHintCount,
        moveBackCount: state.maxMoveBackCount,
        isGetBoostersInProcess: false,
        isGetBoostersError: false,
      };
    }
    case GET_BOOSTERS_FAILURE: {
      return {
        ...state,
        isGetBoostersInProcess: false,
        isGetBoostersError: true,
      };
    }
    case PUT_BOOSTERS_REQUEST: {
      return {
        ...state,
        isPutBoostersInProcess: true,
        isPutBoostersError: false,
      };
    }
    case PUT_BOOSTERS_SUCCESS: {
      return {
        ...state,
        isPutBoostersInProcess: false,
        isPutBoostersError: false,
      };
    }
    case PUT_BOOSTERS_FAILURE: {
      return {
        ...state,
        isPutBoostersInProcess: false,
        isPutBoostersError: true,
      };
    }
    case BOOSTER_HINT_INCREMENT: {
      return {
        ...state,
        hintCount: state.hintCount + boosterHintIncCount,
        moveBackCount: state.moveBackCount,
      };
    }
    case BOOSTER_HINT_DECREMENT: {
      return {
        ...state,
        hintCount: Math.max(0, state.hintCount - 1),
        moveBackCount: state.moveBackCount,
      };
    }
    case BOOSTER_MOVE_BACK_INCREMENT: {
      return {
        ...state,
        hintCount: state.hintCount,
        moveBackCount: state.moveBackCount + boosterMoveBackIncCount,
      };
    }
    case BOOSTER_MOVE_BACK_DECREMENT: {
      return {
        ...state,
        hintCount: state.hintCount,
        moveBackCount: Math.max(0, state.moveBackCount - 1),
      };
    }
    case RESET_BOOSTERS: {
      return {
        ...state,
        hintCount: state.maxHintCount,
        moveBackCount: state.maxMoveBackCount,
      };
    }
    case SET_BOOSTER_HINT_COORDS: {
      return {
        ...state,
        boosterHintX: action.x,
        boosterHintY: action.y,
        boosterHintR: action.r,
      };
    }
    case SET_BOOSTER_MOVE_BACK_COORDS: {
      return {
        ...state,
        boosterMoveBackX: action.x,
        boosterMoveBackY: action.y,
        boosterMoveBackR: action.r,
      };
    }
    case BOOSTER_HINT_CLICK_REQUIRE: {
      return {
        ...state,
        hasBoosterHintClickRequire: isNil(action.require) ? true : action.require,
      };
    }
    case BOOSTER_MOVE_BACK_CLICK_REQUIRE: {
      return {
        ...state,
        hasBoosterMoveBackClickRequire: isNil(action.require) ? true : action.require,
      };
    }
    case SET_INFINITE_BOOSTER_HINT: {
      const infiniteBoosterHint = isNil(action.infinite) ? true : action.infinite;
      return {
        ...state,
        infiniteBoosterHint,
      }
    }
    case SET_INFINITE_BOOSTER_MOVE_BACK: {
      const infiniteBoosterMoveBack = isNil(action.infinite) ? true : action.infinite;
      return {
        ...state,
        infiniteBoosterMoveBack,
      }
    }
    default:
      return state;
  }
}

import isEqual from 'lodash/isEqual';
import pick from 'lodash/pick';

import * as boostersAPI from 'shared/api/boostersAPI';

import {
  putBoostersRequest, putBoostersSuccess, putBoostersFailure,
} from 'redux/actions/boosters';


const fields = ['hintCount', 'moveBackCount', 'maxHintCount', 'maxMoveBackCount'];

export const saveBoostersOnChangeMiddleware = () => store => next => action => {
  // Get the state before and after the action was performed
  const prevState = store.getState();
  const isGetBoostersInProcess = prevState.boosters.isGetBoostersInProcess;

  const prevBoosters = pick(prevState.boosters, fields);
  next(action);
  const nextBoosters = pick(store.getState().boosters, fields);

  // Save data via API on changes
  // If it wasn't change after GET request
  if (!isEqual(prevBoosters, nextBoosters) && !isGetBoostersInProcess) {
    store.dispatch(putBoostersRequest());
    return boostersAPI.putBoosters(nextBoosters)
      .then(() => {
        store.dispatch(putBoostersSuccess());
      }, () => {
        store.dispatch(putBoostersFailure());
      });
  }
};


import isNil from 'lodash/isNil';
  import {
  SET_CHEAT_MODE, SET_FINISH_LEVEL_CHEAT, CLEAR_FINISH_LEVEL_CHEAT,
  SET_PAGE_WITH_LIFES, SET_PAGE_WITH_GAME,
} from 'redux/actionTypes';


const initialState = {
  isCheatMode: false,
  finishLevelWithStars: null,
  isPageWithLifes: false,
  isPageWithGame: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_CHEAT_MODE: {
      return {
        ...state,
        isCheatMode: isNil(action.isCheatMode) ? true : action.isCheatMode,
      }
    }
    case SET_FINISH_LEVEL_CHEAT: {
      return {
        ...state,
        finishLevelWithStars: action.starsCount,
      }
    }
    case CLEAR_FINISH_LEVEL_CHEAT: {
      return {
        ...state,
        finishLevelWithStars: initialState.finishLevelWithStars,
      }
    }
    case SET_PAGE_WITH_LIFES: {
      return {
        ...state,
        isPageWithLifes: !!action.isPageWithLifes,
      }
    }
    case SET_PAGE_WITH_GAME: {
      return {
        ...state,
        isPageWithGame: !!action.isPageWithGame,
      }
    }
    default:
      return {...state};
  }
}

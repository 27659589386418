/* eslint react/jsx-pascal-case: [0, { ignore: 1 }] */

import React, { Component } from 'react';
import classNames from 'classnames';
import { withNamespaces, WithNamespaces } from 'react-i18next';
import { Trans } from 'react-i18next';

import { fb, tw, rd, ok } from 'shared/contacts';
import OKShareBtn from 'shared/components/OKShareBtn';
import { createOKSharing } from "shared/helpers/sharing";

import { sendAnalyticsEvent } from 'shared/modules/AnalyticsEvents';

import config from 'shared/config';

// styles
import styles from './Footer.module.css';

interface InProps {
  className?: string;
}

type FooterProps = InProps & WithNamespaces;

class Footer extends Component<FooterProps> {

  private shareText = 'A puzzle game inspired by graph theory. Play with thousands of people and find the optimal solution to each level!';
  private fbShareLink = 'https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fthedollargame.io%2F&amp;src=sdkpreparse';
  private twShareLink = `https://twitter.com/intent/tweet?text=${encodeURIComponent(this.shareText)}&url=${encodeURIComponent('https://thedollargame.io')}&hashtags=thedollargame`;
  private twitterShareBtnRef = React.createRef<HTMLAnchorElement>();
  private fbShareBtnRef = React.createRef<HTMLDivElement>();

  /******************************************
  * COMPONENT HOOKS
  ******************************************/

  componentDidMount() {
    if (process.env.REACT_APP_SOCIAL_MEDIA !== 'ok') {
      window.waitTwitterInit.then(() => {
        window.twttr.widgets.load(this.twitterShareBtnRef.current!);
      });
      
      window.waitFBInit.then(() => {
        window.FB.XFBML.parse(this.fbShareBtnRef.current);
      });
    }
  }

  render() {
    const footerClasses = classNames(styles.Footer, this.props.className);
    const { t } = this.props;
    return (
      <footer className={footerClasses}>
        {(process.env.REACT_APP_SOCIAL_MEDIA === 'ok') ? (
          <div className={styles.Line}>
            <div className={styles.Contacts}>
              <OKShareBtn
                title={t('footer.okGroup')}
                onClick={this.handlerOKGroupClick} />
            </div>
            <div className={styles.Sharings}>
              <OKShareBtn
                title={t('footer.okShare')}
                onClick={this.handlerOKShareClick} />
            </div>
          </div>
        ) : (
          <div className={styles.Line}>
            <div className={styles.Contacts}>
              <a className={styles.Tw} href={tw} target='_blank' rel='noopener noreferrer'>&nbsp;</a>
              <a className={styles.Fb} href={fb} target='_blank' rel='noopener noreferrer'>&nbsp;</a>
              <a className={styles.Reddit} href={rd} target='_blank' rel='noopener noreferrer'>&nbsp;</a>
            </div>
            <div className={styles.Sharings}>
              <div className={styles.ShareBtn}>
                <a ref={this.twitterShareBtnRef} className={'twitter-share-button'} href={this.twShareLink}>&nbsp;</a>
              </div>
              <div className={styles.ShareBtn} ref={this.fbShareBtnRef}>
                <div className={"fb-share-button"}
                  data-href="https://thedollargame.io"
                  data-layout="button"
                  data-size="small"
                  data-mobile-iframe="true">
                    <a target="_blank" href={this.fbShareLink} className={"fb-xfbml-parse-ignore"} rel='noopener noreferrer'>Share</a>
                </div>
              </div>
            </div>
          </div>
        )}
        {(process.env.REACT_APP_SOCIAL_MEDIA !== 'ok') &&
          <React.Fragment>

            <div className={styles.Line}>
              <p className={styles.Info}>
                <Trans i18nKey="footer.downloadAndroid">
                  <span className={styles.Masked}>Download</span>
                  <a className={styles.FooterLink} target="_blank" href="https://play.google.com/store/apps/details?id=com.Hoodies.thedollargame" rel='noopener noreferrer'>
                  Android
                  </a>&nbsp;<span className={styles.Masked}>version.</span>
                  <span className={styles.Masked}>version</span>
                </Trans>
                <br/>
                <span className={styles.Masked}>{t('footer.ios')}</span>
              </p>
            </div>
            <p className={styles.Info}>
              <span className={styles.Masked}>{t('footer.developed')}&nbsp;</span>
              <a target="_blank" href="https://hoodies.company" className={styles.FooterLink} rel='noopener noreferrer'>
                Hoodies
              </a>
            </p>
          </React.Fragment>
        }
        {config.reviewLink &&
          <div className={styles.Line}>
            <div className={styles.Review}>
              <a
                className={classNames(styles.FooterLink, styles.ReviewLink)}
                href={config.reviewLink}
                rel='noopener noreferrer'
                target="_blank"
              >{t('footer.reviewLink')}</a>
            </div>
          </div>
        }
      </footer>
    );
  }

  handlerOKShareClick = () => {
    sendAnalyticsEvent('shareClick');
    createOKSharing();
  }

  handlerOKGroupClick = () => {
    sendAnalyticsEvent('groupClick');
    window.open(ok, '_blank');
  }
}

export default withNamespaces()(Footer);

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import get from 'lodash/get';
import { Scrollbars } from 'react-custom-scrollbars';
import { withNamespaces } from 'react-i18next';

import { hasPackAccess } from 'shared/helpers/progress';

// modules
import { sendAnalyticsEvent, composeAnalyticsEntityId, withCount } from 'shared/modules/AnalyticsEvents';

// components
import PageTitle from 'shared/components/PageTitle';
import StarsCounter from 'shared/components/StarsCounter';
import AppSoundManager from 'shared/modules/AppSoundManager';
import CustomListItem from 'shared/components/CustomListItem';
import PageHeaderManager from 'shared/containers/PageHeaderManager';
import ScrollbarHeightAdjuster from 'shared/components/ScrollbarHeightAdjuster';
import withTracker from 'shared/components/withTracker';

// constants
import cssEnvVars from 'shared/cssEnvironmentVariables';

// redux
import { getWorldPacksSelector } from 'redux/selectors/worlds';
import {
	getAchievedStarsInWorld, getAchievedStarsInPack, getAllAchievedStars
} from 'redux/selectors/progress';
import { getMaxStarsInWorld } from 'redux/selectors/worlds';

// styles
import styles from './WorldPage.module.css';


const mapStateToProps = (state, props) => ({
	worldsMaxStars: state.worlds.worldsMaxStars,
	packs: getWorldPacksSelector(state, props),
	achievedStars: state.progress.achievedStars,
});

class WorldPage extends Component {
	constructor(props) {
		super(props);
		this.isActive = true;
	}

	get worldId() {
	  return +get(this.props.match.params, 'worldId');
	}

	/******************************************
	* COMPONENT HOOKS
	******************************************/

	render() {
		const { t, achievedStars, worldsMaxStars, packs } = this.props;

		return (
			<div className={styles.WorldPage}>
				<PageHeaderManager 
					showSettings={true}
					showBackBtn={true}
					showUserLives={true}
					onBackClick={this.handlerBackClick}
				/>

				<div className={styles.ContentWrap}>
				  <PageTitle className={styles.WorldPageTitle}>{t(`worlds.${this.worldId}.title`)}</PageTitle>
				  <StarsCounter
				    className={styles.StarsCounter}
				    currentStarsNumber={getAchievedStarsInWorld(this.worldId, achievedStars)}
				    maxStarsNumber={getMaxStarsInWorld(this.worldId, worldsMaxStars)} />
				  <ScrollbarHeightAdjuster
				  	className={styles.PacksWrap}
				  	listItem={{
	            height: cssEnvVars.CUSTOM_LIST_ITEM_HEIGHT,
	            marginBottom: cssEnvVars.CUSTOM_LIST_ITEM_BOTTOM_OFFSET
	          }}>
						<Scrollbars autoHide>
							<ul>
								{packs.map((packItem, index) => {
									const isPackBlocked = !hasPackAccess(packItem.StarsRequired, getAllAchievedStars(achievedStars));
									const packName = t(`worlds.${this.worldId}.packs.${packItem.Id}`);
									return (
										<CustomListItem
											key={index}
											isBlocked={isPackBlocked}
											title={packName}
											starsAchieved={getAchievedStarsInPack(this.worldId, packItem.Id, achievedStars)}
											maxStarsCount={packItem.Levels.length * packItem.MaxStars}
											starsRequired={packItem.StarsRequired}
											onClick={this.handlerPackClick.bind(this, packItem.Id)} />
									);
								})}
							</ul>
						</Scrollbars>
					</ScrollbarHeightAdjuster>
				</div>
			</div>
		);
	}

	componentWillUnmount() {
		this.isActive = false;
	}

	/******************************************
	* COMPONENT HANDLERS
	******************************************/

	handlerPackClick(packId) {
		const { worldId } = this;
		sendAnalyticsEvent('packClick', withCount({ 
			packId: composeAnalyticsEntityId(worldId, packId) 
		}));
		AppSoundManager.playButtonSound();
		this.props.history.push(`/world/${worldId}/pack/${packId}`);
	}

	handlerBackClick = () => {
		AppSoundManager.playButtonSound();
		this.props.history.push(`/`, {routeBack: true});
	}
}

export default withTracker(withNamespaces()(withRouter(connect(mapStateToProps, null)(WorldPage))));
// redux
import store from 'redux/store';
import { setAdBlockDetected } from 'redux/actions/adBlock';
import { $Values } from 'shared/helpers/types';

export const checkAdBlock = () => {
	window.fuckAdBlockInstance.check(true);
}

export const initAdBlockDetector = () => {
	if (!window.fuckAdBlockInstance) {
	  window.fuckAdBlockInstance = new window.FuckAdBlock();
	} else {
		window.fuckAdBlockInstance.setOption('resetOnEnd', false);
	}
	window.fuckAdBlockInstance.onDetected(() => {
	  // console.log('%c nasty AdBLock has been detected!', 'color: #ff201b');
	  store.dispatch(setAdBlockDetected(true));
	});
	window.fuckAdBlockInstance.onNotDetected(() => {
	  // console.log('%c no adBlocks in here..', 'color: #5fba7d');
	  store.dispatch(setAdBlockDetected(false));
	});
	checkAdBlock();
}

/**
 * Possible text types in the adBlockWarningModal
 * @type {Object}
 */
export const warningTextTypes = {
	'lives': 'lives',
	'boosters': 'boosters',
	'moves': 'moves',
} as const;

export type WarningTextType = $Values<typeof warningTextTypes>;

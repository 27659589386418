import React, { Component } from 'react';
import Countdown, { getTimeDifference } from 'react-countdown-now';
import defer from 'lodash/defer';
import { withNamespaces } from 'react-i18next';
import classNames from 'classnames';

import { propTypes, defaultProps } from './props';
import styles from './NewLifeTime.module.css';

// helpers
import { minToMs } from 'shared/helpers/lives';

class NewLifeTime extends Component {
	constructor(props) {
	  super(props);
	  this.state = {
	  	countdownCompleted: false,
		};
		this.checkCountdown();
	}

	get timerStartDate()/*: number|null*/ {
		if (this.props.newLifeTimer) {
			return this.props.newLifeTimer + minToMs(this.props.lifeRestoreMinutes);
		}
		return null;
	}

	/******************************************
	* COMPONENT HOOKS
	******************************************/

	render() {
		const { t } = this.props;
		const timerStartDate = this.timerStartDate;
		return (
			<span className={classNames({
					[styles.NewLifeTime]: true,
					[this.props.className]: !!this.props.className
				})}>
				{this.props.livesNumber >= this.props.maxLives ?
					<React.Fragment>{t('lives.maxLivesTitle')}</React.Fragment>
				: (
					this.state.countdownCompleted || !timerStartDate ?
						'00:00' :
						<Countdown
							date={timerStartDate}
							renderer={this.handlerRenderer}
							onComplete={this.handlerCountdownComplete} />
				)}
			</span>

		);
	}

	shouldComponentUpdate(nextProps, nextState) {
		if (nextProps.newLifeTimer !== this.props.newLifeTimer) return true;
		if (nextProps.livesNumber === this.props.maxLives && nextProps.livesNumber !== this.props.livesNumber) {
			return true;
		}
		if (nextState.countdownCompleted !== this.state.countdownCompleted) return true;
		if (nextProps.t !== this.props.t) return true;
		return false;
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		if (nextProps.newLifeTimer !== this.props.newLifeTimer) {
			this.setState({
        countdownCompleted: false,
      });
		}
	}

	componentDidUpdate(prevProps) {
		if (prevProps.newLifeTimer !== this.props.newLifeTimer) {
			this.checkCountdown();
		}
	}

	/******************************************
	* COMPONENT HANDLERS
	******************************************/

	handlerRenderer = ({ hours, minutes, seconds, completed }) => {
	  if (completed) {
	    return '00:00';
	  } else {
	    // Render a countdown
	    return <React.Fragment>{minutes}:{seconds}</React.Fragment>;
	  }
	};

	handlerCountdownComplete = () => {
		this.setState({countdownCompleted: true});
		if (this.props.onCountdownComplete) {
			defer(() => this.props.onCountdownComplete());
		}
	}

	checkCountdown() {
		if (getTimeDifference(this.timerStartDate).completed) {
			if (this.props.livesNumber >= this.props.maxLives) return;
			this.handlerCountdownComplete();
		}
	}
}

NewLifeTime.propTypes = propTypes;
NewLifeTime.defaultProps = defaultProps;

export default withNamespaces()(NewLifeTime);

import produce from 'immer';

import { UPDATE_ACTIVE_LEVEL_ID, SET_TUTORIAL_PASSED, RESET_TUTORIAL } from 'redux/actionTypes';

const initialState = {
  activeLevelId: null,
  tutorialPassed: false,
};

export default produce((draftState, action) => {
  switch (action.type) {
    case UPDATE_ACTIVE_LEVEL_ID: {
      draftState.activeLevelId = action.activeLevelId;
      break;
    }
    case SET_TUTORIAL_PASSED: {
      draftState.tutorialPassed = true;
      break;
    }
    case RESET_TUTORIAL: {
      draftState.tutorialPassed = false;
      draftState.activeLevelId = null;
      break;
    }
    default: {
      break;
    }
  }
}, initialState);
import {
  GET_SETTINGS_REQUEST,
  GET_SETTINGS_SUCCESS,
  GET_SETTINGS_FAILURE,
  PUT_SETTINGS_REQUEST,
  PUT_SETTINGS_SUCCESS,
  PUT_SETTINGS_FAILURE,
  SET_SETTINGS,
  GET_SETTINGS_TUTORIAL_REQUEST,
  GET_SETTINGS_TUTORIAL_SUCCESS,
  GET_SETTINGS_TUTORIAL_ERROR
} from 'redux/actionTypes';
import * as settingsAPI from 'shared/api/settingsAPI';


/* SYNC ACTION CREATORS */

export const getSettingsRequest = () => ({
  type: GET_SETTINGS_REQUEST,
});

export const getSettingsSuccess = (settings) => ({
  type: GET_SETTINGS_SUCCESS,
  settings,
});

export const getSettingsFailure = () => ({
  type: GET_SETTINGS_FAILURE,
});

export const putSettingsRequest = () => ({
  type: PUT_SETTINGS_REQUEST,
});

export const putSettingsSuccess = () => ({
  type: PUT_SETTINGS_SUCCESS,
});

export const putSettingsFailure = () => ({
  type: PUT_SETTINGS_FAILURE,
});

export const setSettings = (settings) => ({
  type: SET_SETTINGS,
  settings,
});

/* ASYNC ACTION CREATORS */

function apiSettingsTutorial() {
  const fnDefer = arguments[0];
  const args = Array.prototype.slice.call(arguments, 1);
  return function (dispatch) {
    dispatch({
      type: GET_SETTINGS_TUTORIAL_REQUEST
    });
    fnDefer.apply(null, args).then(
      ({
        tutorial
      }) => dispatch({
        type: GET_SETTINGS_TUTORIAL_SUCCESS,
        data: tutorial
      }),
      err => dispatch({
        type: GET_SETTINGS_TUTORIAL_ERROR,
        error: err
      })
    )
  }
}

export const fetchSettingsTutorial = () => apiSettingsTutorial(settingsAPI.fetchSettingsTutorial);
export const updateSettingsTutorial = (levels) => apiSettingsTutorial(settingsAPI.putSettingsTutorial, levels);
export const addSettingsTutorial = (level) => (dispatch, getState) => {
  const {
    settings: {
      tutorialProgress
    }
  } = getState();
  const newProgress = tutorialProgress ? tutorialProgress.concat(level) : [level];
  updateSettingsTutorial(newProgress)(dispatch, getState);
}


export function getSettings() {
  return function (dispatch) {
    dispatch(getSettingsRequest());
    return settingsAPI.fetchSettings()
      .then(settings => {
        dispatch(getSettingsSuccess(settings));
      }, () => {
        dispatch(getSettingsFailure());
      });
  };
};

export function putSettings(settings) {
  return function (dispatch) {
    dispatch(putSettingsRequest());
    return settingsAPI.putSettings(settings)
      .then(() => {
        dispatch(putSettingsSuccess(settings));
      }, () => {
        dispatch(putSettingsFailure());
      });
  };
};

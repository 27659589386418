import React from 'react';
import classNames from 'classnames';

// styles
import styles from './StarsCounter.module.css';


class StarsCounter extends React.PureComponent {

	render() {
		const counterClasses = classNames({
		  [styles.StarsCounter]: true,
		  [this.props.className]: !!this.props.className,
		});
		return (
				<div className={counterClasses}>
					<i className={styles.StarsIcon}></i>
					<span>{this.props.currentStarsNumber}</span>
					<span className={styles.StarsSeparator}>/</span>
					<span>{this.props.maxStarsNumber}</span>
				</div>
		);
	}

}

export default StarsCounter;
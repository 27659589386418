import { schema } from 'normalizr';

import { LevelProgress, PackProgress, WorldProgress } from '../../shared/api/progressAPI';
import { createPackEntityId, createLevelEntityId } from '../../shared/helpers/schemas';

// Normalized Data interfaces

export type WorldsProgressEntity = Record<string, WorldProgress>;

export type PacksProgressEntity = Record<string, PackProgress>;


export type LevelsProgressEntity = Record<string, LevelProgress>;

/**
 * Structure Example:
  entities: {
		worlds: {
			1: {
				worldId: 1,
				packs: ['1-1', '1-2']
			}
			2: {}
		},
		packs: {
			'1-1': {
				packId: number,
				levels: ['1-1-1', '1-1-2', '1-1-3']
			},
			'1-2': {...}
		},
		levels: {
			'1-1-1': {
				id: 1,
				hash: 'level1hash',
				completed: true,
				stepsSpent: 42,
				addMovesAttempts: 3,
			},
			'1-1-2': Level,
			'1-1-3': Level,
		}
  }
 */
export interface ProgressEntities {
	worlds: WorldsProgressEntity;
	packs: PacksProgressEntity;
	levels: any; // LevelsEntity;
};

const LevelProgressSchema = new schema.Entity('levels',
	{},
	{
		idAttribute: (value, parent, key) => createLevelEntityId(parent.worldId, parent.packId, value.id)
	}
);

const PackProgressSchema = new schema.Entity('packs', {
	levels: [LevelProgressSchema]
}, {
	processStrategy: (value, parent, key) => ({ ...value, worldId: parent.worldId }),
	idAttribute: (value, parent, key) => createPackEntityId(parent.worldId, value.packId),
});

export const WorldProgressSchema = new schema.Entity('worlds', {
	packs: [PackProgressSchema]
}, { idAttribute: 'worldId' });

export const ProgressListSchema = [WorldProgressSchema];

import isEqual from 'lodash/isEqual';
import get from 'lodash/get';

import { setCheatStars, setGamePassed } from 'redux/actions/game';


export const finishGameCheatMiddleware = () => store => next => action => {
  // Get the state before and after the action was performed
  const finishLevelWithStarsPrev = get(store.getState(), 'cheats.finishLevelWithStars');
  
  next(action);
  const finishLevelWithStarsNext = get(store.getState(), 'cheats.finishLevelWithStars');

  if (!isEqual(finishLevelWithStarsPrev, finishLevelWithStarsNext) && finishLevelWithStarsNext > 0) {
    store.dispatch(setCheatStars(finishLevelWithStarsNext));
    store.dispatch(setGamePassed());
  }
};
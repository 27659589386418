import isNil from 'lodash/isNil';
import {
  SET_GAME, RESET_GAME,
  CLEAR_STEPS_SPENT, SET_STEPS_SPENT, SET_LEVEL_HASH,
  SET_MAX_STARS, SET_MAX_MOVES_COUNT, SET_BEST_MOVES_COUNT,
  SET_GAME_PASSED, SET_GAME_LOST, SET_MOVES_RUN_OUT, SET_CHEAT_STARS,
  SET_BEST_VERTEX_COORDS, SET_GRAPH_COORDS,
  SET_STEPS_LEFT, SET_NOT_BEST_VERTEX_DATA,
  BEST_VERTEX_CLICK_REQUIRE, NOT_BEST_VERTEX_CLICK_REQUIRE, SHOW_HINT_REQUIRE, 
  UPDATE_BEST_VERTEX_COORD_REQUIRE, UPDATE_GRAPH_COORD_REQUIRE,
  SET_INFINITE_STEPS, UPDATE_STEPS_LEFT_REQUIRE, UPDATE_NOT_BEST_VERTEX_DATA_REQUIRE,
} from 'redux/actionTypes';
import { getAchievedStarsAmount, getStepsForCheatStars } from 'shared/helpers/progress';


const initialState = {
  worldId: null,
  packId: null,
  levelId: null,
  levelHash: null,
  stepsSpent: 0,
  maxMovesCount: 0,
  bestMovesCount: 0,
  maxStars: 0,
  achievedStars: 0,
  cheatStars: 0, // if user passed it with cheat
  // final states
  passed: false,
  lost: false,
  movesRunOut: false,
  // coordinates
  bestVertexX: 0,
  bestVertexY: 0,
  bestVertexR: 0,
  graphX: 0,
  graphY: 0,
  graphR: 0,
  // requests for actions
  hasBestVertexClickRequire: false,
  hasShowHintRequire: false,
  hasUpdateBestVertexCoordRequire: false,
  hasUpdateGraphCoordRequire: false,
  infiniteSteps: false,
  
  // AI steps left & action require
  hasUpdateStepsLeftRequire: false,
  stepsLeft: null,

  // best vertex data & action require
  hasUpdateNotBestVertexDataRequire: false,
  hasNotBestVertexClickRequire: false,
  notBestVertexIndex: -1,
  notBestVertexX: 0,
  notBestVertexY: 0,
  notBestVertexR: 0,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_GAME: {
      return {
        ...initialState,
        worldId: action.worldId,
        packId: action.packId,
        levelId: action.levelId,
        levelHash: action.levelHash,
        maxMovesCount: action.maxMovesCount,
        bestMovesCount: action.bestMovesCount,
        maxStars: action.maxStars,
        stepsSpent: 0,
        achievedStars: 0,
        cheatStars: 0, // if user passed it with cheat
        passed: false,
        lost: false,
        movesRunOut: false,
      };
    }
    case RESET_GAME: {
      return {
        ...state,
        stepsSpent: 0,
        achievedStars: 0,
        cheatStars: 0, // if user passed it with cheat
        passed: false,
        lost: false,
        movesRunOut: false,
      };
    }
    case CLEAR_STEPS_SPENT: {
      return {
        ...state,
        stepsSpent: initialState.stepsSpent,
      };
    }
    case SET_STEPS_SPENT: {
      return {
        ...state,
        stepsSpent: action.stepsSpent,
      };
    }
    case SET_LEVEL_HASH: {
      return {
        ...state,
        levelHash: action.levelHash,
      };
    }
    case SET_MAX_STARS: {
      return {
        ...state,
        maxStars: action.maxStars,
      };
    }
    case SET_MAX_MOVES_COUNT: {
      return {
        ...state,
        maxMovesCount: action.maxMovesCount,
      };
    }
    case SET_BEST_MOVES_COUNT: {
      return {
        ...state,
        bestMovesCount: action.bestMovesCount,
      };
    }
    case SET_INFINITE_STEPS: {
      return {
        ...state,
        infiniteSteps: !!action.infiniteSteps,
      };
    }
    case SET_GAME_PASSED: {
      const cheatSteps = (state.cheatStars > 0) ?
        getStepsForCheatStars(
          state.maxMovesCount,
          state.bestMovesCount,
          state.maxStars,
          state.cheatStars,
        ) : 0;
      
      const achievedStars = getAchievedStarsAmount(
        state.maxMovesCount,
        state.bestMovesCount,
        state.stepsSpent,
        state.maxStars,
        state.cheatStars,
      );

      return {
        ...state,
        stepsSpent: cheatSteps ? cheatSteps : state.stepsSpent,
        passed: isNil(action.passed) || action.passed,
        lost: false,
        movesRunOut: false,
        achievedStars,
      };
    }
    case SET_GAME_LOST: {
      return {
        ...state,
        passed: false,
        lost: isNil(action.lost) || action.lost,
        movesRunOut: false,
      };
    }
    case SET_MOVES_RUN_OUT: {
      return {
        ...state,
        passed: false,
        lost: false,
        movesRunOut: isNil(action.movesRunOut) || action.movesRunOut,
      };
    }
    case SET_CHEAT_STARS: {
      return {
        ...state,
        cheatStars: action.cheatStars,
      };
    }
    case SET_BEST_VERTEX_COORDS: {
      return {
        ...state,
        bestVertexX: action.x,
        bestVertexY: action.y,
        bestVertexR: action.r,
      };
    }
    case SET_GRAPH_COORDS: {
      return {
        ...state,
        graphX: action.x,
        graphY: action.y,
        graphR: action.r,
      };
    }
    case SET_STEPS_LEFT: {
      return {
        ...state,
        stepsLeft: action.stepsLeft,
      };
    }
    case SET_NOT_BEST_VERTEX_DATA: {
      return {
        ...state,
        notBestVertexIndex: action.index,
        notBestVertexX: action.x,
        notBestVertexY: action.y,
        notBestVertexR: action.r,
      };
    }
    case BEST_VERTEX_CLICK_REQUIRE: {
      return {
        ...state,
        hasBestVertexClickRequire: isNil(action.require) ? true : action.require,
      };
    }
    case NOT_BEST_VERTEX_CLICK_REQUIRE: {
      return {
        ...state,
        hasNotBestVertexClickRequire: isNil(action.require) ? true : action.require,
      };
    }
    case UPDATE_BEST_VERTEX_COORD_REQUIRE: {
      return {
        ...state,
        hasUpdateBestVertexCoordRequire: isNil(action.require) ? true : action.require,
      };
    }
    case UPDATE_GRAPH_COORD_REQUIRE: {
      return {
        ...state,
        hasUpdateGraphCoordRequire: isNil(action.require) ? true : action.require,
      };
    }
    case SHOW_HINT_REQUIRE: {
      return {
        ...state,
        hasShowHintRequire: isNil(action.require) ? true : action.require,
      };
    }
    case UPDATE_STEPS_LEFT_REQUIRE: {
      return {
        ...state,
        hasUpdateStepsLeftRequire: isNil(action.require) ? true : action.require,
      };
    }
    case UPDATE_NOT_BEST_VERTEX_DATA_REQUIRE: {
      return {
        ...state,
        hasUpdateNotBestVertexDataRequire: isNil(action.require) ? true : action.require,
      };
    }
    default:
      return state;
  }
}

import { defaultLng, okDefaultLng /*, appLngForDetectedLng */ } from 'shared/locales/languages';
import cookies from 'browser-cookies';


export default {
  name: 'thedollarGameLngDetector',

  lookup(options) {
    // check cookies first
    const cookiesLang = cookies.get(options.lookupCookie);
    if (cookiesLang) return cookiesLang;

    // set russian languages as default for ok.ru platform
    if (process.env.REACT_APP_SOCIAL_MEDIA === 'ok') {
      return okDefaultLng;
    }

    // check navigator then
    /* Switch off language detector - EN always default
    if (navigator.language) {
      const appLng = appLngForDetectedLng(navigator.language.toLowerCase());
      return appLng;
    } */

    // default
    return defaultLng;
  },

  cacheUserLanguage(lng, options) {
    cookies.set(options.lookupCookie, lng);
  }
};

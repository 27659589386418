import { Dispatch } from 'redux';
import {
  SET_USER_ID,
  CHECK_USER_REQUEST, CHECK_USER_SUCCESS, CHECK_USER_FAILURE,
  CREATE_USER_REQUEST, CREATE_USER_SUCCESS, CREATE_USER_FAILURE,
  AUTH_USER_REQUEST, AUTH_USER_SUCCESS, AUTH_USER_FAILURE,
  DELETE_USER_REQUEST, DELETE_USER_SUCCESS, DELETE_USER_FAILURE,
  DELETE_SESSION_REQUEST, DELETE_SESSION_SUCCESS, DELETE_SESSION_FAILURE,
} from '../../redux/actionTypes';
import * as userAPI from '../../shared/api/userAPI';
import { AxiousError } from 'shared/api/axiosInstance';

/* SYNC ACTION CREATORS */

export const setUserUuid = (uuid: string) => ({
  type: SET_USER_ID,
  uuid,
});

// CHECK USER

export const checkUserRequest = () => ({
  type: CHECK_USER_REQUEST,
});

export const checkUserSuccess = (user: any) => ({
  type: CHECK_USER_SUCCESS,
  user,
});

export const checkUserFailure = () => ({
  type: CHECK_USER_FAILURE,
});

// CREATE USER

export const createUserRequest = () => ({
  type: CREATE_USER_REQUEST,
});

export const createUserSuccess = (user: any) => ({
  type: CREATE_USER_SUCCESS,
  user,
});

export const createUserFailure = () => ({
  type: CREATE_USER_FAILURE,
});

// AUTH USER

export const authUserRequest = () => ({
  type: AUTH_USER_REQUEST,
});

export const authUserSuccess = (user: any) => ({
  type: AUTH_USER_SUCCESS,
  user,
});

export const authUserFailure = () => ({
  type: AUTH_USER_FAILURE,
});

// DELETE USER

export const deleteUserRequest = () => ({
  type: DELETE_USER_REQUEST,
});

export const deleteUserSuccess = () => ({
  type: DELETE_USER_SUCCESS,
});

export const deleteUserFailure = () => ({
  type: DELETE_USER_FAILURE,
});

// DELETE SESSION

export const deleteSessionRequest = () => ({
  type: DELETE_SESSION_REQUEST,
});

export const deleteSessionSuccess = (user?: any) => ({
  type: DELETE_SESSION_SUCCESS,
  user,
});

export const deleteSessionFailure = () => ({
  type: DELETE_SESSION_FAILURE,
});

/* ASYNC ACTION CREATORS */

export function checkUserExists(uuid: string) {
  return function(dispatch: Dispatch) {
    dispatch(checkUserRequest());

    return userAPI.checkUserExists(uuid).then((user: userAPI.IUserExistsData) => {
        dispatch(checkUserSuccess(user));
        return user;
      })
      .catch((error: AxiousError) => {
        dispatch(checkUserFailure());
        throw error;
      });
  };
};

export function createUser(uuid: string) {
  return function(dispatch: Dispatch) {
    dispatch(createUserRequest());

    return userAPI.createUser(uuid)
      .then((user: userAPI.IUserCreateData) => {
        dispatch(createUserSuccess(user));
        return user;
      })
      .catch((error: AxiousError) => {
        dispatch(createUserFailure());
        throw error;
      });
  };
};

export function authUser(uuid: string) {
  return function (dispatch: Dispatch) {
    dispatch(authUserRequest());

    return userAPI.authUser(uuid)
      .then((user: userAPI.IUserData) => {
        dispatch(setUserUuid(uuid));
        dispatch(authUserSuccess(user));
        return user;
      })
      .catch((error: AxiousError) => {
        dispatch(authUserFailure());
        throw error;
      });
  }
};

export function deleteUser() {
  return function (dispatch: Dispatch) {
    dispatch(deleteUserRequest());

    return userAPI.deleteUser()
      .then(() => {
        dispatch(deleteUserSuccess());
        return;
      })
      .catch((error: AxiousError) => {
        dispatch(deleteUserFailure());
        throw error;
      });
  };
};

export function deleteSession() {
  return function(dispatch: Dispatch) {
    dispatch(deleteSessionRequest());

    return userAPI.deleteSession()
      .then(() => {
        dispatch(deleteSessionSuccess());
        return;
      })
      .catch((error: AxiousError) => {
        dispatch(deleteSessionFailure());
        throw error;
      });
  };
};

import PropTypes from 'prop-types';


export const propTypes = {
  starsNumber: PropTypes.number.isRequired,
  resizeOnMobile: PropTypes.bool,
  maxStars: PropTypes.number.isRequired, // maximum stars user can achieve in level
};

export const defaultProps = {
  starsNumber: 0,
  resizeOnMobile: false,
  maxStars: 3,
};
import { normalize } from 'normalizr';

import { GET_WORLDS_SUCCESS, GET_WORLDS_FAILURE } from '../../redux/actionTypes';
import { fetchWorlds } from '../../shared/api/worldsAPI';
import { updateWorldsLocales } from '../../shared/helpers/locales';
import { WorldListSchema, WorldsEntities } from '../../shared/schemas/worlds';

export const getWorldsSuccess = (worlds: WorldsEntities) => ({
  type: GET_WORLDS_SUCCESS,
  worlds
});

export const getWorldsFailure = () => ({
  type: GET_WORLDS_FAILURE,
});

/* ASYNC ACTION CREATORS */

export const getWorlds = () => {
  return (dispatch: any, getState: any): Promise<WorldsEntities> => {
  	if (getState().worlds.entities) {
  		return Promise.resolve(getState().worlds.entities);
  	}
    return fetchWorlds().then((worldsData) => {
      // Update titles according locales
      updateWorldsLocales(worldsData);
      // Normalize data, see WorldsEntities interface structure
      const normalizedData = normalize(worldsData, WorldListSchema);
    	dispatch(getWorldsSuccess(normalizedData.entities));
      return normalizedData.entities;
    }, (err) => {
      dispatch(getWorldsFailure());
    });
  };
};

import axios from 'axios';
import get from 'lodash/get';

/**
 * Entity id in the normalized data
 * Pack id example: '1-1', '2-1'. First number represents World.Id, second number is Pack.Id
 * level id example '1-1-1', '1-1-2', '3-2-1'. First number is World.Id, second is Pack.Id, thrid is Level.Id
 * @type {entityID}
 */
export type entityID = string;
export type WorldProgress = any;
export interface Graph {
	Vertices: Array<any>;
	MaxMovesCount: number;
	BestMovesCount: number;
	Data: any;
};

export interface Level {
	Id: number;
	Hash: string;
	Graph: Graph;
};

export interface Pack {
	Id: number;
	Name: string;
	Locales: any;
	StarsRequired: number;
	MaxStars: number;
	Levels: Array<Level|entityID>;
};

export interface World {
	Id: number;
	Name: string;
	Locales: any;
	Packs: Array<Pack|entityID>;
};

/**
 * Fetch worlds data or return worldsData cached array
 * Worlds Data Structure
	[{
	  	Id: number,
	  	Name: string,
	  	Locales: {en: string, ru: string}
	  	Packs: [{
	  		Id: number,
	  		Name: string,
	  		Locales: {en: string, ru: string}
	  		StarsRequired: number
	  		Levels: [{
	  			Id: number,
	  			Hash: string,
	  			Graph: {
	  				Vertices: Array
	  				MaxMovesCount: number
	  				BestMovesCount: number
	  				Data: Object,
	  			}
	  		}]
	  	}],
	 }]
*/
export const fetchWorlds = (): Promise<Array<World>> => {
	const endpoint =
		//axios.get(`${String(process.env.REACT_APP_API)}/worlds`);
		axios.get('/worlds_new_28.01.20.json');

	return endpoint.then(response => {
		return get(response, 'data');
	})
	.catch((error) => {
    	console.log(error);
		throw new Error(error);
  	});
};

import loadable from '@loadable/component';
import config from '../../shared/config';
import { sendAnalyticsEvent, withCount } from 'shared/modules/AnalyticsEvents';

type YmFunction = (methodName: string, ...args: any[]) => void;

export const isAnalyticsEnabled = true;

export const getAnalyticsID = (analyticsName: 'GA' | 'YM'): string|number => {
  return config.analytics[analyticsName];
}

export const loadYandexMetrica = (): Promise<{ default: YmFunction } & any> => {
  if (isAnalyticsEnabled) {
    return loadable.lib<{ default: YmFunction } & any>(() => import('react-yandex-metrika')).load()
  }
  return Promise.reject();
};

export const loadReactGA = (): Promise<any> => {
  if (isAnalyticsEnabled) return loadable.lib(() => import('react-ga')).load();
  return Promise.reject();
};

export const sendRouteMetric = (currentRoute: string, previousRoute?: string): void => {
  sendAnalyticsEvent('route', withCount({
    [currentRoute]: withCount({
      fromRoute: previousRoute
    })
  }));
};

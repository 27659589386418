import findIndex from 'lodash/findIndex';
import map from 'lodash/map';
import get from 'lodash/get';
import reduce from 'lodash/reduce';
import isNil from 'lodash/isNil';
import keys from 'lodash/keys';
import forEach from 'lodash/forEach';
import * as ls from 'store2';


import { MAX_ADD_MOVES_ATTEMPTS } from '../../shared/constants';
import { Level, Pack } from '../../shared/api/worldsAPI';
import { PackProgress, LevelProgress } from '../../shared/api/progressAPI';
import { ProgressEntities } from '../../shared/schemas/progress';
import { WorldsEntities } from '../../shared/schemas/worlds';
import { getLevelProgress, getPackLevelsProgress } from '../../redux/selectors/progress';
import { getPackLevels } from '../../redux/selectors/worlds';
import { createLevelEntityId } from '../../shared/helpers/schemas';


const lastVisitedLevelName = `lastVisitedLevel__${String(process.env.REACT_APP_LEVELS_VERSION)}`;

interface LastVisitedLevel {
  worldId: number;
  packId: number;
  levelId: number;
};

const nextPackMsgShowedPrefix = 'isNextPackMsgShowed__';

/**
 * Creates empty pack progress
 * @param  {number} worldId
 * @param  {number} packId
 * @param  {Array} packLevels - Passed Level's pack with all levels
 * @return {PackProgress}
 */
export const createPackProgress = (worldId: number, packId: number, packLevels: Array<Level> = []): PackProgress => {
  return {
    packId,
    worldId,
    levels: map(packLevels, (levelData) => {
      return createLevelEntityId(worldId, packId, levelData.Id);
    }),
  };
};

/**
 * Creates empty level progress
 * @param  {number} levelId
 * @param  {string} levelHash - Level's hash
 * @return {LevelProgress}
 */
export const createLevelProgress = (levelId: number, levelHash: string, stepsSpent: number, completed: boolean, addMovesAttempts: number): LevelProgress => {
  return {
    id: levelId,
    hash: levelHash ? levelHash : undefined,
    completed: !!completed,
    stepsSpent: stepsSpent ? stepsSpent : undefined,
    addMovesAttempts: !isNil(addMovesAttempts) ? addMovesAttempts : MAX_ADD_MOVES_ATTEMPTS,
  };
};

/**
 * Check if there is another level after levelId exists in the pack
 * @param  {number} levelId
 * @param  {Array} packLevels - Levels array of the pack
 * @return {boolean}
 */
export const hasNextLevelInPack = (levelId: number, packLevels: Array<Level>): boolean => {
  const levelIndex = findIndex(packLevels, { Id: levelId } as any);
  return (levelIndex >= 0 && levelIndex !== packLevels.length - 1);
};

/**
 * Check if user has access to the level
 * Previous level must be completed before next can be started
 * @param  {number} worldId
 * @param  {number} levelId
 * @param  {number} packId
 * @param  {ProgressEntities} normalized progress from store
 * @return {boolean}
 */
export const hasLevelAccess = (worldId: number, packId: number, levelId: number, progressEntities: ProgressEntities): boolean => {
  if (levelId === 1) return true;
  const prevLevel = getLevelProgress(worldId, packId, levelId - 1, progressEntities as any);
  return get(prevLevel, 'completed', false);
};

export const hasPackAccess = (starsRequired: number, achievedStarsInAllWorlds: number): boolean => {
  return achievedStarsInAllWorlds >= starsRequired;
};

/**
 * Calculate pack's achieved stars according on progress in the localStorage
 * @param {number} worldId
 * @param {number} packId
 * @param {number} maxStars - pack.MaxStars
 * @param {WorldsEntities} worlds - normalized worlds data from store
 * @param  {ProgressEntities} normalized progress from store
 * @return {number} - Number of achieved stars in the pack
 */
export const calcAchievedStarsInPack = (worldId: number, packId: number, maxStars: number, worlds: WorldsEntities, progress: ProgressEntities): number => {
  const packLevels = getPackLevels(worldId, packId, worlds);
  const packLevelsProgress = getPackLevelsProgress(worldId, packId, progress);
  if (!packLevelsProgress) return 0;
  return packLevelsProgress.reduce((result: any, levelProgress: any) => {
    if (!levelProgress.completed) return result;
    const levelData: any = packLevels.find((item) => item.Id === levelProgress.id);
    let levelAchievedStars = 0;

    if (levelData) {
      levelAchievedStars = getAchievedStarsAmount(
        levelData.Graph.MaxMovesCount,
        levelData.Graph.BestMovesCount,
        levelProgress.stepsSpent,
        maxStars,
        levelProgress.cheatStars,
      );
    }
    return result + levelAchievedStars;
  }, 0);
};

export const calcMaxStarsInWorld = (worldPacksData: Array<Pack>): number => {
  if (!worldPacksData) {
    return 0;
  }
  return reduce(worldPacksData, (result: number, pack: Pack) => {
    return pack.Levels.length * pack.MaxStars + result;
  }, 0);
};

// Saves level data (world,pack,level) of the last visited level
// Proceed user to this level after continue button click
export const saveLastVisitedLevel = (worldId: number, packId: number, levelId: number) => {
  ls.set(lastVisitedLevelName, { worldId, packId, levelId });
};

// Gets last visited level data
export const getLastVisitedLevel = (): LastVisitedLevel => {
  return ls.get(lastVisitedLevelName);
};

// Clears last visited level data
export const clearLastVisitedLevel = (): void => {
  ls.remove(lastVisitedLevelName);
};

/*
  * Если пользователь прошел уровень за BestMovesCount или меньше, то возвращаем maxStars
  * В остальных случаях высчитываем диапазоны для оставшихся звезд
  * Example: MaxMovesCount = 50, BestMovesCount = 30
  * maxStars=3 => 30-1=3, 41-31=2, 42-50=1, 51 и больше=1
  * maxStars=5 => 1-30=5, 31-36=4, 37-42=3, 43-48=2, 49-50=1
  * https://trello.com/c/NmveTUtI
  * https://trello.com/c/OW8WlXOa
*/
export const getAchievedStarsAmount = (maxMovesCount: number, bestMovesCount: number, currentMovesCount: number, maxStars: number, cheatStars?: number): number => {
  if (cheatStars) return cheatStars;
  // if user made less or equal moves count to bestMovesCount
  if (currentMovesCount <= bestMovesCount) {
    return maxStars;
  }
  // if user made more moves count as maxMovesCount (e.g. with advertisement)
  if (currentMovesCount > maxMovesCount) {
    return 1;
  }
  // else - calculate stars
  const delta = maxMovesCount - bestMovesCount;
  const boundariesNumber = maxStars - 1;
  const rangeSize = Math.round(delta/boundariesNumber);
  let lastRangeEndNumber;
  for (let i = boundariesNumber; i > 0; i--) {
    let rangeStartNumber: number;
    if (lastRangeEndNumber) {
      rangeStartNumber = lastRangeEndNumber + 1;
    } else {
      rangeStartNumber = bestMovesCount + 1;
    }
    let rangeEndNumber = rangeStartNumber + rangeSize;
    if (currentMovesCount >= rangeStartNumber && currentMovesCount <= rangeEndNumber) {
      return i;
    }
    lastRangeEndNumber = rangeEndNumber;
  }
  return 0;
};

/*
  * Если пользователь прошел уровень с читами, то возвращаем кол-во шагов,
  * Нужное для прохождения этого уровня с cheatStars
*/
export const getStepsForCheatStars = (maxMovesCount: number, bestMovesCount: number, maxStars: number, cheatStars?: number): number => {
  const delta = maxMovesCount - bestMovesCount;
  const boundariesNumber = maxStars - 1;
  const rangeSize = Math.round(delta / boundariesNumber);

  return maxMovesCount - rangeSize * (cheatStars! - 1);
};

/**
  * Returns next available pack ID if we have one
  * @param {number} worldId
  * @param {number} packId
  * @param {Array} packs - normalized packs data from store (array of packs Id of the world)
  * @param {number} achievedStarsInWorld - number of current achieved stars in wirld
  * @return {string} - next pack ID
*/
export const getNextAvailablePackId = (worldId: number, packId: number, packs: Array<any>, achievedStarsInWorld: number): string | null => {
  const currIndex = findIndex(packs, {Id: packId} as any);
  if (currIndex < packs.length - 1) {
    const nextPack = packs[currIndex + 1];
    return achievedStarsInWorld >= nextPack.StarsRequired ? nextPack.Id : null;
  }
  return null;
};


const getNextPackMsgShowedName = (worldId: number, packId: number): string => {
  return `${nextPackMsgShowedPrefix}${worldId}__${packId}`;
};

export const isNextPackMsgShowed = (worldId: number, packId: number): boolean => {
  const nextPackMsgShowedName = getNextPackMsgShowedName(worldId, packId);
  const flag = ls.get(nextPackMsgShowedName);
  return !!flag;
};

export const setNextPackMsgShowed = (worldId: number, packId: number): void => {
  const nextPackMsgShowedName = getNextPackMsgShowedName(worldId, packId);
  ls.set(nextPackMsgShowedName, true);
};

export const clearNextPackMsgShowed = (worldId: number, packId: number): void => {
  const nextPackMsgShowedName = getNextPackMsgShowedName(worldId, packId);
  ls.remove(nextPackMsgShowedName);
};

export const clearAllNextPackMsgShowed = (): void => {
  const arrToRemove: any[] = [];
  const lsKeys = keys(ls.getAll());

  // Iterate over localStorage and insert the keys into arr
  forEach(lsKeys, key => {
    if (key.indexOf(nextPackMsgShowedPrefix) >= 0) {
      arrToRemove.push(key);
    }
  });

  // Remove the items by key
  forEach(arrToRemove, key => {
    ls.remove(key);
  });
};

import {
  SHOW_HINT, SET_HINT_CIRCLE, SET_HINT_TEXT, SET_HINT_BG_SIZE,
} from '../actionTypes';

type TextPosition = 'top' | 'bottom';


/* SYNC ACTION CREATORS */

export const showHint = (show: boolean) => ({
  type: SHOW_HINT,
  show,
});

export const setCircle = (x: number, y: number, r: number) => ({
  type: SET_HINT_CIRCLE,
  x, // x position
  y, // y position
  r, // radius
});

export const setText = (text: any, textPosition: TextPosition) => ({
  type: SET_HINT_TEXT,
  text, // text string
  textPosition,
});

export const setBgSize = (bgWidth: number, bgHeight: number) => ({
  type: SET_HINT_BG_SIZE,
  bgWidth,
  bgHeight,
});

import axiosInstance from 'shared/api/axiosInstance';


export interface IPutLivesParams {
  livesNumber: number;
  maxLivesNumber: number;
  newLifeTimer: number;
  infiniteLife: boolean;
  lifeRestoreMinutes: string;
}

export const fetchLives = () =>
  axiosInstance.get<IPutLivesParams>('/lives') as any as Promise<IPutLivesParams>

export const putLives = (data: Partial<IPutLivesParams>) =>
  axiosInstance.put<IPutLivesParams>('/lives', data) as any as Promise<IPutLivesParams>;

export const deleteLivesNumber = () =>
  axiosInstance.delete('/lives') as any as Promise<IPutLivesParams>

export const increaseLivesNumber = () =>
  axiosInstance.post<IPutLivesParams>('/lives') as any as Promise<IPutLivesParams>

export const deleteLivesNumberSync = (token: string) => {
  const xhr = new XMLHttpRequest();
  xhr.open('DELETE', `${process.env.REACT_APP_API_SERVER}/lives`, false);
  xhr.setRequestHeader('Content-type', 'application/json; charset=utf-8');
  xhr.setRequestHeader('X-Session-Token', token);
  xhr.send();
}

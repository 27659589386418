export const GET_BOOSTERS_REQUEST = 'GET_BOOSTERS_REQUEST';
export const GET_BOOSTERS_SUCCESS = 'GET_BOOSTERS_SUCCESS';
export const GET_BOOSTERS_FAILURE = 'GET_BOOSTERS_FAILURE';
export const PUT_BOOSTERS_REQUEST = 'PUT_BOOSTERS_REQUEST';
export const PUT_BOOSTERS_SUCCESS = 'PUT_BOOSTERS_SUCCESS';
export const PUT_BOOSTERS_FAILURE = 'PUT_BOOSTERS_FAILURE';

export const RECEIVED_BOOSTERS = 'RECEIVED_BOOSTERS';
export const SET_BOOSTERS = 'SET_BOOSTERS';
export const RESET_BOOSTERS = 'RESET_BOOSTERS';

export const BOOSTER_HINT_INCREMENT = 'BOOSTER_HINT_INCREMENT';
export const BOOSTER_HINT_DECREMENT = 'BOOSTER_HINT_DECREMENT';
export const BOOSTER_MOVE_BACK_INCREMENT = 'BOOSTER_MOVE_BACK_INCREMENT';
export const BOOSTER_MOVE_BACK_DECREMENT = 'BOOSTER_MOVE_BACK_DECREMENT';

export const SET_BOOSTER_HINT_COORDS = 'SET_BOOSTER_HINT_COORDS';
export const SET_BOOSTER_MOVE_BACK_COORDS = 'SET_BOOSTER_MOVE_BACK_COORDS';

// actions for boosters component
export const BOOSTER_HINT_CLICK_REQUIRE = 'BOOSTER_HINT_CLICK_REQUIRE';
export const BOOSTER_MOVE_BACK_CLICK_REQUIRE = 'BOOSTER_MOVE_BACK_CLICK_REQUIRE';
export const SET_INFINITE_BOOSTER_HINT = 'SET_INFINITE_BOOSTER_HINT';
export const SET_INFINITE_BOOSTER_MOVE_BACK = 'SET_INFINITE_BOOSTER_MOVE_BACK';

import isNil from 'lodash/isNil';
import {
  minToMs
} from 'shared/helpers/lives';
import {
  GET_LIVES_REQUEST,
  GET_LIVES_SUCCESS,
  GET_LIVES_FAILURE,
  RESET_LIVES_NUMBER,
  SET_NEW_LIFE_TIMER,
  CLEAR_NEW_LIFE_TIMER,
} from 'redux/actionTypes';

const initialState = {
  livesNumber: 5,
  newLifeTimer: null, // timestamp (ms) when timer started (not when it's expected to be finished)
  maxLivesNumber: 5,
  infiniteLife: false,
  lifeRestoreMinutes: '20:00',
  // api requests
  isGetLivesInProcess: false,
  isGetLivesError: false,
  isPutLivesInProcess: false,
  isPutLivesError: false,
};

export default function (state = initialState, action) {
  switch (action.type) {

    case GET_LIVES_REQUEST: {
      return {
        ...state,
        isGetLivesInProcess: true,
        isGetLivesError: false,
      };
    }
    case GET_LIVES_SUCCESS: {
      if (action.lives) {
        let {
          newLifeTimer,
          maxLivesNumber,
          infiniteLife,
          lifeRestoreMinutes,
          livesNumber
        } = action.lives;

        maxLivesNumber = isNil(maxLivesNumber) ? initialState.maxLivesNumber : maxLivesNumber;
        livesNumber = isNil(livesNumber) ? 0 : livesNumber;
        lifeRestoreMinutes = isNil(lifeRestoreMinutes) ? initialState.lifeRestoreMinutes : lifeRestoreMinutes;

        return {
          ...state,
          livesNumber,
          maxLivesNumber,
          newLifeTimer,
          infiniteLife,
          lifeRestoreMinutes,
          isGetLivesInProcess: false,
          isGetLivesError: false,
        };
      }
      return {
        ...state,
        livesNumber: state.maxLivesNumber,
        isGetLivesInProcess: false,
        isGetLivesError: false,
      };
    }
    case GET_LIVES_FAILURE: {
      return {
        ...state,
        isGetLivesInProcess: false,
        isGetLivesError: true,
      };
    }

    case RESET_LIVES_NUMBER: {
      return {
        ...state,
        livesNumber: state.maxLivesNumber,
      }
    }

    case SET_NEW_LIFE_TIMER: {
      const isUpdate = !state.newLifeTimer ? true : state.newLifeTimer + minToMs(state.lifeRestoreMinutes) < Date.now();
      if (isUpdate) {
        return {
          ...state,
          newLifeTimer: Date.now(),
        }
      }
      return state;
    }
    case CLEAR_NEW_LIFE_TIMER: {
      return {
        ...state,
        newLifeTimer: null,
      }
    }
    default:
      return state;
  }
}

export const CLEAR_PROGRESS_REQUEST = 'CLEAR_PROGRESS_REQUEST';
export const CLEAR_PROGRESS_SUCCESS = 'CLEAR_PROGRESS_SUCCESS';
export const CLEAR_PROGRESS_FAILURE = 'CLEAR_PROGRESS_FAILURE';

export const GET_WORLDS_PROGRESS_SUCCESS = 'GET_WORLDS_PROGRESS_SUCCESS';
export const GET_WORLDS_PROGRESS_FAILURE = 'GET_WORLDS_PROGRESS_FAILURE';
export const RESET_WORLDS_PROGRESS = 'RESET_WORLDS_PROGRESS';

export const UPDATE_LEVEL_PROGRESS_SUCCESS = 'UPDATE_LEVEL_PROGRESS_SUCCESS';
export const UPDATE_LEVEL_PROGRESS_FAILURE = 'UPDATE_LEVEL_PROGRESS_FAILURE';

export const CALC_ALL_ACHIEVED_STARS = 'CALC_ALL_ACHIEVED_STARS';

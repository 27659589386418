import {
  SET_GAME, RESET_GAME,
  CLEAR_STEPS_SPENT, SET_STEPS_SPENT, SET_LEVEL_HASH,
  SET_MAX_STARS, SET_MAX_MOVES_COUNT, SET_BEST_MOVES_COUNT,
  SET_INFINITE_STEPS,
  COMPLETE_LEVEL_REQUEST, COMPLETE_LEVEL_SUCCESS, COMPLETE_LEVEL_FAILURE,
  SET_GAME_PASSED, SET_GAME_LOST, SET_MOVES_RUN_OUT, SET_CHEAT_STARS,
  SET_BEST_VERTEX_COORDS, SET_GRAPH_COORDS,
  SET_STEPS_LEFT, SET_NOT_BEST_VERTEX_DATA,
  BEST_VERTEX_CLICK_REQUIRE, NOT_BEST_VERTEX_CLICK_REQUIRE, SHOW_HINT_REQUIRE,
  UPDATE_BEST_VERTEX_COORD_REQUIRE, UPDATE_GRAPH_COORD_REQUIRE,
  UPDATE_STEPS_LEFT_REQUIRE, UPDATE_NOT_BEST_VERTEX_DATA_REQUIRE,
} from '../../redux/actionTypes';


/* SYNC ACTION CREATORS */

export const setGame = (
  worldId: any, packId: any, levelId: any, levelHash: any, maxMovesCount: any, bestMovesCount: any, maxStars: any,
  ) => ({
  type: SET_GAME,
  worldId,
  packId,
  levelId,
  levelHash,
  maxMovesCount,
  bestMovesCount,
  maxStars,
});

export const resetGame = () => ({
  type: RESET_GAME,
});

export const clearStepsSpent = () => ({
  type: CLEAR_STEPS_SPENT,
});

export const setStepsSpent = (stepsSpent: any) => ({
  type: SET_STEPS_SPENT,
  stepsSpent,
});

export const setLevelHash = (levelHash: any) => ({
  type: SET_LEVEL_HASH,
  levelHash,
});

export const setMaxStars = (maxStars: any) => ({
  type: SET_MAX_STARS,
  maxStars,
});

export const setMaxMovesCount = (maxMovesCount: any) => ({
  type: SET_MAX_MOVES_COUNT,
  maxMovesCount,
});

export const setBestMovesCount = (bestMovesCount: any) => ({
  type: SET_BEST_MOVES_COUNT,
  bestMovesCount,
});

export const setInfiniteSteps = (infiniteSteps: boolean) => ({
  type: SET_INFINITE_STEPS,
  infiniteSteps,
});

export const completeLevelRequest = () => ({
  type: COMPLETE_LEVEL_REQUEST,
});

export const completeLevelSuccess = () => ({
  type: COMPLETE_LEVEL_SUCCESS,
});

export const completeLevelFailure = () => ({
  type: COMPLETE_LEVEL_FAILURE,
});

export const setGamePassed = (passed: any) => ({
  type: SET_GAME_PASSED,
  passed,
});

export const setGameLost = (lost: any) => ({
  type: SET_GAME_LOST,
  lost,
});

export const setMovesRunOut = (movesRunOut: any) => ({
  type: SET_MOVES_RUN_OUT,
  movesRunOut,
});

export const setCheatStars = (cheatStars: any) => ({
  type: SET_CHEAT_STARS,
  cheatStars,
});

export const setBestVertexCoords = (x: any, y: any, r: any) => ({
  type: SET_BEST_VERTEX_COORDS,
  x,
  y,
  r,
});

export const setGraphCoords = (x: any, y: any, r: any) => ({
  type: SET_GRAPH_COORDS,
  x,
  y,
  r,
});

export const setStepsLeft = (stepsLeft: any) => ({
  type: SET_STEPS_LEFT,
  stepsLeft,
});

export const setNotBestVertexData = (index: any, x: any, y: any, r: any) => ({
  type: SET_NOT_BEST_VERTEX_DATA,
  index,
  x,
  y,
  r,
});

export const bestVertexClickRequire = (require: boolean) => ({
  type: BEST_VERTEX_CLICK_REQUIRE,
  require,
});

export const notBestVertexClickRequire = (require: boolean) => ({
  type: NOT_BEST_VERTEX_CLICK_REQUIRE,
  require,
});

export const showHintRequire = (require: boolean) => ({
  type: SHOW_HINT_REQUIRE,
  require,
});

export const updateBestVertexCoordRequire = (require: boolean) => ({
  type: UPDATE_BEST_VERTEX_COORD_REQUIRE,
  require,
});

export const updateGraphCoordRequire = (require: boolean) => ({
  type: UPDATE_GRAPH_COORD_REQUIRE,
  require,
});

export const updateStepsLeftRequire = (require: boolean) => ({
  type: UPDATE_STEPS_LEFT_REQUIRE,
  require,
});

export const updateNotBestVertexDataRequire = (require: boolean) => ({
  type: UPDATE_NOT_BEST_VERTEX_DATA_REQUIRE,
  require,
});

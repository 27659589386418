import { createSelector } from 'reselect';
import get from 'lodash/get';
import values from 'lodash/values';

import { getPropsWorldID } from '../../redux/selectors/worlds';
import { WorldProgress, Level, Pack } from '../../shared/api/worldsAPI';
import { WorldsEntities } from '../../shared/schemas/worlds';
import {
  ProgressEntities,
  WorldsProgressEntity
} from '../../shared/schemas/progress';
import {
  createPackEntityId,
  createLevelEntityId
} from '../../shared/helpers/schemas';

// reselect cached selectors


export type PackProgress = any;


export const getAchievedStarsInWorldSelector = createSelector(
  [getPropsWorldID, (state, props) => state.progress.achievedStars],
  (worldId, achievedStars) => getAchievedStarsInWorld(worldId, achievedStars)
);

// other selectors

export const getAchievedStarsInWorld = (worldId: any, achievedStars: any) => {
  return get(achievedStars, `${worldId}.stars`, 0);
};

export const getAchievedStarsInPack = (
  worldId: any,
  packId: any,
  achievedStars: any
) => {
  return get(achievedStars, `${worldId}.packs.${packId}`, 0);
};

export const getAllAchievedStars = (achievedStars: any) => {
  return get(achievedStars, 'all', 0);
};

export const getWorldProgressById = (
  worldId: number,
  progressEntities: ProgressEntities
): WorldsProgressEntity => {
  return get(progressEntities, `worlds.${worldId}`);
};

export const getPackLevelsProgress = (
  worldId: number,
  packId: number,
  progressEntities: ProgressEntities
): PackProgress | undefined => {
  const packProgress: any = getPackProgress(worldId, packId, progressEntities as any);
  if (!packProgress) return undefined;
  return packProgress.levels.map((entityLevelId: any) =>
    get(progressEntities, `levels.${entityLevelId}`)
  );
};

export const getLevelProgress = (
  worldId: number,
  packId: number,
  levelId: number,
  entities: WorldsEntities
): Level => {
  return get(
    entities,
    `levels.${createLevelEntityId(worldId, packId, levelId)}`
  ) as any;
};

export const getPackProgress = (
  worldId: number,
  packId: number,
  entities: WorldsEntities
): Pack => {
  return get(entities, `packs.${createPackEntityId(worldId, packId)}`);
};

export const getProgressArray = (
  entities: WorldsEntities
): Array<WorldProgress> => {
  return values(get(entities, 'worlds'));
};

/*
  @value cWhite from '../../styles/vars.css';

.EmptyAdv {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.75);
  z-index: 200;
}

.EmptyAdvVideo {
  position: fixed;
  top: 50%;
  left: 50%;
  width: 640px;
  height: 360px;
  transform: translate(-50%,-50%);
  background-color: black;
}
*/


const divWrap = document.createElement('div');
const divVideoBlock = document.createElement('div');
divWrap.style.cssText = `
  width: 100vw; \
  height: 100vh; \
  position: fixed; \
  top: 0; \
  left: 0; \
  background-color: rgba(0, 0, 0, 0.75); \
  z-index: 200; \
`;
divVideoBlock.style.cssText = `
  position: fixed; \
  top: 50%; \
  left: 50%; \
  width: 640px; \
  height: 360px; \
  transform: translate(-50%,-50%); \
  background-color: black; \
`;
divWrap.appendChild(divVideoBlock);

export default divWrap;

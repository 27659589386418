import produce from 'immer';
import reduce from 'lodash/reduce';
import set from 'lodash/set';
import setWith from 'lodash/setWith';
import isNil from 'lodash/isNil';

import {
  CLEAR_PROGRESS_REQUEST, CLEAR_PROGRESS_SUCCESS, CLEAR_PROGRESS_FAILURE,
  GET_WORLDS_PROGRESS_SUCCESS, GET_WORLDS_PROGRESS_FAILURE, RESET_WORLDS_PROGRESS, UPDATE_LEVEL_PROGRESS_SUCCESS, UPDATE_LEVEL_PROGRESS_FAILURE, CALC_ALL_ACHIEVED_STARS,
} from 'redux/actionTypes';
import {
  createPackProgress, createLevelProgress, calcAchievedStarsInPack
} from 'shared/helpers/progress';
import { createPackEntityId, createLevelEntityId } from 'shared/helpers/schemas';
import {
  getPackProgress, getLevelProgress, getProgressArray, getWorldProgressById,
} from 'redux/selectors/progress';
import { getWorldPacks } from 'redux/selectors/worlds';


const initialState = {
  isClearProgressInProcess: false,
  isClearProgressError: false,
  isGetWorldsProgressError: false,
  isGetWorldsProgressSuccess: false,
  isUpdateLevelProgressError: false,
  // Default null, after data fetching, it must become an object
  // Contains normalized data! See shared/schemas/progress
  progress: null,
  isAchievedStarsCalculated: false, // used in App.js on init (do not render page until it has all progress data)
  achievedStars: {},
};

export default produce((draftState, action) => {
  switch (action.type) {
    case CLEAR_PROGRESS_REQUEST: {
      draftState.isClearProgressInProcess = true;
      draftState.isClearProgressError = false;
      break;
    }
    case CLEAR_PROGRESS_SUCCESS: {
      draftState.isClearProgressInProcess = false;
      draftState.isClearProgressError = false;
      draftState.achievedStars = {};
      break;
    }
    case CLEAR_PROGRESS_FAILURE: {
      draftState.isClearProgressInProcess = false;
      draftState.isClearProgressError = true;
      break;
    }
    case UPDATE_LEVEL_PROGRESS_SUCCESS: {
      draftState.isUpdateLevelProgressError = false;
      if (!draftState.progress) draftState.progress = {};
      const packEntityId = createPackEntityId(action.worldId, action.packId);
      const levelEntityId = createLevelEntityId(action.worldId, action.packId, action.levelId);
      // try to find level's world progress in the state
      let worldProgress = getWorldProgressById(action.worldId, draftState.progress);
      if (!worldProgress) {
        // if world progress doesn't exist, then create it along with pack progress structure
        worldProgress = { worldId: action.worldId, packs: [packEntityId] };
        set(draftState.progress, `worlds.${action.worldId}`, worldProgress);
      }
      // try to find pack progress
      let packProgress = getPackProgress(action.worldId, action.packId, draftState.progress);
      if (!packProgress) {
        // if pack progress doesn't exist, then create it
        packProgress = createPackProgress(action.worldId, action.packId, []);
        set(draftState.progress, `packs.${packEntityId}`, packProgress);
      }
      // get level's progress in the progress entities
      let currentLevelProgress = getLevelProgress(action.worldId, action.packId, action.levelId, draftState.progress);
      // create new progress for the level according on data from action
      const updatedLevelProgress = createLevelProgress(action.levelId, action.hash, action.stepsSpent, action.completed, action.addMovesAttempts);
      if (!currentLevelProgress) {
        // if pack doesn't have this level yet, then push its entityId to the pack's progress array
        packProgress.levels.push(levelEntityId);
      }
      // update level's data
      set(draftState.progress, `levels.${levelEntityId}`, updatedLevelProgress);
      break;
    }
    case UPDATE_LEVEL_PROGRESS_FAILURE: {
      draftState.isUpdateLevelProgressError = true;
      break;
    }
    case GET_WORLDS_PROGRESS_SUCCESS: {
      draftState.isGetWorldsProgressError = false;
      draftState.isGetWorldsProgressSuccess = true;
      draftState.progress = action.progress ? action.progress : {};
      break;
    }
    case GET_WORLDS_PROGRESS_FAILURE: {
      draftState.isGetWorldsProgressError = true;
      draftState.isGetWorldsProgressSuccess = false;
      break;
    }
    case RESET_WORLDS_PROGRESS: {
      draftState.progress = {};
      break;
    }

    /*
      * Achieved stars calculates once and recalculates everytime after passing a game
      * Structure:
      * {
      *   worldId: {
      *     stars: number // stars number in the world
      *     packs: {
      *       packId: number // stars number in the pack
      *     }
      *   }
      *   all: number // stars number in all worlds
      * }
     */
    case CALC_ALL_ACHIEVED_STARS: {
      draftState.isAchievedStarsCalculated = true;
      draftState.achievedStars = reduce(getProgressArray(action.worldsData), (result, world) => {
        const packs = getWorldPacks(world.Id, action.worldsData);
        if (!packs) {
          // if world doesn't have packs, then don't calculate stars
          set(result, `${world.Id}.stars`, 0);
          return result;
        };

        const achievedStarsInWorld = packs.reduce((worldResult, pack) => {
          const achievedStarsInPack = calcAchievedStarsInPack(world.Id, pack.Id, pack.MaxStars, action.worldsData, draftState.progress);
          // set achieved stars in pack
          setWith(result, `${world.Id}.packs.${pack.Id}`, achievedStarsInPack, Object);
          return worldResult + achievedStarsInPack;
        }, 0);
        set(result, `${world.Id}.stars`, achievedStarsInWorld);
        // calculate stars in all worlds
        let starsInAllWorlds;
        if (isNil(result.all)) {
          starsInAllWorlds = achievedStarsInWorld;
        } else {
          starsInAllWorlds = result.all + achievedStarsInWorld;
        }
        set(result, 'all', starsInAllWorlds);
        return result;
      }, {});
      break;
    }
    default: {
      break;
    }
  }
}, initialState);

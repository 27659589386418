import get from 'lodash/get';
import { GET_ADS_REQUEST, GET_ADS_SUCCESS, GET_ADS_FAILURE } from 'redux/actionTypes';


const initialState = {
  isGetAdsInProcess: false,
  isGetAdsError: false,
  androidOkAppSuggestDesktopIconTimeoutMinutes: 0,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_ADS_REQUEST: {
      return {
        ...state,
        isGetAdsInProcess: true,
      }
    }
    case GET_ADS_SUCCESS: {
      return {
        ...state,
        androidOkAppSuggestDesktopIconTimeoutMinutes: get(action.ads, 'AndroidOkAppSuggestDesktopIconTimeoutMinutes', 1),
        isGetAdsInProcess: false,
        isGetAdsError: false,
      };
    }
    case GET_ADS_FAILURE: {
      return {
        ...state,
        isGetAdsInProcess: false,
        isGetAdsError: true,
      };
    }
    default:
      return {...state};
  }
}

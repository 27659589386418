import PropTypes from 'prop-types';


export const propTypes = {
  isBlocked: PropTypes.bool,
  title: PropTypes.string,
  starsAchieved: PropTypes.number,
  maxStarsCount: PropTypes.number,
  starsRequired: PropTypes.number,
  onClick: PropTypes.func,
};

export const defaultProps = {
  isBlocked: false,
  title: '',
  starsAchieved: 0,
  maxStarsCount: 0,
  starsRequired: 0,
  onClick: () => {},
};
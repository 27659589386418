import axiosInstance from 'shared/api/axiosInstance';

export const fetchSettings = () =>
  axiosInstance.get<{
    music: boolean,
    sounds: boolean,
    language: string
  }>('/settings')

export const putSettings = (settingsData: {
  music: boolean,
  sounds: boolean,
  language: string
}) => {
  return axiosInstance.put('/settings', settingsData);
};

export const fetchSettingsTutorial = () =>
  axiosInstance.get<{ tutorial: string[] }>('/settings/tutorial')
  //.then(p => ({ ...p, tutorial: [ '100-100-1','100-100-2','100-100-3']}))

export const putSettingsTutorial = (items: string[]) =>
  axiosInstance.put<{ tutorial: string[] }>('/settings/tutorial', { items })

import * as ls from 'store2';
import uuidv4 from 'uuid/v4';


import { UUID_STORAGE_NAME } from 'shared/constants';

export const getUUID = (): string => {
	return ls.get(UUID_STORAGE_NAME);
};
  
export const createUUID = (): string => {
	const uuid = getUUID();
	if (uuid) return uuid;

	const newUuid = uuidv4();
	ls.set(UUID_STORAGE_NAME, newUuid);
	return newUuid;
};
import { SET_SECOND_SESSION } from 'redux/actionTypes';

import { getSecondSession } from 'shared/api/secondSessionAPI';

export const setSecondSession = isSecondSession => ({
  type: SET_SECOND_SESSION,
	isSecondSession,
});

export const checkSecondSession = () => {
  return (dispatch) => {
  	// async
  	return getSecondSession()
		.then((isSecondSession) => {
			dispatch(setSecondSession(isSecondSession));
  	});
  };
};
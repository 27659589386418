import forEach from 'lodash/forEach';
import keys from 'lodash/keys';


// default lng for system
export const defaultLng = 'en';
export const okDefaultLng = 'ru';

/*
  Languages, for which we show Russian:
    Ukrainian, Kazakh, Kazakh (Kazakhstan), Belarusian, Belarusian (Belarus),
    Uzbek, Uzbek (Uzbekistan), Romanian, Romanian (Moldavia), Russian (Moldavia), Moldavian, Turkmen, Tatar, Tajik, Kyrgyz,
    Georgian, Armenian, Azerbaijani

  For others we show English
*/
export const languagesTrans = {
  'ru': [
    'ru', 'ru-ru', 'uk', 'uk-ua', 'kk', 'kk-kz', 'be', 'be-by', 'uz', 'uz-uz', 'ro',
    'mo', 'ro-mo', 'ru-mo', 'tk', 'tt', 'tg', 'ky', 'ka', 'hy', 'az',
  ],
};

/*
  This function returns language for application based on detected language
*/
export const appLngForDetectedLng = (detectedLng) => {
  let appLng = null;
  forEach(keys(languagesTrans), lng => {
    if (languagesTrans[lng].indexOf(detectedLng) >= 0) {
      appLng = lng;
      return;
    }
  });
  return appLng ? appLng : defaultLng;
};
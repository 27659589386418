import { SET_ADBLOCK_DETECTED } from '../actionTypes';


const initialState = {
  adBlockDetected: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_ADBLOCK_DETECTED: {
      return {
        ...state,
        adBlockDetected: action.adBlockDetected,
      }
    }
    default:
      return state;
  }
}

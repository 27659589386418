import {
  GET_BOOSTERS_REQUEST, GET_BOOSTERS_SUCCESS, GET_BOOSTERS_FAILURE,
  PUT_BOOSTERS_REQUEST, PUT_BOOSTERS_SUCCESS, PUT_BOOSTERS_FAILURE,
  RESET_BOOSTERS,
  BOOSTER_HINT_INCREMENT, BOOSTER_HINT_DECREMENT,
  BOOSTER_MOVE_BACK_INCREMENT, BOOSTER_MOVE_BACK_DECREMENT,
  SET_BOOSTER_HINT_COORDS, SET_BOOSTER_MOVE_BACK_COORDS,
  BOOSTER_MOVE_BACK_CLICK_REQUIRE, BOOSTER_HINT_CLICK_REQUIRE,
  SET_INFINITE_BOOSTER_HINT, SET_INFINITE_BOOSTER_MOVE_BACK,
} from 'redux/actionTypes';
import * as boostersAPI from 'shared/api/boostersAPI';


/* SYNC ACTION CREATORS */

export const getBoostersRequest = () => ({
  type: GET_BOOSTERS_REQUEST,
});

export const getBoostersSuccess = boosters => ({
  type: GET_BOOSTERS_SUCCESS,
  boosters,
});

export const getBoostersFailure = () => ({
  type: GET_BOOSTERS_FAILURE,
});

export const putBoostersRequest = () => ({
  type: PUT_BOOSTERS_REQUEST,
});

export const putBoostersSuccess = () => ({
  type: PUT_BOOSTERS_SUCCESS,
});

export const putBoostersFailure = () => ({
  type: PUT_BOOSTERS_FAILURE,
});

export const boosterHintInc = () => ({
  type: BOOSTER_HINT_INCREMENT,
});

export const boosterHintDec = () => ({
  type: BOOSTER_HINT_DECREMENT,
});

export const boosterMoveBackInc = () => ({
  type: BOOSTER_MOVE_BACK_INCREMENT,
});

export const boosterMoveBackDec = () => ({
  type: BOOSTER_MOVE_BACK_DECREMENT,
});

export const resetBoosters = () => ({
  type: RESET_BOOSTERS,
});

export const setBoosterHintCoords = (x, y, r) => ({
  type: SET_BOOSTER_HINT_COORDS,
  x,
  y,
  r,
});

export const setBoosterMoveBackCoords = (x, y, r) => ({
  type: SET_BOOSTER_MOVE_BACK_COORDS,
  x,
  y,
  r,
});

export const boosterHintClickRequire = require => ({
  type: BOOSTER_HINT_CLICK_REQUIRE,
  require,
});

export const boosterMoveBackClickRequire = require => ({
  type: BOOSTER_MOVE_BACK_CLICK_REQUIRE,
  require,
});

export const setInfiniteBoosterHint = infinite => ({
  type: SET_INFINITE_BOOSTER_HINT,
  infinite,
});

export const setInfiniteBoosterMoveBack = infinite => ({
  type: SET_INFINITE_BOOSTER_MOVE_BACK,
  infinite,
});

/* ASYNC ACTION CREATORS */

export const getBoosters = () => {
  return function (dispatch) {
    dispatch(getBoostersRequest());

    return boostersAPI.fetchBoosters()
      .then(boosters => {
        dispatch(getBoostersSuccess(boosters));
      }, () => {
        dispatch(getBoostersFailure());
      });
  };
};

export function putBoosters(boosters) {
  return function (dispatch) {
    dispatch(putBoostersRequest());

    return boostersAPI.putBoosters(boosters)
      .then(() => {
        dispatch(putBoostersSuccess());
      }, () => {
        dispatch(putBoostersFailure());
      });
  };
};

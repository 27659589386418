import get from 'lodash/get';
import size from 'lodash/size';

import i18n from '../../shared/i18n';
import config from '../../shared/config';

export const getSuccessShareText = (movesCount: number, level: number, packName: string): string => {
	const suffix = i18n.services.pluralResolver.getSuffix(i18n.language, +movesCount);
	const movePluralized = i18n.t(`playgroundPage.move${suffix}`);
	return i18n.t('playgroundPage.winSharing', {level, movesCount, packName, movePluralized});
};

/**
 * Warning: Порядок элементов в массиве attachmentJSON имеет значение, так они будут отображаться в шеринге на странице
 */
export const createAttachmentJSON = (attachment: any): any => {
	const attachmentJSON = {
    'media': [] as any[]
  };

  const text = get(attachment, 'text', '');
  if (text) {
    attachmentJSON.media.push({
      'type': 'text',
      'text': text,
    })
  }

  const app = get(attachment, 'app');
  if (size(app)) {
    attachmentJSON.media.push({
      "type": "app",
      'text': get(attachment, 'app.imageText', ''),
      'images': [{
        "url": get(attachment, 'app.imageURL', `${config.ok.appServerURL}/assets/sharing/opg-sharing.png`),
        "title": get(attachment, 'app.imageTitle', '')
      }]
    })
  }

  attachmentJSON.media.push({
    'type': 'app-ref',
    'appId': `${config.ok.appID}`
  });
	return attachmentJSON;
}

/**
 * POST via FAPI.UI.postMediatopic
 * Attachment config docs https://apiok.ru/dev/methods/rest/mediatopic/mediatopic.post#attachment
 */
export const createOKSharing = (attachmentConfig?: any): void => {
  if (!(window as any).FAPI) {
    console.warn('No FAPI for OK');
    return;
  }
  const attachmentJSON = createAttachmentJSON(attachmentConfig);
  (window as any).FAPI.UI.postMediatopic(attachmentJSON, false);
}

export const openShareDialog = (linkToShare: string): void => {
  window.open(linkToShare, '', 'toolbar=0,status=0,width=626,height=436');
}

import reduce from 'lodash/reduce';

import { GET_WORLDS_SUCCESS, GET_WORLDS_FAILURE } from 'redux/actionTypes';
import { calcMaxStarsInWorld } from 'shared/helpers/progress';
import { getWorldPacks } from 'redux/selectors/worlds';

const initialState = {
  // Contains normalized data! See shared/schemas/worlds
  entities: null,
  worldsMaxStars: {}, // {[worldId]: number}, stars maximum that is achievable in the world
  isGetWorldsError: false,
  isGetWorldsSuccess: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_WORLDS_SUCCESS: {
      return {
        ...state,
        isGetWorldsError: false,
        isGetWorldsSuccess: true,
        entities: action.worlds || {},
        worldsMaxStars: reduce(action.worlds.worlds, (result, world) => {
          result[world.Id] = calcMaxStarsInWorld(getWorldPacks(world.Id, action.worlds));
          return result;
        }, {}),
      };
    }
    case GET_WORLDS_FAILURE: {
      return {
        ...state,
        isGetWorldsError: true,
        isGetWorldsSuccess: false,
        entities: {},
        worldsMaxStars: {},
      };
    }
    default:
      return state;
  }
};

import { schema } from 'normalizr';

import { Level, Pack, World } from '../../shared/api/worldsAPI';
import { createPackEntityId, createLevelEntityId } from '../../shared/helpers/schemas';

// Normalized Data interfaces

export type WorldsEntity = Record<string, World>;

export type PacksEntity = Record<string, Pack>;


export type LevelsEntity = Record<string, Level>;


/**
 * Structure Example:
  entities: {
		worlds: {
			1: {
				Id: 1,
				Locales: {en: string, ru: string},
				Name: string,
				Packs: ['1-1', '1-2']
			},
			2: {...}
		},
		packs: {
			'1-1': {
				Id: number,
				Name: string,
				Locales: {en: string, ru: string}
				StarsRequired: number
				Levels: ['1-1-1', '1-1-2', '1-1-3']
			},
			'1-2': {...}
		},
		levels: {
			'1-1-1': Level,
			'1-1-2': Level,
			'1-1-3': Level,
		}
  }
 */
export interface WorldsEntities {
	worlds: WorldsEntity;
	packs: PacksEntity;
	levels: LevelsEntity;
};

const LevelSchema = new schema.Entity('levels',
	{},
	{
		idAttribute: (value, parent, key) => createLevelEntityId(parent.WorldId, parent.Id, value.Id)
	}
);

const PackSchema = new schema.Entity('packs', {
	Levels: [LevelSchema]
}, {
	processStrategy: (value, parent, key) => ({ ...value, WorldId: parent.Id }),
	idAttribute: (value, parent, key) => createPackEntityId(parent.Id, value.Id),
});

export const WorldSchema = new schema.Entity('worlds', {
	Packs: [PackSchema]
}, { idAttribute: 'Id' });

export const WorldListSchema = [WorldSchema];

import { combineReducers } from 'redux';
import boosters from './boosters';
import game from './game';
import user from './user';
import cheats from './cheats';
import ads from './ads';
import purchases from './purchases';
import lives from './lives';
import adBlock from './adBlock';
import secondSession from './secondSession';
import header from './header';
import settings from './settings';
import worlds from './worlds';
import progress from './progress';
import tutorial from './tutorial';
import modals from './modals';
import hint from './hint';


export default combineReducers({
  boosters, game, user, cheats, ads, purchases,
  lives, adBlock, secondSession, header, settings,
  worlds, progress, tutorial, modals, hint,
});

import React from 'react';
import { connect } from 'react-redux';
import { Switch, Route, Redirect } from 'react-router-dom';
import { withRouter, matchPath } from 'react-router';
import {
  CSSTransition,
  TransitionGroup,
} from 'react-transition-group';
import get from 'lodash/get';
import defer from 'lodash/defer';
import some from 'lodash/some';
import upperCase from 'lodash/upperCase';
import { setAutoFreeze } from 'immer';
import 'fuckadblock';
import classNames from 'classnames';
import loadable from '@loadable/component';
import Div100vh from 'react-div-100vh';

import i18n from 'shared/i18n';
import config from 'shared/config';

// components
import Header from 'containers/Header';
import Footer from 'components/Footer';

import Preloader from 'shared/components/Preloader';
import OKAndroidManager from 'shared/containers/OKAndroidManager';
import PageWrap from 'shared/components/PageWrap';
import Tutorial from 'shared/containers/Tutorial';
import ModalManager from 'shared/containers/ModalManager';

// constants
import { SUCCESS, PAGE_ANIMATION_TIME } from 'shared/constants';

// helpers
import { detectTabVisibility, tabVisibility$ } from 'shared/helpers/tabVisibility';
import { getLastVisitedLevel } from 'shared/helpers/progress';
import { initAdBlockDetector } from 'shared/helpers/adBlockDetector';
import { setLang } from 'shared/helpers/locales';
import 'shared/helpers/arrayIncludesPolyfill';
import { getAnalyticsID, isAnalyticsEnabled, sendRouteMetric } from 'shared/helpers/analytics';

// modules
import AppSoundManager from 'shared/modules/AppSoundManager';
import Auth from 'shared/modules/Auth';
import TutorialManager from 'shared/modules/TutorialManager';
import Advertizer from 'shared/adv/Advertizer';
import loadSDK from 'shared/sdk/index';

// redux
import { getSettings, setSettings, fetchSettingsTutorial } from 'redux/actions/settings';
import { getLives } from 'redux/actions/lives';
import { getBoosters } from 'redux/actions/boosters';
import { getProgress, calcAchievedStarsAsync } from 'redux/actions/progress';
import { getWorlds } from 'redux/actions/worlds';
import { checkSecondSession } from 'redux/actions/secondSession';
import { setInfiniteBoosterHint, setInfiniteBoosterMoveBack } from 'redux/actions/boosters';

import styles from './App.module.css';
import 'shared/styles/animation.css';

import LandingPage from 'containers/LandingPage';
import LevelsPage from 'containers/LevelsPage';
import WorldPage from 'containers/WorldPage';
import { createUUID } from 'shared/helpers/user';

// libs lazy loading
const ReactYMLoadable = loadable.lib(() => import('react-yandex-metrika'));

// pages lazy loading
const PlaygroundPage = loadable(() => import('containers/PlaygroundPage'));

// components lazy loading
const CheatPanel = loadable(() => import('shared/containers/CheatPanel'))

// Stub for testing
// Uncomment for testing android app emulation
// window.OKApp = {
//   suggestDesktopIcon: () => {
//     console.log('window.OKApp.suggestDesktopIcon() was called');
//   },
// };

const mapStateToProps = state => ({
  isAuth: state.user.isAuth,
  isDeveloper: state.user.developer,
  language: state.settings.language,
  progressFetched: state.progress.isGetWorldsProgressSuccess,
  isAchievedStarsCalculated: state.progress.isAchievedStarsCalculated,
  worldsFetched: state.worlds.isGetWorldsSuccess,
  isTutorialLoad: !!state.settings.tutorialProgress
});

const mapDispatchToProps = dispatch => ({
  fetchSettings: () => dispatch(getSettings()),
  fetchSettingsTutorial: () => dispatch(fetchSettingsTutorial()),
  getProgress: () => dispatch(getProgress()),
  calcAchievedStarsAsync: () => dispatch(calcAchievedStarsAsync()),
  getWorlds: () => dispatch(getWorlds()),
  setSettingsLanguage: language => dispatch(setSettings({ language })),
  fetchLives: () => dispatch(getLives()),
  fetchBoosters: () => dispatch(getBoosters()),
  checkSecondSession: () => dispatch(checkSecondSession()),
  setInfiniteBoosters: () => {
    dispatch(setInfiniteBoosterHint(true))
    dispatch(setInfiniteBoosterMoveBack(true))
  },
});

class App extends React.PureComponent {

  constructor(props) {
    super(props);
    // https://github.com/mweststrate/immer#auto-freezing
    setAutoFreeze(false);
    // set pages paths on which footer must be hidden
    this.hiddenFooterPaths = [
      { path: '/game/level/:worldId/:packId/:levelId', exact: true }
    ];
  }

  get isAndroidOkApp() {
    return (process.env.REACT_APP_SOCIAL_MEDIA === 'ok') && get(window, 'OKApp.suggestDesktopIcon');
  }

  /******************************************
  * COMPONENT HOOKS
  ******************************************/

  render() {
    const { location, progressFetched, worldsFetched, isAchievedStarsCalculated, isDeveloper } = this.props;
    // For animating route back different way add {routeBack: true} state to the history.push or to the <Link />
    const transitionClassName = get(location, 'state.routeBack') ? 'page-back' : 'page-forward';
    const hideFooter = some(this.hiddenFooterPaths, (item) => matchPath(location.pathname, item));

    // Show preloader until progress, worlds data are fetched and achievedStars are calculated
    // Thus all pages will have these data before rendering
    const isLoading = !(progressFetched && worldsFetched && isAchievedStarsCalculated);

    return (
      <React.Fragment>
        {isLoading ? (
          <div className={styles.PreloaderWrap}>
            <Preloader size='big' />
          </div>
        ) : (
          <Div100vh as="main" className={classNames({
            [styles.App]: true,
            [`App${upperCase(process.env.REACT_APP_SOCIAL_MEDIA)}`]: !!process.env.REACT_APP_SOCIAL_MEDIA
          })}>
            <Tutorial className={styles.Tutorial}></Tutorial>

            {/*  Header */}
            <Header className={styles.Header} />

            {/*  Page content */}
            <div className={styles.PageWrap}>
              <TransitionGroup
                // to apply dynamic animations accoridng on state value
                // https://github.com/reactjs/react-transition-group/issues/182#issuecomment-371652237
                childFactory={child => React.cloneElement(
                  child,
                  { classNames: transitionClassName }
                )} >
                <CSSTransition
                  key={location.key}
                  timeout={PAGE_ANIMATION_TIME}
                >
                  <Switch location={location}>
                    <Route exact path='/' render={(props) => (
                      <PageWrap><LandingPage {...props} /></PageWrap>
                    )} />
                    <Route path='/levels' render={() => {
                      return (<Redirect to='/' />);
                    }} />
                    <Route exact path='/game/:id' render={() => {
                      return (<Redirect to='/' />);
                    }} />
                    <Route exact path='/game' render={() => {
                      return (<Redirect to='/' />);
                    }} />
                    <Route exact path='/world/:worldId' component={(props) => (
                        <PageWrap><WorldPage {...props} /></PageWrap>
                    )} />
                    <Route exact path='/world/:worldId/pack/:packId' component={(props) => (
                        <PageWrap><LevelsPage  {...props} /></PageWrap>
                    )} />
                    <Route exact path='/game/level/:worldId/:packId/:levelId' component={(props) => (
                        <PageWrap><PlaygroundPage {...props} /></PageWrap>
                    )} />
                  </Switch>
                </CSSTransition>
              </TransitionGroup>
            </div>

            {/*  Footer */}
            <Footer className={classNames({
              [styles.FooterHidden]: hideFooter,
              [styles.Footer]: true,
              [styles.OK]: (process.env.REACT_APP_SOCIAL_MEDIA === 'ok')
            })}/>

            {/*  Purchase Manager */}
            <ModalManager/>

            {/*  Cheat Panel */}
            {isDeveloper && <CheatPanel fallback={<></>} className={styles.CheatPanel}></CheatPanel>}
            { this.isAndroidOkApp && <OKAndroidManager /> }
          </Div100vh>
        )}
        {isAnalyticsEnabled && (
          <ReactYMLoadable>
            {({ YMInitializer }) => (
              <YMInitializer accounts={[getAnalyticsID('YM')]}
                options={{
                  clickmap: true,
                  trackLinks: true,
                  accurateTrackBounce: true,
                  webvisor: true,
                }}
                version="2"/>
            )}
          </ReactYMLoadable>
        )}
      </React.Fragment>
    );
  }

  componentDidMount() {
    sendRouteMetric(get(this.props, 'history.location.pathname'));

    if (this.props.isAuth) {
      this.init();
    }

    if (process.env.REACT_APP_SOCIAL_MEDIA === 'ok') {
      this.initSdkOk();
    } else {
      Auth.authUser(createUUID());
    }

    if (process.env.REACT_APP_SHOW_ADV) {
      defer(() => {
        Advertizer.init();
      });
    }
    initAdBlockDetector();

    if (process.env.REACT_APP_INFINITE_BOOSTERS === 'true') {
      this.props.setInfiniteBoosters()
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.isAuth && !prevProps.isAuth) {
      this.init();

      // fetch worlds data and user progress after authorization
      Promise.all([this.props.getProgress(), this.props.getWorlds()])
      .then((result) => {
        // calculate and store achieved stars in worlds, packs
        this.props.calcAchievedStarsAsync();
      });
    }

    if (process.env.REACT_APP_TUTORIAL === 'true' && this.isAllowToRedirectActions(prevProps)) {
      // check tutorial only after worlds data is fetched - to get available level to redirect
      const levelToRedirect = TutorialManager.getLevelForRedirect(this.props.location.pathname);

      if (levelToRedirect) {
        this.props.history.push(`/game/level/${levelToRedirect.worldId}/${levelToRedirect.packId}/${levelToRedirect.levelId}`);
      }
    }

    if (prevProps.location !== this.props.location) {
      sendRouteMetric(get(this.props, 'location.pathname'), get(prevProps, 'location.pathname'));
    }
  }

  isAllowToRedirectActions(prevProps) {
    const { worldsFetched, isTutorialLoad } = this.props;
    if (worldsFetched && isTutorialLoad) {
      return !prevProps.worldsFetched || isTutorialLoad !== prevProps.isTutorialLoad;
    }
    return false;
  }

  /******************************************
  * COMPONENT METHODS
  ******************************************/

  init() {
    this.updateSettings();
    this.props.fetchLives();
    this.props.fetchBoosters();
    this.props.checkSecondSession();
  }

  updateSettings() {
    // fetch settings
    Promise.all([
      this.props.fetchSettings(),
      this.props.fetchSettingsTutorial()
    ]).then(() => {
        // add tab visibility event listeners
        detectTabVisibility();

        AppSoundManager.init(!!getLastVisitedLevel());

        // subscribe to tab visibility change
        tabVisibility$.subscribe((isTabVisible) => {
          if (isTabVisible) {
            AppSoundManager.startBackgroundMusic();
          } else {
            AppSoundManager.pauseBackgroundMusic();
          }
        });

        // init language
        if (this.props.language) {
          setLang(this.props.language);
        } else {
          this.props.setSettingsLanguage(i18n.language);
        }
      });
  }

  initSdkOk() {
    loadSDK().then(SdkOk =>
      SdkOk.default.init()
        .then(({ status, okParameters }) => {
          if (status === SUCCESS) {
            Auth.authUser(okParameters.logged_user_id);
          } else {
            return Promise.reject(new Error(`invalid status=${status}`));
          }
        })
    ).catch(() => {
      Auth.authUser(config.ok.logged_user_id_test);
    })

  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));

import axiosInstance from 'shared/api/axiosInstance';
import config from 'shared/config';

export interface IUserData {
	id: number;
	userId: number;
	logins: {
		today: number;
		total: number;
		daysInARow: number;
	}
	developer: boolean;
}

export interface IUserExistsData {
  id: number;
  uuid: string;
	developer: boolean;
}

export interface IUserCreateData extends IUserData {};

const secretApiToken = config.secretApiToken;
const uuidParamName = process.env.REACT_APP_SOCIAL_MEDIA === 'ok' ? 'okUuid' : 'uuid';
const appSource = (process.env.REACT_APP_SOCIAL_MEDIA as 'ok' | 'vk' | 'fb') || 'web';

export const checkUserExists = (uuid: string): Promise<IUserExistsData> => {
  const queryString = `${uuidParamName}=${uuid}&secretApiToken=${secretApiToken}`;
  return axiosInstance.get(`/users/${appSource}/exists?${queryString}`);
};

export const createUser = (uuid: string): Promise<IUserCreateData> => {
  return axiosInstance.post(`/users/${appSource}/`, {
    [uuidParamName]: uuid,
    secretApiToken: secretApiToken
  });
};

/*
  * Delete current user
  * By X-Session-Token
*/
export const deleteUser = (): Promise<any> => {
  const endpoint = `/users/${appSource}/current`;
  return axiosInstance.delete(endpoint);
};

export const authUser = (uuid: string): Promise<IUserData> => {
  return axiosInstance.post(`/authentications/${appSource}`, {
    [uuidParamName]: uuid,
    secretApiToken
  });
};

/*
  * Delete OK user
*/
export const deleteSession = (): Promise<any> => {
  const endpoint = `/authentications/${appSource}`;
  return axiosInstance.delete(endpoint);
};
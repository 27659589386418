import PropTypes from 'prop-types';
import { HINT_TEXT_BOTTOM } from 'shared/constants';


export const propTypes = {
  circleX: PropTypes.number, // x position of circle
  circleY: PropTypes.number, // y position of circle
  circleR: PropTypes.number, // radius of circle
  circleDelta: PropTypes.number, // delta to add to the radius of circle
  text: PropTypes.string,
  textPosition: PropTypes.string, // positioning of text
  btnText: PropTypes.string,
  skipBtnText: PropTypes.string,
  onBtnClick: PropTypes.func.isRequired,
  onSkipBtnClick: PropTypes.func.isRequired,
};

export const defaultProps = {
  circleX: 0,
  circleY: 0,
  circleR: 0,
  circleDelta: 0,
  text: 'Test text to check if hint is displayed correct',
  textPosition: HINT_TEXT_BOTTOM,
};
import {
  SET_USER_ID, CHECK_USER_REQUEST, CHECK_USER_SUCCESS, CHECK_USER_FAILURE,
  CREATE_USER_REQUEST, CREATE_USER_SUCCESS, CREATE_USER_FAILURE,
  AUTH_USER_REQUEST, AUTH_USER_SUCCESS, AUTH_USER_FAILURE,
  DELETE_USER_REQUEST, DELETE_USER_SUCCESS, DELETE_USER_FAILURE,
  DELETE_SESSION_REQUEST, DELETE_SESSION_SUCCESS, DELETE_SESSION_FAILURE
} from 'redux/actionTypes';


const initialState = {
  id: null,
  userId: null,
  okUuid: null,
  uuid: null,
  secretApiToken: null,
  token: null,
  isAuth: false,
  // requests statuses
  isCheckUserInProcess: false,
  isCheckUserError: false,
  isCreateUserInProcess: false,
  isCreateUserError: false,
  isAuthUserInProcess: false,
  isAuthUserError: false,
  isDeleteUserInProcess: false,
  isDeleteUserError: false,
  isDeleteSessionInProcess: false,
  isDeleteSessionError: false,
};

export default function (state = initialState, action) {
  switch (action.type) {

    // SET USER ID

    case SET_USER_ID: {
      return {
        ...state,
        uuid: action.uuid,
      }
    }

    // CHECK USER

    case CHECK_USER_REQUEST: {
      return {
        ...state,
        isCheckUserInProcess: true,
        isCheckUserError: false,
      }
    }
    case CHECK_USER_SUCCESS: {
      return {
        ...state,
        isCheckUserInProcess: false,
        isCheckUserError: false,
      };
    }
    case CHECK_USER_FAILURE: {
      return {
        ...state,
        isCheckUserInProcess: false,
        isCheckUserError: true,
      };
    }

    // CREATE USER

    case CREATE_USER_REQUEST: {
      return {
        ...state,
        isCreateUserInProcess: true,
        isCreateUserError: false,
      }
    }
    case CREATE_USER_SUCCESS: {
      return {
        ...state,
        isCreateUserInProcess: false,
        isCreateUserError: false,
      };
    }
    case CREATE_USER_FAILURE: {
      return {
        ...state,
        isCreateUserInProcess: false,
        isCreateUserError: true,
      };
    }

    // AUTH USER

    case AUTH_USER_REQUEST: {
      return {
        ...state,
        isAuthUserInProcess: true,
        isAuthUserError: false,
      }
    }
    case AUTH_USER_SUCCESS: {
      return {
        ...state,
        isAuth: true,
        id: action.user.id,
        userId: action.user.userId,
        token: action.user.authenticationToken,
        isAuthUserInProcess: false,
        isAuthUserError: false,
        developer: !!action.user.developer
      };
    }
    case AUTH_USER_FAILURE: {
      return {
        ...state,
        isAuth: false,
        isAuthUserInProcess: false,
        isAuthUserError: true,
      };
    }

    // DELETE USER

    case DELETE_USER_REQUEST: {
      return {
        ...state,
        isDeleteUserInProcess: true,
        isDeleteUserError: false,
      }
    }
    case DELETE_USER_SUCCESS: {
      return {
        ...state,
        id: initialState.id,
        token: initialState.token,
        userId: initialState.id,
        isAuth: false,
        isDeleteUserInProcess: false,
        isDeleteUserError: false,
      };
    }
    case DELETE_USER_FAILURE: {
      return {
        ...state,
        isDeleteUserInProcess: false,
        isDeleteUserError: true,
      };
    }

    // DELETE SESSION

    case DELETE_SESSION_REQUEST: {
      return {
        ...state,
        isDeleteSessionInProcess: true,
        isDeleteSessionError: false,
      }
    }
    case DELETE_SESSION_SUCCESS: {
      return {
        ...state,
        id: initialState.id,
        token: initialState.token,
        isAuth: false,
        isDeleteSessionInProcess: false,
        isDeleteSessionError: false,
      };
    }
    case DELETE_SESSION_FAILURE: {
      return {
        ...state,
        isDeleteSessionInProcess: false,
        isDeleteSessionError: true,
      };
    }
    default:
      return {...state};
  }
}

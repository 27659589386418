export interface ListItem {
	height: number;
	marginBottom?: number;
};

/**
 * To avoid list item crop inside scroll area
 * calculate scroll height, that can fit a number of items without cropping them
 */
export const calcCustomListHeight = (maxAvailableHeight: number, listItem: ListItem): number => {
	const fullHeight = listItem.marginBottom ? listItem.height + listItem.marginBottom : listItem.height;
	const visibleRowsCount = maxAvailableHeight/fullHeight;
	let maxHeightWithItems = Math.round(visibleRowsCount) * fullHeight;
	if (listItem.marginBottom) {
		// remove last row margin bottom
		maxHeightWithItems -= listItem.marginBottom;
	}
	if (maxHeightWithItems > maxAvailableHeight) {
		return Math.floor(visibleRowsCount) * fullHeight;
	}
	return maxHeightWithItems;
};

(window as any).calcCustomListHeight = calcCustomListHeight;

import { BehaviorSubject } from 'rxjs/BehaviorSubject';

const keys = {
  hidden: "visibilitychange",
  webkitHidden: "webkitvisibilitychange",
  mozHidden: "mozvisibilitychange",
  msHidden: "msvisibilitychange",
};

let stateKey, eventKey;
for (stateKey in keys) {
  if (stateKey in document) {
    eventKey = keys[stateKey];
    break;
  }
}

// To be sure that event listeners are being added only one time
let visibilityListenersAdded = false;

// iOS Safari 7.x - native support not working, polyfilled with pageshow/pagehide events
const isSafari7 = navigator.userAgent.match(/(iPad|iPhone|iPod touch);.*CPU.*OS 7_\d/i);

const tabVisibilitySubj = new BehaviorSubject(eventKey ? !document[stateKey] : true);
/**
 * Subscribe on tabVisibility$ observable for handling tab visibility change
 * Emit true if tab is active, false if inactive
 */
export const tabVisibility$ = tabVisibilitySubj.asObservable();

/**
  * Add EventListeners, detect if browser tab is active
  * Must be called once after application init
*/
export const detectTabVisibility = () => {
  if (visibilityListenersAdded) return;
  visibilityListenersAdded = true;
  if (isSafari7) {
    // Hack for iOS Safari 7.x
    // Safari reports that support Page Visibility API, but event 'visibilitychange' never happen
    document.addEventListener('pageshow', () => {
      tabVisibilitySubj.next(true);
    }, false);
    document.addEventListener('pagehide', () => {
      tabVisibilitySubj.next(false);
    }, false);
  } else if (eventKey) {
    // browsers supporting Page Visibility API
    document.addEventListener(eventKey, (event) => {
      if (!document[stateKey]) {
        // tab is active
        tabVisibilitySubj.next(true);
      } else {
        // tab is inactive
        tabVisibilitySubj.next(false);
      }
    });
  } else {
    // bind focus/blur event for browsers not supporting Page Visibility API
    document.addEventListener("focus", (event) => {
      // tab is active
      tabVisibilitySubj.next(true);
    }, false);

    document.addEventListener("blur", (event) => {
      // tab is inactive
      tabVisibilitySubj.next(false);
    }, false);
  }
};

/**
 * Check if tab is visible
 * @return {boolean}
 */
export const isTabVisible = () => {
  return tabVisibilitySubj.getValue();
};

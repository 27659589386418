import { isAnalyticsEnabled, loadYandexMetrica } from 'shared/helpers/analytics';

export interface WithCount {
  // service param to track
  // total number of events
  '!count': null
};

export function withCount<T>(params: T): WithCount & T {
  return {
    '!count': null,
    ...params
  }
};

export type LoginParams = WithCount & {
  [userId: string]: WithCount & {
    logins: {
      today: number,
      daysInARow: number,
      total: number,
    }
  },
};

export type WorldParams = WithCount & { worldId: string };

export type PackParams = WithCount & { packId: string };


export const LevelEndings = Object.freeze({
  restart: 'restart',
  leave: 'leave',
  win: 'win',
  fail:'fail'
});

export type LevelParams = WithCount & {
  [levelId: string]: WithCount & {
    [ending: string]: WithCount & {
      uniqueStart: boolean,
      stepsCount: number,
      bestMovesPath: string,
      time: number,
      boostersUsed: {
        hint: number,
        moveBack: number
      },
      adsWatched: {
        hint: number,
        moveBack: number
      },
    }
  }
};

export type LevelEnterParams = WithCount & {
  [levelId: string]: WithCount & {
    enter: {
      enterDate: string
    }
  }
};

export type MoveBackUseParams = WithCount & {
  [levelId: string]: WithCount & {
    step: number,
    time: number,
  }
};

export type HintUseParams = WithCount & {
  [levelId: string]: WithCount & {
    step: number,
    time: number,
    followed: boolean,
  }
};

export type BoosterAdWatchParams = WithCount & {
  levelId: string
};

export const WinPopupActions = Object.freeze({
  restart: 'restart',
  next: 'next',
});

export type WinPopupActionParams = WithCount & {
  [levelId: string]: WithCount & {
    achievedStars: number,
    action: keyof typeof WinPopupActions
  }
};

export const LosePopupActions = Object.freeze({
  restart: 'restart',
  exit: 'exit',
});

export type LosePopupActionParams = WithCount & {
  [levelId: string]: WithCount & {
    achievedStars: number,
    action: keyof typeof LosePopupActions
  }
};

export const InfinityLivesPurchaseResults = Object.freeze({
  success: 'success',
  fail: 'fail',
  exit: 'exit'
})

export type InfinityLivesPurchaseParams = WithCount & {
  result: keyof  typeof InfinityLivesPurchaseResults
}

export const AdWatchRewards = Object.freeze({
  moves: 'moves',
  live: 'live',
  hint: 'hint',
  moveBack: 'moveBack',
});

export const AdWatchResults = Object.freeze({
  watched: 'watched',
  blocked: 'blocked',
  notFound: 'notFound',
});

export type AdWatchParams = WithCount & {
  [reward: string]: WithCount & {
    result: keyof typeof AdWatchResults
  }
};

export const AddMovesPopupResults = Object.freeze({
  playAd: 'playAd',
  leave: 'leave'
});

export type AddMovesPopupParams = WithCount & {
  [levelId: string]: WithCount & {
    [result: string]: WithCount & {
      adPlays: number,
    }
  }
};

export type SkipTutorialParams = WithCount & {
  [levelId: string]: WithCount & {
    userId: string;
  }
}

export type RouteParams = WithCount & {
  [pathName: string]: WithCount & {
    fromRoute?: string;
  };
}

export function sendAnalyticsEvent(eventName: 'shareClick', eventParams?: any): void
export function sendAnalyticsEvent(eventName: 'groupClick', eventParams?: any): void
export function sendAnalyticsEvent(eventName: 'worldClick', eventParams:WorldParams): void
export function sendAnalyticsEvent(eventName: 'packClick', eventParams:PackParams): void
export function sendAnalyticsEvent(eventName: 'level', eventParams:LevelParams|LevelEnterParams): void
export function sendAnalyticsEvent(eventName: 'hintUse', eventParams:HintUseParams): void
export function sendAnalyticsEvent(eventName: 'hintAdWatch', eventParams:BoosterAdWatchParams): void
export function sendAnalyticsEvent(eventName: 'moveBackUse', eventParams:MoveBackUseParams) : void
export function sendAnalyticsEvent(eventName: 'moveBackAdWatch', eventParams:BoosterAdWatchParams) : void
export function sendAnalyticsEvent(eventName: 'winPopupAction', eventParams:WinPopupActionParams) : void
export function sendAnalyticsEvent(eventName: 'losePopupAction', eventParams:LosePopupActionParams) : void
export function sendAnalyticsEvent(eventName: 'infinityLivesPurchase', eventParams:InfinityLivesPurchaseParams): void
export function sendAnalyticsEvent(eventName: 'adWatch', eventParams:AdWatchParams) : void
export function sendAnalyticsEvent(eventName: 'addMovesPopup', eventParams:AddMovesPopupParams) : void
export function sendAnalyticsEvent(eventName: 'login', eventParams: LoginParams): void;
export function sendAnalyticsEvent(eventName: 'skipTutorial', eventParams: SkipTutorialParams): void;
export function sendAnalyticsEvent(eventName: 'route', eventParams: RouteParams): void;
export function sendAnalyticsEvent(eventName: any, eventParams: any = {}){
  if (isAnalyticsEnabled) {
    loadYandexMetrica().then(({ default: ym }) => {
      ym('reachGoal', eventName, {
        [eventName]: eventParams
      });
    });
  }
};

export const composeAnalyticsEntityId = (...ids: number[]): string => ids.join('.');

import React from 'react';
import classNames from 'classnames';

import { propTypes, defaultProps } from './props';
import styles from './CustomListItem.module.css';

class CustomListItem extends React.PureComponent {
	constructor(props) {
	  super(props);
	  this.state = {};
	}

	render() {
		const { isBlocked, title, starsAchieved, maxStarsCount, starsRequired, isVirtual } = this.props;
		const wrapperClasses = classNames({
		  [styles.CustomListItem]: true,
		  [styles.Blocked]: isBlocked,
		  [styles.Virtual]: isVirtual,
		  [styles.SizeSmall]: maxStarsCount > 999,
		  [this.props.className]: !!this.props.className,
		});
		return (
			<li
				className={wrapperClasses}
				onClick={isBlocked ? null : this.props.onClick.bind(this)}>
				<span className={styles.CustomListItemTitle}>{title}</span>
				<div className={styles.CustomListItemRight}>
					{isBlocked ?
						<React.Fragment>
							<i className={classNames([styles.StarIcon, styles.StarIconGrey])}></i>
							<i className={styles.LockIcon}></i>
							<span className={styles.CustomListItemStarsRequired}>{starsRequired}</span>
						</React.Fragment>
						:
					<React.Fragment>
						<i className={styles.StarIcon}></i>
						<div className={styles.CustomListItemStarsInfo}>
							{starsAchieved}
							<span>/</span>
							{maxStarsCount}
						</div>
					</React.Fragment>
					}
				</div>
			</li>

		);
	}

}

CustomListItem.propTypes = propTypes;
CustomListItem.defaultProps = defaultProps;

export default CustomListItem;

/* eslint react/jsx-pascal-case: [0, { ignore: 1 }] */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import get from 'lodash/get';
import { withNamespaces } from 'react-i18next';
import { Scrollbars } from 'react-custom-scrollbars';
import { NamespacesConsumer } from 'react-i18next';

// modules
import { sendAnalyticsEvent, composeAnalyticsEntityId, withCount } from 'shared/modules/AnalyticsEvents';

// components
import CustomListItem from 'shared/components/CustomListItem';
import ScrollbarHeightAdjuster from 'shared/components/ScrollbarHeightAdjuster';
import PageHeaderManager from 'shared/containers/PageHeaderManager';
import withTracker from 'shared/components/withTracker';

// constants
import cssEnvVars from 'shared/cssEnvironmentVariables';

// helpers
import { getLastVisitedLevel } from 'shared/helpers/progress';
import AppSoundManager from 'shared/modules/AppSoundManager';

// styles
import styles from './LandingPage.module.css';

// redux
import { getAchievedStarsInWorld } from 'redux/selectors/progress';
import { getMaxStarsInWorld, getWorldsSelector } from 'redux/selectors/worlds';

const mapStateToProps = state => ({
  worlds: getWorldsSelector(state),
  worldsMaxStars: state.worlds.worldsMaxStars,
  achievedStars: state.progress.achievedStars,
});

class LandingPage extends Component {

  constructor(props) {
    super(props);
    this.isActive = true;
    this.state = {
      hasContinueLevel: !!getLastVisitedLevel(),
    };
  }

  /******************************************
  * COMPONENT HOOKS
  ******************************************/

  render() {
    const { t, worlds, achievedStars, worldsMaxStars } = this.props;

    return (
      <div className={styles.LandingPage}>
        <PageHeaderManager
          showSettings={true}
        />
        <div className={styles.LogoWrap}>
          <div className={styles.Logo}/>
          <div className={styles.LogoText} />
        </div>
        <ScrollbarHeightAdjuster
          className={styles.WorldsWrap}
          listItem={{
            height: cssEnvVars.CUSTOM_LIST_ITEM_HEIGHT,
            marginBottom: cssEnvVars.CUSTOM_LIST_ITEM_BOTTOM_OFFSET
          }}>
          <Scrollbars autoHide>
            <ul>
              {worlds.map((worldItem, index) => {
                const worldName = t(`worlds.${worldItem.Id}.title`);
                return (
                  <CustomListItem
                    key={index}
                    isBlocked={false}
                    title={worldName}
                    starsAchieved={getAchievedStarsInWorld(worldItem.Id, achievedStars)}
                    maxStarsCount={getMaxStarsInWorld(worldItem.Id, worldsMaxStars)}
                    onClick={this.handlerWorldClick.bind(this, worldItem.Id)} />
                );
              })}
              <NamespacesConsumer>
                { t => (
                  <CustomListItem
                    key={worlds.length}
                    isBlocked={true}
                    isVirtual={true}
                    title={t('worlds.new.title')}
                  />
                )}
              </NamespacesConsumer>

            </ul>
          </Scrollbars>
        </ScrollbarHeightAdjuster>
      </div>
    );
  }

  componentWillUnmount() {
    this.isActive = false;
  }

  /******************************************
  * COMPONENT HANDLERS
  ******************************************/

  handlerContinueClick() {
    AppSoundManager.playButtonSound();
    const lvl = getLastVisitedLevel();
    if (lvl) {
      this.props.history.push(`/game/level/${lvl.worldId}/${lvl.packId}/${lvl.levelId}`);
      return;
    }
  }

  handlerNewGameClick() {
    // New Game - появляется один раз. При клике - кидаем на туториал.
    AppSoundManager.playButtonSound();
    this.props.history.push('/tutorial');
  }

  handlerTutorialClick() {
    AppSoundManager.playButtonSound();
    this.props.history.push('/tutorial');
  }

  handlerChooseLevelClick() {
    // (показывется вместо new game после первого запуска)
    AppSoundManager.playButtonSound();
    const firstWorldId = get(this.props.worlds, '[0].Id', 1);
    this.props.history.push(`/world/${firstWorldId}`);
  }

  handlerWorldClick(worldId) {
    sendAnalyticsEvent('worldClick', withCount({
      worldId: composeAnalyticsEntityId(worldId)
    }));
    AppSoundManager.playButtonSound();
    this.props.history.push(`/world/${worldId}`);
  }

}

export default withTracker(withNamespaces()(withRouter(connect(mapStateToProps)(LandingPage))));

import 'react-app-polyfill/ie11';
import loadable from '@loadable/component';
import main from './main';

const polyfillStable = loadable.lib(() => import('react-app-polyfill/stable'));

function browserSupportsFeatures() {
  return !!(
    Array.of
    && Array.prototype.find
    && Array.prototype.flatMap
    && Array.prototype.values
    && String.prototype.endsWith
    && String.prototype.padStart
    && String.prototype.repeat
    && Object.entries
  )
}

if (browserSupportsFeatures()) {
  main();
} else {
  polyfillStable.load().then(main);
}
import { minToMs } from 'shared/helpers/lives';
import {
  getLives,
  setNewLifeTimer,
  clearNewLifeTimer
} from 'redux/actions/lives';
import { GET_LIVES_SUCCESS } from 'redux/actionTypes';

export const updateNewLifeTimerMiddleware = () => (store: any) => (
  next: any
) => (action: any) => {
  next(action);
  const { livesNumber, maxLivesNumber } = store.getState().lives;
  if (action.type === GET_LIVES_SUCCESS) {
    if (livesNumber < maxLivesNumber) {
      startTimer(store);
    }
  }
};

let timer: any;

const startTimer = (store: any) => {
  const { lives } = store.getState();
  const { newLifeTimer, lifeRestoreMinutes } = lives;
  if (!newLifeTimer) {
    store.dispatch(setNewLifeTimer());
  } else {
    const time = newLifeTimer + minToMs(lifeRestoreMinutes);
    const now = new Date().valueOf();
    if (now > time) {
      store.dispatch(setNewLifeTimer());
    }
  }

  if (!timer) {
    timer = setInterval(() => {
      const lives = store.getState().lives;
      const { lifeRestoreMinutes, newLifeTimer } = lives;
      if (newLifeTimer) {
        const time = newLifeTimer + minToMs(lifeRestoreMinutes);
        const now = new Date().valueOf();
        // if timer is finished (or it's null) - add lives & clear timer
        if (now > time) {
          clearTimer(store);
          store.dispatch(getLives());
        }
      }
    }, 1000);
  }
};

const clearTimer = (store: any) => {
  store.dispatch(clearNewLifeTimer());

  if (timer) {
    clearInterval(timer);
    timer = null;
  }
};
